import * as React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import Button from '@mui/joy/Button';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as RealEstateIcon } from '../../assets/images/house-chimney-floor-svgrepo-com.svg';
import { ReactComponent as MarketingIcon } from '../../assets/images/marketing-svgrepo-com.svg';
import { ReactComponent as StoreIcon } from '../../assets/images/store-svgrepo-com.svg';
import { ReactComponent as HospitalityIcon } from '../../assets/images/hotel-svgrepo-com.svg';
import { ReactComponent as EComIcon } from '../../assets/images/online-shop-ecommerce-svgrepo-com.svg'
import { ReactComponent as ToolboxIcon } from '../../assets/images/toolbox-svgrepo-com.svg'

const industryMapping = {
    "real-estate": "Real Estate",
    "retail": "Retail",
    "marketing": "Marketing",
    "hospitality": "Hospitality",
    "home-services": "Home",
    "e-commerce": "E-commerce"
};


const IconCarousel = ({
    selectedCategory,
    setSelectedCategory,
    maxWidth = '60%'
}) => {
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        let selected = params.get('industry');
        console.log(selected);

        if (selected) {
            selected = industryMapping[selected] || selected;
            console.log(selected);
            setSelectedCategory([selected]);
        }
    }, [location, setSelectedCategory]);

    const data = [
        {
            src: <RealEstateIcon />,
            title: 'Real Estate',
            category: 'Real Estate'
        },
        {
            src: <HospitalityIcon />,
            title: 'Hospitality',
            category: 'Hospitality'
        },
        {
            src: <StoreIcon />,
            title: 'Retail Tools',
            category: 'Retail'
        },
        {
            src: <ToolboxIcon />,
            title: 'Maintenance',
            category: 'Home'
        },
        {
            src: <MarketingIcon />,
            title: 'Marketing',
            category: 'Marketing'
        },
        {
            src: <EComIcon />,
            title: 'E-commerce',
            category: 'E-commerce'
        }
    ];

    // const handleScroll = (direction) => {
    //     if (scrollRef.current) {
    //         const { scrollLeft, clientWidth } = scrollRef.current;
    //         const scrollAmount = direction === 'left' ? -clientWidth : clientWidth;
    //         scrollRef.current.scrollTo({
    //             left: scrollLeft + scrollAmount,
    //             behavior: 'smooth',
    //         });
    //     }
    // };

    const handleCardClick = (item) => {
        const newSelectedCategories = selectedCategory.includes(item.category)
            ? selectedCategory.filter(cat => cat !== item.category)
            : [...selectedCategory, item.category];

        setSelectedCategory(newSelectedCategories);
    };

    const handleClearAll = () => {
        setSelectedCategory([]);
    };

    return (
        <Box sx={{ position: 'relative', width: "100%", maxWidth }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2
            }}>

                {selectedCategory.length > 0 && (
                    <Button
                        variant="soft"
                        color="neutral"
                        size="sm"
                        onClick={handleClearAll}
                    >
                        Clear All ({selectedCategory.length})
                    </Button>
                )}
            </Box>

            <Box sx={{ position: 'relative' }}>
                {/* <IconButton
                    onClick={() => handleScroll('left')}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '-20px',
                        transform: 'translateY(-50%)',
                        zIndex: 10,
                        display: { xs: 'none', sm: 'flex' },
                    }}
                    aria-label="Scroll left"
                >
                    <ChevronLeft />
                </IconButton>
 */}
                <Box
                    // ref={scrollRef}
                    sx={{
                        display: 'flex',
                        gap: 2,
                        py: 2,
                        overflowX: 'auto',
                        scrollSnapType: 'x mandatory',
                        '& > *': {
                            scrollSnapAlign: 'center',
                        },
                        '::-webkit-scrollbar': { display: 'none' },
                        px: 2,
                    }}
                >
                    {data.map((item, index) => (
                        <Card
                            orientation="vertical"
                            size="sm"
                            key={index}
                            variant={selectedCategory.includes(item.category) ? "solid" : "outlined"}
                            onClick={() => handleCardClick(item)}
                            sx={{
                                width: 110,
                                height: 100,
                                textAlign: 'center',
                                transition: 'all 0.2s ease',
                                cursor: 'pointer',
                                '&:hover': { transform: 'scale(1.05)' },
                                bgcolor: 'transparent',
                                // borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 1,
                                border: "1px solid rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <Box sx={{
                                width: "90%",
                                height: "90%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                                {typeof item.src === 'string' ? (
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        style={{ objectFit: 'cover', borderRadius: "50%", width: "100%", height: "100%" }}
                                    />
                                ) : (
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                                        {item.src}
                                    </Box>
                                )}
                            </Box>
                            <Typography
                                level="body-sm"
                                sx={{
                                    fontFamily: 'Poppins',
                                    fontSize: "14px",
                                    color: '#5C187E',
                                    fontWeight: 'bold',
                                    whiteSpace: "nowrap",
                                    textAlign: "center",
                                }}
                            >
                                {item.title}
                            </Typography>

                        </Card>
                    ))}
                </Box>

                {/* <IconButton
                    onClick={() => handleScroll('right')}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        right: '8px',
                        transform: 'translateY(-50%)',
                        zIndex: 10,
                        display: { xs: 'none', sm: 'flex' },
                    }}
                    aria-label="Scroll right"
                >
                    <ChevronRight />
                </IconButton> */}
            </Box>
        </Box>
    );
};

export default IconCarousel;