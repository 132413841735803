import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import NavBar from '../../components/Navbar';
import Box from '@mui/joy/Box';
import Footer from '../../components/Footer';
import FooterDivider from '../../components/FooterDivider';
import LandingPageInputBox from '../../components/LandingPage/LandingPageInputBox';
import TrendingProducts from '../../components/LandingPage/TrendingProducts';
import ProductsFilters from '../../components/ViewAllProducts/ProductsFilters';
import NoProduct from '../../components/SellerDashboard/NoProducts';
import { fetchProducts, fetchCustomProducts } from '../../Services/Products';
import CustomPagination from '../../components/CustomPagination';
import { Helmet } from 'react-helmet';
import { HEADER_DATA } from './constants';
import SpinnerComponent from '../../components/Spinner';
import { FlexDiv } from '../../assets/styles/styles';
import { InputFilterDiv } from '../../assets/styles/styles';
import ProductType from './ProductType';
import { Container } from '@mui/material';
import { useNotification } from '../../Context/NotificationContext';
import Dropdown from './Dropdown';
import IconCarousel from './IconCarousel';
import ToggleButton from '../../components/ToggleButton';

const BuyerProducts = ({ custom }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isTab = useMediaQuery({ maxWidth: 700 });
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(10000);
    const [isFreeChecked, setIsFreeChecked] = useState(true);
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [sortBy, setSortBy] = useState(''); // State variable to hold selected sort option
    const [loading, setLoading] = useState(true); // Set initial value to false
    // const [selectedCategory, setSelectedCategory] = useState([]);
    const [type, setType] = useState('')
    const { selected, selectedCategory, setSelectedCategory } = useNotification();
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage]);

    const handleSearch = (searchTerm) => {
        setSearchQuery(searchTerm);
    };

    const getProducts = async () => {
        try {

            const limit = isMobile ? 5 : 8; // Set limit based on screen size

            if (!custom) {
                console.log('Products')
                const { products, totalPages } = await fetchProducts({
                    searchQuery,
                    minPrice,
                    maxPrice,
                    selectedCategory,
                    currentPage,
                    limit,
                    selected,
                    sortBy
                });
                console.log(currentPage)
                console.log(totalPages)
                if (currentPage > totalPages)
                    setCurrentPage(1);

                setProducts(products);
                setTotalPages(totalPages);

            }
            else {
                console.log('Custom Products')
                const { products, totalPages } = await fetchCustomProducts({
                    searchQuery,
                    selectedCategory,
                    currentPage,
                    limit,
                    selected,
                    sortBy
                });
                if (currentPage > totalPages)
                    setCurrentPage(1);
                console.log(products)
                setProducts(products);
                setTotalPages(totalPages);

            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getProducts();
    }, [custom, currentPage, searchQuery, maxPrice, minPrice, selectedCategory, isMobile, selected, sortBy]);

    return (
        <>
            <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>
                <Helmet>
                    <title>Cornucopia AI | Products</title>
                </Helmet>
                <Box component="main" sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
                    <NavBar header_data={HEADER_DATA} header_button={{ label: 'Join the venture', navigation_url: '/buyerseller' }} mobile_nav_height={'260px'} />
                    <Box component="section" sx={{ display: 'flex', flexDirection: 'column', gap: '4rem', marginTop: isTab ? '2rem' : '6rem' }}>
                        <LandingPageInputBox padding_top='0rem' searchQuery={searchQuery} setSearchQuery={setSearchQuery} onSearch={handleSearch} />
                    </Box>
                    <Box component="section" sx={{ 
                        justifyContent: 'center', 
                        alignItems: isTab ? 'center' : 'flex-start', 
                        flexDirection: isTab ? 'column' : 'row', 
                        paddingRight: isTab ? '0rem' : '4rem', 
                        paddingLeft: isTab ? '0rem' : '0.5rem', 
                        marginBottom: isTab ? '2rem' : '' 
                    }}>
                        <Box component="div" sx={{ 
                            ...styles.filtersContainer, 
                            marginLeft: !isTab ? '6.5rem' : '', 
                            paddingTop: '1rem' 
                        }}>
                            <Box
                                component="nav"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    gap: 1,
                                    mb: 2,
                                    width: '100%'
                                }}
                            >
                                <IconCarousel selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
                                {!custom && <ToggleButton />}
                                <Dropdown setSortBy={setSortBy} />
                            </Box>
                        </Box>
                    </Box>
                    {loading ? (
                        <Box component="div" sx={styles.loadingContainer}>
                            <SpinnerComponent />
                        </Box>
                    ) : products?.length === 0 && !loading ? (
                        <Box component="section">
                            <NoProduct width="85%" />
                        </Box>
                    ) : (
                        <Box component="section">
                            {!loading && (
                                custom ? (
                                    <TrendingProducts
                                        totalTrendingProducts={8}
                                        trendingProductsText={'Custom AI Products'}
                                        products={products}
                                        viewMore={false}
                                        custom={custom}
                                    />
                                ) : (
                                    <TrendingProducts
                                        totalTrendingProducts={8}
                                        trendingProductsText={'AI Products'}
                                        products={products}
                                        viewMore={false}
                                        style={{ display: 'flex', flexWrap: 'wrap', gap: '2rem', justifyContent: 'center' }}
                                    />
                                )
                            )}

                            <Box component="nav" sx={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center', 
                                flex: '1', 
                                marginTop: isMobile ? '8rem' : '2rem' 
                            }}>
                                <CustomPagination count={totalPages} page={currentPage} onChange={handlePageChange} />
                            </Box>
                        </Box>
                    )}
                </Box>
            </Container>
            <FooterDivider />
            <Footer />
        </>
    );
};

const styles = {
    container: {
        marginBottom: '-200px',
    },
    filtersContainer: {

        width: '95%',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: '1',
        marginTop: '2rem'
    },
};

export default BuyerProducts;
