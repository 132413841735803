import React, { useState } from "react";
import {
    Box,
    Typography,
    Button,
    Container,
    Stack,
    Modal
} from "@mui/material";
import { ExploreOutlined, StorefrontOutlined, CalendarToday } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { InlineWidget } from "react-calendly";
import CornucopiaVideo from "../../assets/images/H1.mp4";
import { Link } from "react-router-dom";

const HeaderSection = () => {
    const [openCalendly, setOpenCalendly] = useState(false);
    const navigate = useNavigate();
    const calendlyUrl = "https://calendly.com/tatmanman12/30min?";

    const handleCalendlyOpen = () => setOpenCalendly(true);
    const handleCalendlyClose = () => setOpenCalendly(false);

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100vw',
                height: { xs: 'auto', md: '90vh' },
                minHeight: { xs: '100vh', md: '90vh' },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                overflow: "hidden",
                boxSizing: "border-box",
            }}
        >
            {/* Background Video with iOS fixes */}
            <Box
                component="video"
                autoPlay
                loop
                muted
                playsInline
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    zIndex: -1,
                    // iOS specific fixes
                    '&::-webkit-media-controls': {
                        display: 'none !important'
                    },
                    '&::-webkit-media-controls-start-playback-button': {
                        display: 'none !important'
                    },
                    '&::-webkit-media-controls-play-button': {
                        display: 'none !important'
                    },
                    '-webkit-tap-highlight-color': 'transparent',
                    // Ensure video stays in background
                    pointerEvents: 'none',
                }}
            >
                <source src={CornucopiaVideo} type="video/mp4" />
            </Box>

            {/* Content container */}
            <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
                <Stack 
                    spacing={4} 
                    alignItems="center"
                    sx={{
                        px: { xs: 2, md: 0 },
                        py: { xs: 4, md: 0 }
                    }}
                >
                    {/* Main heading */}
                    <Typography
                        variant="h1" 
                        component="h1"
                        sx={{
                            fontSize: { 
                                xs: '2rem',
                                sm: '2.5rem',
                                md: '2.75rem',
                                lg: '3rem'
                            },
                            lineHeight: 1.2,
                            fontWeight: 'bold',
                            textAlign: 'center',
                            maxWidth: '1200px',
                            whiteSpace: { xs: 'normal', md: 'nowrap' } // Allow wrapping on mobile
                        }}
                    >
                        Explore the #1 AI Marketplace for Small Business Growth
                    </Typography>

                    {/* Subheading */}
                    <Typography
                        variant="h5"
                        sx={{
                            maxWidth: 'md',
                            textAlign: 'center',
                            mb: 4,
                            fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' },
                            px: { xs: 2, md: 0 }
                        }}
                        component="h5"
                    >
                        Step into the future with a next-generation AI platform designed to transform the way small and medium-sized businesses operate. Our carefully vetted AI tools empower SMBs to scale faster, work smarter, and stay ahead in a rapidly evolving digital world.
                    </Typography>

                    {/* CTA Buttons */}
                    <Box sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        alignItems: "center",
                        gap: { xs: 2, sm: 4 },
                        width: '100%',
                        maxWidth: { xs: '100%', sm: 'auto' },
                        justifyContent: 'center'
                    }}>
                        {[{
                            label: "Explore AI Tools for My Business",
                            icon: <ExploreOutlined />,
                            path: "/signup",
                            sx: {
                                backgroundColor: "#9803fa",
                                "&:hover": { backgroundColor: "#8002d1" }
                            }
                        }, {
                            label: "List My AI Tools on the Marketplace",
                            icon: <StorefrontOutlined />,
                            path: "/signup-seller",
                            sx: {
                                backgroundColor: "#9803fa",
                                "&:hover": { backgroundColor: "#8002d1" }
                            }
                        }].map((button, index) => (
                            <Link
                                key={index}
                                to={button.path}
                                style={{ 
                                    textDecoration: "none",
                                    flex: { xs: '1', sm: '0 1 auto' }
                                }}
                                aria-label={button.label}
                            >
                                <Button
                                    variant="contained"
                                    startIcon={button.icon}
                                    sx={{
                                        ...button.sx,
                                        color: "white",
                                        fontSize: { xs: "0.9rem", sm: "1rem" },
                                        fontWeight: "500",
                                        padding: { xs: "12px 24px", sm: "14px 32px" },
                                        borderRadius: "50px",
                                        fontFamily: "Poppins",
                                        textTransform: "none",
                                        whiteSpace: "nowrap",
                                        width: { xs: '100%', sm: 'auto' },
                                        minWidth: { sm: 'max-content' },
                                        boxShadow: 'none',
                                        letterSpacing: '0.3px'
                                    }}
                                >
                                    {button.label}
                                </Button>
                            </Link>
                        ))}
                    </Box>

                    {/* Calendly Modal */}
                    <Modal
                        open={openCalendly}
                        onClose={handleCalendlyClose}
                        aria-labelledby="calendly-modal"
                        aria-describedby="calendly-scheduling-modal"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                width: '80%',
                                height: '80%',
                                maxWidth: '1000px',
                                position: 'relative',
                            }}
                        >
                            <InlineWidget
                                url={calendlyUrl}
                                styles={{ height: '100%', width: '100%' }}
                            />
                        </Box>
                    </Modal>
                </Stack>
            </Container>
        </Box>
    );
};

export default HeaderSection;
