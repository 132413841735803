import React from 'react';
import { Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledSelect = styled(Select)({
  width: '16rem',
  height: '3rem',
  borderRadius: '8px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#7C3AED',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#9B5AE8',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#7C3AED',
  },
  '& .MuiSelect-select': {
    padding: '8px 16px',
    fontFamily: 'Poppins',
    fontSize: '0.9rem',
    color: '#3F0065',
  }
});

const StyledMenuItem = styled(MenuItem)({
  fontFamily: 'Poppins',
  fontSize: '0.9rem',
  color: '#3F0065',
  padding: '12px 16px',
  '&:hover': {
    backgroundColor: '#F9F5FF',
  },
  '&.Mui-selected': {
    backgroundColor: '#F9F5FF',
    color: '#7C3AED',
    '&:hover': {
      backgroundColor: '#F9F5FF',
    }
  }
});

const Dropdown = ({ setSortBy }) => {
  const [value, setValue] = React.useState('Filter');

  const handleChange = (event) => {
    setValue(event.target.value);
    setSortBy(event.target.value);
  };

  return (
    <StyledSelect
      value={value}
      onChange={handleChange}
      displayEmpty
      IconComponent={KeyboardArrowDownIcon}
    >
      <StyledMenuItem value="Filter" disabled>Filter</StyledMenuItem>
      <StyledMenuItem value="trending">Trending</StyledMenuItem>
      <StyledMenuItem value="price_asc">Price: Low to High</StyledMenuItem>
      <StyledMenuItem value="verified">Cornucopia Verified Tools</StyledMenuItem>
      <StyledMenuItem value="avg_customer_review">Avg. Customer Reviews</StyledMenuItem>
      <StyledMenuItem value="created_at">Newest Arrivals</StyledMenuItem>
      <StyledMenuItem value="best_seller">Best Sellers</StyledMenuItem>
    </StyledSelect>
  );
};

export default Dropdown;
