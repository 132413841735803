import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { FlexDiv } from "../../../assets/styles/styles";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { useMediaQuery } from "react-responsive";
import SearchIcon from '@mui/icons-material/Search';
import { TextField, InputAdornment, IconButton } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import DeleteIcon from '@mui/icons-material/Delete';
import VerticalLine from '../../../assets/images/vertical_line.svg';
import { Typography } from '@mui/material';
import UserProfileSeller from "../UserProfileSeller";
import { ADD_NEW_PRODUCT, FILTERS, PRODUCTS } from "../../../constants/text";
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import NoProduct from "../NoProducts";
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { getProducts, deleteProduct } from "../../../Services/Products";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import CustomPagination from "../../CustomPagination";
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import { cellArray, CategoryFilters, convertTo2D } from "../../../constants/helper";
import { TableHeaders, MarketplaceText, FilterContainer, AddNewProductBtn, TagDiv, ProductStatusDiv, pageSize } from "./constants";
import { Helmet } from "react-helmet";
import { IP_ADD } from "../../../constants/api";
import ConfirmationModal from "./ConfirmationModal";
import SpinnerComponent from "../../Spinner";
import ProductTypeModal from "../ProductTypeModal";

const Products = () => {
    const isDesktop = useMediaQuery({ minWidth: 1280 });
    const isSmallDesktop = useMediaQuery({ minWidth: 1030 });
    const isLargeLaptop = useMediaQuery({ minWidth: 720 });
    const isLaptop = useMediaQuery({ minWidth: 620 });
    const isMobile = useMediaQuery({ minWidth: 550 });
    const isSmallMobile = useMediaQuery({ maxWidth: 410 });
    const [isOpen, setIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [apiData, setApiData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deletingProduct, setDeletingProduct] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [loadingOnboard, setLoadingOnboard] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [open, setOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const user = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const stripe_acc = user.response.stripe_connect_account;
    const navigate = useNavigate();

    const addOrRemoveFilter = (value) => {
        setSelectedFilters((prevFilters) =>
            prevFilters.includes(value)
                ? prevFilters.filter((filter) => filter !== value)
                : [...prevFilters, value]
        );
    }

    const handleFilterChange = (event) => {
        event.stopPropagation();
        const value = event.target.value;
        addOrRemoveFilter(value);
    };

    const handleFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setAnchorEl(null);
    };

    const openModal = (productId) => {
        setSelectedProductId(productId);
        setIsOpen(true);
    };


    const closeModal = () => {
        setIsOpen(false);
    };

    const handleNavigate = async () => {
        try {
            setLoadingOnboard(true);
            const response = await axios.post(`${IP_ADD}/api/payment/connect-account`, {
                account_id: stripe_acc,
            });

            if (response.data.response && response.data.response.details_submitted) {
                setIsModalOpen(true);
                // navigate('/seller/products/add');
            } else {
                // navigate('/onboard');
                setIsModalOpen(true);
            }
        } catch (error) {
            console.error('API call failed', error);
        } finally {
            setLoadingOnboard(false);
        }
    };

    const NavigateToDetails = (id) => {
        navigate('/seller/products/' + id);
    };
    const NavigateToEditProd = (id, type) => {
        navigate(`/seller/products/add/${type}?edit=${id}`);
    }

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const fetchData = async (searchQuery = '') => {
        try {
            const response = await getProducts(pageSize, searchQuery, selectedFilters, currentPage);
            setApiData(response.results);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error('API request error:', error);
        }
    };

    const handleDeleteProduct = async (productId) => {
        try {
            setDeletingProduct(true);
            await deleteProduct(productId);
            fetchData();
        } catch (error) {
            console.error('Error deleting product:', error);
        } finally {
            setDeletingProduct(false);
            closeModal();
        }
    };

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, apiData.length);

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setSnackbarMsg('');
    };

    useEffect(() => {
        setCurrentPage(1);
    }, [searchTerm]);

    useEffect(() => {
        fetchData()
            .then(() => setLoading(false)) // Set loading to false when products are loaded
            .catch((error) => {
                console.error('API request error:', error);
                setLoading(false); // Set loading to false in case of an error
            });
    }, [selectedFilters, currentPage]);

    useEffect(() => {
        const handleClickAway = (event) => {
            if (anchorEl && !anchorEl.contains(event.target)) {
                handleFilterClose();
            }
        };

        window.addEventListener('click', handleClickAway);

        return () => {
            window.removeEventListener('click', handleClickAway);
        };
    }, [anchorEl]);

    const productsForPage = apiData;
    
    return (
        <>
            <Helmet>
                <title>Cornucopia AI | Seller-Products</title>
            </Helmet>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleSnackBarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackBarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMsg}
                </MuiAlert>
            </Snackbar>
            <ProductTypeModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
            <FlexDiv style={{ justifyContent: isLargeLaptop ? 'space-between' : 'flex-end', alignItems: 'center' }}>
                {isLargeLaptop && <><FlexDiv><MarketplaceText style={{ marginTop: '1.8rem' }}>{PRODUCTS}</MarketplaceText>
                    <Typography variant="body2" color="textSecondary" style={{ marginTop: "2.7rem", marginLeft: '1rem' }}>
                        {apiData.length} products
                    </Typography>
                </FlexDiv>
                </>
                }
                <FlexDiv style={{ marginTop: '1.2rem', gap: isLaptop ? '1rem' : '0.1rem' }}>
                    <UserProfileSeller />
                </FlexDiv>
            </FlexDiv>
            {!isLargeLaptop && <MarketplaceText style={{ marginTop: '2rem', textAlign: 'center', fontSize: '2rem' }}>{PRODUCTS}</MarketplaceText>}
            <FlexDiv style={{ justifyContent: isSmallDesktop ? 'space-between' : 'center', flexDirection: isSmallDesktop ? 'row' : 'column', alignItems: !isSmallDesktop && 'center', marginLeft: !isLargeLaptop && '1rem' }}>
                {isSmallDesktop ?
                    <TextField
                        placeholder="Search for Products"
                        variant="outlined"
                        size="small"
                        InputProps={{
                            startAdornment: (
                                <>
                                    <SearchIcon style={{ color: '#9E9E9E', marginRight: '0.5rem' }} />
                                    <div style={{ width: '1.43rem', height: '1.3px', background: '#DEDEDE', transform: 'rotate(90deg)' }}></div>
                                </>
                            ),
                            sx: {
                                width: isDesktop ? '33rem' : '19rem',
                                marginTop: '1.38rem',
                                height: '2.8125rem',
                                borderRadius: '0.375rem',
                                background: '#F9F9F9',
                                '& .MuiOutlinedInput-root': {
                                    border: 'none',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none', // Remove the outlined border
                                },
                            },
                        }}
                        inputProps={{
                            style: {
                                color: 'rgba(35, 35, 35, 0.9)',
                                fontFamily: 'Poppins',
                                fontSize: '0.9375rem',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: 'normal',
                            },
                        }}
                        value={searchTerm}
                        onChange={event => setSearchTerm(event.target.value)}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                fetchData(searchTerm);
                            }
                        }}
                    />
                    :
                    <TextField
                        sx={{
                            marginTop: '1.38rem',
                            marginRight: isLargeLaptop ? '3rem' : '1rem',
                            borderRadius: '0.375rem',
                            width: '95%',
                            background: '#F9F9F9',
                            "& fieldset": { border: 'none' },
                        }}
                        InputProps={{
                            startAdornment: (<InputAdornment position="start"><SearchIcon style={{ color: '#9E9E9E', marginRight: '0.5rem' }} /><div style={{ width: '1.43rem', height: '1.3px', background: '#DEDEDE', transform: 'rotate(90deg)' }} /></InputAdornment>),
                        }}
                        inputProps={{
                            style: {
                                color: 'rgba(35, 35, 35, 0.9)',
                                fontFamily: 'Poppins',
                                fontSize: '0.9375rem',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: 'normal',
                            },
                        }}
                        value={searchTerm}
                        onChange={event => setSearchTerm(event.target.value)}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                fetchData(searchTerm);
                            }
                        }}
                        placeholder="Search for Products"
                    />
                }
                <FlexDiv style={{ gap: isMobile ? '1rem' : '2rem', flexDirection: isMobile ? 'row' : 'column', alignItems: isMobile ? '' : 'center', marginLeft: '1rem' }}>
                    <button style={{ display: 'contents' }}>
                        <FilterContainer style={{ marginTop: '1.38rem' }} onClick={handleFilterClick}>
                            <FlexDiv>
                                <SearchIcon sx={{ color: '#8F8F8F', margin: '0.9rem 0rem 0rem 0.75rem', width: '1.2rem', height: '1.2rem' }} onClick={fetchData} />
                                <div style={{ width: '1.43rem', height: '1.3px', background: '#DEDEDE', transform: 'rotate(90deg)', margin: '1.4rem 0.3rem 0rem 0.5rem' }}></div>
                            </FlexDiv>
                            <h1 style={{ color: '#6D0085', fontFamily: 'Poppins', fontSize: '1rem', fontWeight: '500', marginTop: '0.8rem', marginLeft: '1.5rem', marginRight: '1.5rem' }}>{FILTERS}</h1>
                            <ExpandMoreIcon style={{ color: '#76108C', margin: '0.65rem 0.3rem 0rem 1rem' }} />
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleFilterClose}
                                elevation={1}
                                PaperProps={{
                                    style: {
                                        width: anchorEl ? anchorEl.clientWidth : 'auto', // Set the width to match the button
                                        maxHeight: '200px',
                                    },
                                }}
                            >
                                <FormGroup>
                                    {CategoryFilters.map((filter) => (
                                        <MenuItem key={filter}>
                                            <label>
                                                <Checkbox
                                                    value={filter}
                                                    checked={selectedFilters.includes(filter)}
                                                    onChange={handleFilterChange}
                                                />
                                                {filter}
                                            </label>
                                        </MenuItem>
                                    ))}
                                </FormGroup>
                            </Menu>
                        </FilterContainer>
                    </button>
                    <button onClick={handleNavigate} style={{ display: 'contents' }}>
                        <AddNewProductBtn style={{ marginTop: '1.38rem', marginRight: isSmallDesktop ? '3rem' : '', display: 'flex', justifyContent: 'center' }}>
                            {loadingOnboard ? (
                                <CircularProgress color="secondary" style={{ width: '27px', height: '25px', marginTop: '0.5rem' }} />
                            ) : (
                                <>
                                    <AddIcon sx={{ color: '#BB02C3', margin: '0.6rem 0.81rem 0rem 0.1rem' }} />
                                    <div style={{ marginTop: '0.8rem' }}>{ADD_NEW_PRODUCT}</div>
                                </>
                            )}
                        </AddNewProductBtn>
                    </button>
                </FlexDiv>
            </FlexDiv>
            {isSmallDesktop &&
                convertTo2D(selectedFilters, 4).map((listData, index) => (
                    <FlexDiv key={index} style={{ gap: '0.5rem' }}>
                        {listData.map((data, innerIndex) => (
                            <TagDiv key={data} style={{ marginTop: '1.1rem' }}>
                                <div style={{ margin: '0.5rem 0.3rem 0rem 0.5rem' }}>{data}</div>
                                <IconButton key={`${data}-button`} onClick={() => addOrRemoveFilter(data)}>
                                    <CloseIcon sx={{ color: '#8F8F8F' }} />
                                </IconButton>
                            </TagDiv>
                        ))}
                    </FlexDiv>
                ))}
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    {/* <CircularProgress color='secondary' style={{ width: '27px', height: '25px' }} /> Show circular progress while loading */}
                    <SpinnerComponent />
                </div>
            ) : (
                <>
                    <div style={{ marginRight: isLargeLaptop ? '2rem' : '1rem', marginTop: apiData.length === 0 ? '1rem' : '0', marginBottom: apiData.length === 0 ? '2rem' : '0', marginLeft: (apiData.length === 0 && !isLargeLaptop) ? '1rem' : '0rem' }}>
                        {apiData.length === 0 ? (
                            <NoProduct />
                        ) : (
                            <TableContainer style={{ marginTop: '1rem', padding: isLargeLaptop ? '0rem 0.5rem 1rem 0.2rem' : '0rem 0.5rem 1rem 1rem' }}>
                                <Table aria-label="customized table"
                                    sx={{
                                        [`& .${tableCellClasses.root}`]: {
                                            borderBottom: "none"
                                        }
                                    }}
                                >
                                    <TableHead>
                                        <TableRow>
                                            {TableHeaders.map((data) => (
                                                <TableCell key={data.name} align={`${data.align}`} sx={{ color: 'rgba(66, 0, 81, 0.62)', fontFamily: 'Poppins', fontSize: '0.875rem', fontWeight: 'bold', whiteSpace: 'nowrap' }}>{data.name}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {productsForPage.map((data) => (
                                            <>
                                                <React.Fragment key={data._id}>
                                                    <TableRow key={data._id} sx={{
                                                        borderRadius: '0.375rem', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.22)', '&:hover': {
                                                            border: '1px solid rgba(157, 64, 177, 0.53)',
                                                            boxShadow: '0px 0px 13px 0px rgba(157, 64, 177, 0.23)',
                                                        },
                                                    }}>
                                                        <TableCell ><img src={data.images[0]} alt="" style={{ width: '3.5rem', height: '3.25rem' }} /></TableCell>
                                                        <TableCell component="th" scope="row" sx={{ color: '#393939', fontFamily: 'Poppins', fontSize: '0.875rem', fontWeight: '600' }}>
                                                            {data.name}
                                                        </TableCell>
                                                        <TableCell sx={{ color: '#434343', fontFamily: 'Poppins', fontSize: '0.875rem', fontWeight: '400', }}>
                                                            <Tooltip title={data._id}
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(data._id);
                                                                    setSnackbarMsg('Copied to clipboard');
                                                                    setSnackbarSeverity('success');
                                                                    setOpen(true);
                                                                }}
                                                            >
                                                                {data._id.slice(0, 7)}
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell sx={{ color: '#434343', fontFamily: 'Poppins', fontSize: '0.875rem', fontWeight: '400' }}>
                                                            <Tooltip title={data.category.join(', ')}>
                                                                <div>
                                                                    {data.category.join(', ').slice(0, 29) + (data.category.join(', ').length > 29 ? '....' : '')}
                                                                </div>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell sx={{ color: '#434343', fontFamily: 'Poppins', fontSize: '0.875rem', fontWeight: '400' }}>{data.url}</TableCell>
                                                        <TableCell sx={{ color: '#434343', fontFamily: 'Poppins', fontSize: '0.875rem', fontWeight: '400' }}>{data?.type ? (data.type === 'web' ? 'Website' : 'Desktop App') : 'Desktop App'}</TableCell>
                                                        <TableCell sx={{ color: '#C28C00', fontFamily: 'Poppins', fontSize: '0.9375rem', fontWeight: '500', padding: '30px' }}><FlexDiv>{data.buyers?.length}</FlexDiv></TableCell>
                                                        <TableCell align="center">
                                                            <ProductStatusDiv style={{ background: data.status === 'active' ? '#D6EEDD' : '#EFE9D4', color: data.status === 'active' ? '#00976A' : '#978800' }}>
                                                                {data.status === 'active' ? 'Activated' : 'Suspended'}
                                                            </ProductStatusDiv>
                                                        </TableCell>
                                                        <TableCell sx={{ color: '#58006B', fontFamily: 'Poppins', fontSize: '0.9375rem', fontWeight: '600' }}>
                                                            {/* ${data.price} */}
                                                            {data.subscriptionPlans.length > 0 ? (
                                                                data.subscriptionPlans.map((plan) => plan.tier).join(', ')
                                                            ) : (
                                                                `$${data.price}`
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <FlexDiv>
                                                                <button style={{ display: 'contents' }} >
                                                                    <Tooltip title="Edit">
                                                                        <EditIcon sx={{ color: '#C5C5C5', marginTop: '0.4rem' }}
                                                                            onClick={() => NavigateToEditProd(data._id, data.type)}
                                                                        />
                                                                    </Tooltip>
                                                                </button>
                                                                <img src={VerticalLine} alt="" style={{ margin: '0rem 1rem' }} />
                                                                <button style={{ display: 'contents' }} >
                                                                    <Tooltip title="Details">
                                                                        <InfoIcon sx={{ color: '#C5C5C5', marginTop: '0.4rem' }} onClick={() => NavigateToDetails(data._id)} />
                                                                    </Tooltip>
                                                                </button>
                                                                <img src={VerticalLine} alt="" style={{ margin: '0rem 1rem' }} />
                                                                <button style={{ display: 'contents' }} >
                                                                    <Tooltip title="Delete">
                                                                        <DeleteIcon sx={{ color: '#C5C5C5', marginTop: '0.4rem' }} onClick={() => openModal(data._id)} />
                                                                    </Tooltip>
                                                                </button>
                                                            </FlexDiv>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow key={`EmptyRow-${data._id}`} sx={{ '& .MuiTableCell-root': { padding: '8px' } }}>
                                                        {cellArray(9).map((_, index) => (
                                                            <TableCell key={`key-${index}`} />
                                                        ))}
                                                    </TableRow>
                                                </React.Fragment>
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </div>
                </>
            )}
            {apiData.length > 0 && (
                <div style={{ display: 'flex', justifyContent: isSmallMobile ? 'center' : 'space-between', alignItems: 'center', margin: '1rem 1.5rem 2rem' }}>
                    {!isSmallMobile &&
                        <FlexDiv>
                            <Typography variant="body2" color="textSecondary">
                                Showing {startIndex + 1} to {endIndex} of {apiData.length} entries
                            </Typography>
                        </FlexDiv>
                    }
                    {totalPages > 1 && (
                        <FlexDiv>
                            <CustomPagination
                                count={totalPages} // Use totalPages directly
                                page={currentPage}
                                onChange={handlePageChange}
                                sx={{ marginRight: isLargeLaptop ? '3rem' : '0.5rem' }}
                            />
                        </FlexDiv>
                    )}
                </div>
            )}
            <ConfirmationModal isOpen={isOpen} onClose={closeModal} onVerifyEmail={handleDeleteProduct} isVerifyingEmail={deletingProduct} heading='Are You Sure' buttonText='Delete' productId={selectedProductId} fetchData={fetchData} />

        </>
    )
}
export default Products;