import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import { useMediaQuery } from "react-responsive";
import CardContent from '@mui/material/CardContent';
import DoneIcon from '@mui/icons-material/Done';
import { LoginBoxBtn } from '../../assets/styles/styles';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import PurchaseProductImg from '../../assets/images/purchase_product.svg';
import DropMessage from '../../assets/images/drop_message.svg';
import { BASE_PRICE, BORIS_NISI, BUSINESS_GROWTH, DOLLAR, DROP_A_MSG, EXTEND_SUPPORT, JAMES_AND_LAURA, LEAVE_A_MSG, LOREM_IPSUM_DOLOR, LOREM_IPSUM_DOLOR_SIT, PRICE_TAG_NEW, PRICE_TAG_OLD, PURCHASE_PRODUCT, SELLER_INFO, UIS_AUTE, MONTHS, MESSAGE, TERMS_CONDITION, AGREE_TERMS, CANCEL } from '../../constants/text';
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Modal from "react-modal";
import CircularProgress from "@mui/material/CircularProgress";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import CardIcon from "../../assets/images/card_icon.svg";
import { useNavigate } from "react-router-dom";
import TermsConditionsModal from './TermsAndCondition';
import { buyProductApi } from '../../Services/Payment';
import { fetchNotificationsApi, sendNotificationApi } from '../../Services/Notification';
import { useNotification } from '../../Context/NotificationContext';
import axios from 'axios';
import { IP_ADD } from '../../constants/api';
import Avatar from '../../assets/images/male-avatar.png';
import SOW from './DialogComponent/SOW';
import OrderSummaryModal from '../OrderSummaryModal';
import EmailSentModal from '../EmailSentModal';
import PurchaseProduct from '../PurchaseProduct';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { toggleProductLike } from '../../Services/Products';
import SubscriptionPlansModal from './SubscriptionPlansModal';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const customCardStyle = {
    borderRadius: '1.125rem',
    border: 'solid 1px rgba(90, 0, 144, 0.15)',
    maxWidth: '350px',
    margin: '0 auto',
};

const contentStyle = {
    padding: '1.7rem 2rem', // top/right/bottom/left
};

const labelStyle = {
    color: '#6D0085',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginTop: '2rem',
};

const priceStyle = {
    color: '#6D0085',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginTop: '1.63rem',
};

const lineStyle = {
    width: '21.6875rem',
    height: '0.0625rem',
    background: '#DBDBDB',
};

const tickStyle = {
    width: '0.95988rem',
    height: '0.72044rem',
    fill: '#3F0065',
};

const bulletContentStyle = {
    color: '#888',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    marginTop: '1.4rem',
};

const sellerInfo = {
    color: '#3F0065',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
};

const imageStyle = {
    width: '7rem',
    height: '6rem',
    borderRadius: '0.3125rem',
};

const Jamesandlaura = {
    color: '#7300BE',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
};

const BusinessGrowth = {
    color: '#2D2D2D',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
};

const BusniessGrowthSubtext = {
    color: '#757474',
    fontFamily: 'Poppins',
    fontSize: '0.6875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
};

const textAreaStyle = {
    width: '21.8125rem',
    height: '9.375rem',
    borderRadius: '0.4375rem',
    padding: '1.25rem',
    resize: 'none',
    color: '#858585', // Placeholder text color
    fontFamily: 'Poppins',
    fontSize: '1.0625rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
};

const dollarStyle = {
    color: '#6D0085',
    fontFamily: 'Poppins',
    fontSize: '0.9375rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
};

const enterCard = {
    color: 'var(--gray-500, #667085)',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.25rem',
    marginTop: '0.5rem',
    marginBottom: '2.3rem'
};

const planContainer = {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginTop: '1rem',
};

const planCard = {
    background: '#F7F3FC',
    padding: '1.25rem',
    borderRadius: '10px',
    border: '1px solid #6D0085',
    width: '100%',
    maxWidth: '350px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
};

const planTitle = {
    fontSize: '1.25rem',
    fontWeight: '600',
    color: '#6D0085',
};

const planPrice = {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginTop: '0.5rem',
    color: '#6D0085',
};

const planDescription = {
    fontSize: '1rem',
    fontWeight: '400',
    color: '#4A4A4A',
    marginTop: '0.1rem',
    lineHeight: '1.5'
};

// Adding missing FlexDiv component
const FlexDiv = ({ children, sx, ...props }) => {
    return (
        <div
            style={{
                display: 'flex',
                ...sx
            }}
            {...props}
        >
            {children}
        </div>
    );
};

// Helper function to format subscription features
const formatSubscriptionFeatures = (description) => {
    if (!description) return [];

    // Split by hyphens and clean up each feature
    return description
        .split('-')
        .map(feature => feature.trim())
        .filter(feature => feature) // Remove empty strings
        .map(feature => {
            // Shorten and format common phrases
            return feature
                .replace('a month', '/mo')
                .replace('images a month', 'images/mo')
                .replace('words a month', 'words/mo')
                // Add more replacements as needed
                .trim();
        })
        .filter(feature => feature.length > 0)
        .slice(0, 4); // Limit to 4 key features
};

const TokenExpired = (tokenDate) => {
    const convertedTime = new Date(tokenDate);
    const currentTime = new Date();
    if (currentTime >= convertedTime) {
        return true;
    }
    return false;
};

const BasePriceCard = ({ custom, productName, price, subscriptionPlans, sellerId, productId, setProductBought, firstName, lastName, profilePicture, companyName, bio, isAffiliate, url, productDetails }) => {
    const [showOrderSummary, setShowOrderSummary] = useState(false);
    const [extendSupport, setExtendSupport] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
    const [isEmailSentModalOpen, setIsEmailSentModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [inquireOpen, setInquireOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sendingMsg, setSendingMsg] = useState(false);
    const [message, setMessage] = useState('');
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [isFocused, setIsFocused] = useState(false);
    const [isExpired, setIsExpired] = useState(true);
    const [age, setAge] = useState('');
    const [productBuySuccess, setProductBuySuccess] = useState(false);
    const userData = (localStorage.getItem('access-token'));
    const isSmallMobile = useMediaQuery({ maxWidth: 450 });
    const stripe = useStripe();
    const elements = useElements();
    const { paymentFail, setPaymentFail, setPaymentSuccess, paymentSuccess, socket, newProductId } = useNotification();
    const { notification, notificationUpdated, setNotification, setNotificationUpdated, setNewProductId, setFetchAgain } = useNotification();
    const user = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const navigate = useNavigate();
    const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleInquireModalOpen = () => {
        setInquireOpen(true);
    };

    const handleInquireModalClose = () => {
        setInquireOpen(false);
    };

    const handleEmailSentModalClose = () => {
        setIsEmailSentModalOpen(false);
    };

    const handleModalOpen = async (e) => {
        if (!sellerId || !productId) {
            return;
        }
        handleTermsModalClose();
        setIsModalOpen(true);
    };

    const handleTextareaChange = (event) => {
        setMessage(event.target.value);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleTermsModalOpen = () => {
        if (!sellerId || !productId) {
            return;
        }
        if (!userData) {
            setSnackbarMsg("Login to purchase this product!!!");
            setSnackbarSeverity('error');
            setOpen(true);
            setTimeout(() => {
                navigate("/signup", { state: { redirectTo: window.location.pathname } });
            }, 300);
            return;
        }
        setIsTermsModalOpen(true);
    };

    const handleTermsAccept = () => {
        setIsTermsModalOpen(false);
        setShowOrderSummary(true);
    };

    const formatUrl = (url) => {
        if (!url) return ''; // Handle cases where url is null or undefined
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            return `https://${url}`;
        }
        return url;
    };

    const handleOrderSummaryConfirm = () => {
        setShowOrderSummary(false);
        if (isAffiliate) {
            let formattedUrl = url;
            if (!formattedUrl.startsWith("http://") && !formattedUrl.startsWith("https://")) {
                formattedUrl = "https://" + formattedUrl; // Add 'https://' if missing
            }
            window.open(formattedUrl, '_blank');
            return;
        }
        setIsModalOpen(true);
    };

    const handleOrderSummaryCancel = () => {
        setShowOrderSummary(false);
    };

    const handleTermsModalClose = () => {
        setIsTermsModalOpen(false);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (paymentFail) {
            setLoading(false);
            setSnackbarMsg("Payment Failed");
            setSnackbarSeverity('error');
            setOpen(true);
            setPaymentFail(false);
            handleModalClose();
        }
        if (paymentSuccess) {
            setLoading(false);
            handleModalClose();
            setSnackbarMsg('Payment Successful');
            setSnackbarSeverity('success');
            setOpen(true);
            setTimeout(() => {
                setProductBought(true);
                setIsEmailSentModalOpen(true);
            }, 1500);
            setPaymentSuccess(false);
        }
    }, [paymentFail, paymentSuccess]);

    const refreshtoken = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const token = localStorage.getItem('refresh-token');
                const response = await axios.post(`${IP_ADD}/api/auth/refresh-tokens`, {
                    refreshToken: token
                });

                if (response.status === 200) {
                    localStorage.setItem('access-token', response.data.response.response.tokens.access.token);
                    localStorage.setItem('refresh-token', response.data.response.response.tokens.refresh.token);
                    localStorage.setItem('access-token-expiry', response.data.response.response.tokens.access.expires);
                    localStorage.setItem('refresh-token-expiry', response.data.response.response.tokens.refresh.expires);
                    setIsExpired(false);

                    // Resolve the promise with the response data
                    resolve(response.data);
                } else {
                    // Reject the promise with an error message
                    reject(new Error('Token refresh failed.'));
                }
            } catch (error) {
                // Reject the promise with the error
                reject(error);
            }
        });
    };

    const accessChat = async () => {
        if (message === '') {
            setSnackbarMsg("Please enter a message");
            setSnackbarSeverity('error');
            setOpen(true);
            return;
        }
        if (!userData) {
            setSnackbarMsg("Please sign in to send a message");
            setSnackbarSeverity('error');
            setOpen(true);
            return;
        }
        const refreshExpiryToken = localStorage.getItem('refresh-token-expiry');
        const convertedTime = new Date(refreshExpiryToken);
        const currentTime = new Date();
        if (currentTime >= convertedTime) {
            localStorage.removeItem('access-token');
            localStorage.removeItem('refresh-token');
            localStorage.removeItem('access-token-expiry');
            localStorage.removeItem('refresh-token-expiry');
            localStorage.removeItem('Cornucopia-user');
            navigate('/login');
            return;
        }
        const accessExpiryToken = localStorage.getItem('access-token-expiry');
        if (TokenExpired(accessExpiryToken)) {
            try {
                // Assuming refreshtoken returns a promise
                await refreshtoken();
            } catch (refreshError) {
                console.log('Error refreshing token', refreshError);
                // Handle error if needed
            }
        }
        const userId = sellerId;
        try {
            setSendingMsg(true);
            const userData = (localStorage.getItem('access-token'));
            let config = {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${userData}`,
                },
            };
            const response = await axios.post(`${IP_ADD}/api/chats`, { userId }, config);
            const chatId = response.data.response._id;
            const formData = new FormData();

            formData.append('content', message);
            formData.append('chatId', chatId);
            config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userData}`,
                },
            };

            const { data } = await axios.post(
                `${IP_ADD}/api/messages`,
                formData,
                config
            );
            if (socket && socket.connected) {
                socket.emit('event', { type: 'message', data: data.response });
            }
            setMessage('');
            setSnackbarMsg("Message Delivered");
            setSnackbarSeverity('success');
            setOpen(true);
            const notificationApiResult = await sendNotificationApi(
                sellerId,
                message,
                user.response.id,
                "message"
            );
            if (!notificationApiResult.success) {
                console.log("Error in notification", notificationApiResult.error);
            }
        } catch (error) {
            console.log(error);
            setSnackbarMsg("Message Failed");
            setSnackbarSeverity('error');
            setOpen(true);
            throw error;
        } finally {
            setSendingMsg(false);
        }
    };

    const handleBuyProduct = async (e) => {
        try {
            setLoading(true);

            const cardElement = elements.getElement(CardElement);
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (error) {
                console.error('Error creating payment method:', error);
                setSnackbarMsg(error.message);
                setSnackbarSeverity('error');
                setOpen(true);
                return;
            }

            const apiResponse = await buyProductApi({
                sellerId,
                productId,
                price,
                paymentMethod,
                user,
            });

            if (apiResponse.success && apiResponse.data.message !== 'Something went wrong') {
                // Send notification on successful purchase
                if (apiResponse.data?.response?.metadata) {
                    const notificationApiResult = await sendNotificationApi(
                        apiResponse.data.response.metadata.seller_id,
                        productName,
                        user.response.id,
                        'payment'
                    );

                    if (!notificationApiResult.success) {
                        console.error('Error sending notification:', notificationApiResult.error);
                    }
                }

                setProductBuySuccess(true);
                handleModalClose(); // Close the modal on success
                setSnackbarMsg('Payment Successful');
                setSnackbarSeverity('success');
                setOpen(true);

                // Show email sent modal after a brief delay
                setTimeout(() => {
                    setIsEmailSentModalOpen(true);
                }, 1500);
            } else {
                throw new Error(apiResponse.error || 'Payment failed');
            }
        } catch (error) {
            console.error('Error in Payment:', error);
            setSnackbarMsg(error.message || 'Payment Failed');
            setSnackbarSeverity('error');
            setOpen(true);
            handleModalClose();
        } finally {
            setLoading(false);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setSnackbarMsg('');
    };

    const emailNotification = async () => {
        const emailNotification = await sendNotificationApi(
            user.response.id,
            "Product Purchased. Check your email",
            user.response.id,
            "webPayment",
            newProductId
        );
        const notificationApiResult = await fetchNotificationsApi(user.response.id);
        setNotification([...notificationApiResult.data]);
        setFetchAgain(true);
        console.log(emailNotification.data);
    };

    useEffect(() => {
        if (productBuySuccess) {
            emailNotification();
        }
    }, [newProductId]);

    const renderStats = () => (
        <FlexDiv sx={{
            gap: '1rem',
            color: '#737274',
            fontSize: '0.875rem',
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
            justifyContent: 'flex-start'
        }}>
            <FlexDiv sx={{
                gap: '0.25rem',
                alignItems: 'center',
                cursor: 'pointer'
            }}
                onClick={(e) => handleLikeClick(e)}>
                <FavoriteIcon
                    sx={{
                        fontSize: '1rem',
                        color: productDetails?.isLiked ? '#C87DD4' : '#737274',
                        '&:hover': { color: '#C87DD4' }
                    }}
                />
                <span>{productDetails?.likes || 0}</span>
            </FlexDiv>
            <FlexDiv sx={{
                gap: '0.25rem',
                alignItems: 'center'
            }}>
                <VisibilityIcon sx={{ fontSize: '1rem' }} />
                <span>{productDetails?.views || 0}</span>
            </FlexDiv>
        </FlexDiv>
    );

    const handleLikeClick = async (e) => {
        e.preventDefault();
        if (!userData) {
            navigate('/login');
            return;
        }

        try {
            await toggleProductLike(productDetails._id);
            // You might want to refresh the product details here
        } catch (error) {
            console.error("Error toggling like:", error);
        }
    };

    const handleOpenSubscriptionModal = () => setIsSubscriptionModalOpen(true);
    const handleCloseSubscriptionModal = () => setIsSubscriptionModalOpen(false);

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMsg}
                </MuiAlert>
            </Snackbar>
            <Card sx={customCardStyle}>
                <CardContent sx={contentStyle}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                    }}>

                        {custom ? (
                            <>
                                <div style={{ ...labelStyle, fontSize: isSmallMobile ? '1rem' : '1.375rem' }}>Price Range</div>
                                <div style={{ ...priceStyle, fontSize: isSmallMobile ? '1.6rem' : '2rem' }}>{price}</div>
                            </>
                        ) : (
                            <>
                                {price ? (
                                    <>
                                        <div style={{ ...labelStyle, fontSize: isSmallMobile ? '1rem' : '1.375rem' }}>{BASE_PRICE}</div>
                                        <div style={{ ...dollarStyle, marginTop: '1.44rem', marginLeft: 'auto' }}>{DOLLAR}</div>
                                        <div style={{ ...priceStyle, fontSize: isSmallMobile ? '1.6rem' : '2rem' }}>{price}</div>
                                    </>
                                ) : (
                                    <>
                                        {/* Subscription Plans Button */}
                                        {subscriptionPlans?.length > 0 && (
                                            <LoginBoxBtn
                                                onClick={handleOpenSubscriptionModal}
                                                style={{ width: '100%', marginTop: '1rem', justifyContent: 'center' }}
                                            >
                                                View Subscription Plans
                                            </LoginBoxBtn>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>

                    <div style={{ marginTop: isSmallMobile ? '1.3rem' : '1.81rem' }}>
                        <div style={{ ...lineStyle, width: '100%' }}></div>
                    </div>

                    {custom ? (
                        <LoginBoxBtn
                            style={{ width: '100%', marginTop: '2.56rem', justifyContent: 'center' }}
                            onClick={handleInquireModalOpen}
                        >
                            <img style={{ marginRight: '1.09rem', marginBottom: '0.3rem' }} src={PurchaseProductImg} alt="" />
                            {PURCHASE_PRODUCT}
                        </LoginBoxBtn>
                    ) : isAffiliate ? (
                        <LoginBoxBtn
                            as="a"
                            href={formatUrl(url)}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                width: '100%',
                                marginTop: '2.56rem',
                                justifyContent: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'none'
                            }}
                        >
                            <img style={{ marginRight: '1.09rem', marginBottom: '0.3rem' }} src={PurchaseProductImg} alt="" />
                            {PURCHASE_PRODUCT}
                        </LoginBoxBtn>
                    ) : price ? (
                        <LoginBoxBtn
                            style={{ width: '100%', marginTop: '2.56rem', justifyContent: 'center' }}
                            onClick={handleTermsModalOpen}
                        >
                            <img style={{ marginRight: '1.09rem', marginBottom: '0.3rem' }} src={PurchaseProductImg} alt="" />
                            {PURCHASE_PRODUCT}
                        </LoginBoxBtn>
                    ) : null}

                    <SOW sellerId={sellerId} inquireOpen={inquireOpen} setInquireOpen={setInquireOpen} />

                    <div style={{ ...sellerInfo, fontSize: isSmallMobile ? '1rem' : '1.375rem', marginTop: '1.69rem' }}>
                        {SELLER_INFO}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: isSmallMobile ? 'column' : 'inherit' }}>
                        <div style={{ marginTop: '1.62rem' }}>
                            <img style={imageStyle} src={profilePicture ? profilePicture : Avatar} alt='Seller Info' />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ ...Jamesandlaura, marginLeft: isSmallMobile ? '0' : '0.94rem', marginTop: isSmallMobile ? '0.5rem' : '2.2rem', textAlign: isSmallMobile ? 'center' : 'inherit' }}>
                                {firstName} {lastName}
                            </div>

                            <div style={{ ...BusinessGrowth, marginLeft: isSmallMobile ? '0' : '0.94rem', marginTop: '0.12rem', textAlign: isSmallMobile ? 'center' : 'inherit' }}>
                                {companyName ? companyName : BUSINESS_GROWTH}
                            </div>

                            <div style={{ ...BusniessGrowthSubtext, marginLeft: isSmallMobile ? '0' : '0.94rem', marginTop: '0.31rem', textAlign: isSmallMobile ? 'center' : 'inherit' }}>
                                {bio && bio.length > 120 ? `${bio.substring(0, 120)}...` : bio || "Tech enthusiast turned seller! Offering cutting-edge gadgets and solutions to elevate your tech experience!"}
                            </div>
                        </div>
                    </div>

                    {productDetails && renderStats()}

                    <div style={{ ...sellerInfo, fontSize: isSmallMobile ? '1rem' : '1.375rem', marginTop: '1.44rem' }}>
                        {MESSAGE}
                    </div>
                    <div style={{ marginTop: '1.25rem', }}>
                        <TextareaAutosize
                            style={{
                                ...textAreaStyle,
                                width: '100%',
                                border: '1px solid #ccc',
                                outline: isFocused ? '1px solid #3F0065' : 'none'
                            }}
                            placeholder={LEAVE_A_MSG}
                            value={message}
                            onChange={handleTextareaChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                        />
                    </div>
                    <div style={{ justifyContent: 'center' }}>
                        <LoginBoxBtn onClick={accessChat} style={{ width: '100%', marginTop: '1.7rem', }}>
                            {sendingMsg ? <CircularProgress color='secondary' style={{ width: '27px', height: '25px' }} /> :
                                <>
                                    <img style={{ marginRight: '0.5rem' }} src={DropMessage} alt="" />{DROP_A_MSG}
                                </>
                            }
                        </LoginBoxBtn>
                    </div>
                </CardContent>
            </Card>
            <EmailSentModal
                isOpen={isEmailSentModalOpen}
                handleClose={handleEmailSentModalClose}
            />

            <OrderSummaryModal
                price={price}
                showOrderSummary={showOrderSummary}
                handleOrderSummaryCancel={handleOrderSummaryCancel}
                isSmallMobile={isSmallMobile}
                productName={productName}
                handleOrderSummaryConfirm={handleOrderSummaryConfirm}
            />

            <PurchaseProduct
                isOpen={isModalOpen}
                onClose={handleModalClose}
                onBuyProduct={handleBuyProduct}
                loading={loading}
            />

            <TermsConditionsModal
                isOpen={isTermsModalOpen}
                onClose={handleTermsModalClose}
                onAccept={handleTermsAccept}
            />
            <SubscriptionPlansModal
                open={isSubscriptionModalOpen}
                onClose={handleCloseSubscriptionModal}
                subscriptionPlans={subscriptionPlans}
            />
        </>
    );
};

export default BasePriceCard;
