import { React, useState } from 'react';
import { Box, Grid, Typography, Button, Modal } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { ReactComponent as RealEstateIcon } from '../../assets/images/house-chimney-floor-svgrepo-com.svg';
import { ReactComponent as MarketingIcon } from '../../assets/images/marketing-svgrepo-com.svg';
import { ReactComponent as StoreIcon } from '../../assets/images/store-svgrepo-com.svg';
import { ReactComponent as EComIcon } from '../../assets/images/online-shop-ecommerce-svgrepo-com.svg';
import { ReactComponent as ToolboxIcon } from '../../assets/images/toolbox-svgrepo-com.svg';
import { ReactComponent as HospitalityIcon } from '../../assets/images/hotel-svgrepo-com.svg';
import { ReactComponent as CheckBoxIcon } from '../../assets/images/check-circle-svgrepo-com.svg';
import AwsLogo from "../../assets/images/aws.svg";
import LaunchLogo from "../../assets/images/launch.svg";
import NvidiaLogo from "../../assets/images/nvidia.svg";
import { CalendarToday } from '@mui/icons-material';
import { InlineWidget } from 'react-calendly';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const BusinessEmp = () => {
    const navigate = useNavigate();
    const [openCalendly, setOpenCalendly] = useState(false);
    const calendlyUrl = "https://calendly.com/tatmanman12/30min?";

    const handleCalendlyOpen = () => setOpenCalendly(true);
    const handleCalendlyClose = () => setOpenCalendly(false);



    const getIndustryButtons = (industry) => [
        {
            text: `Ready Made ${industry} Tools`,
            to: `/products?industry=${industry.toLowerCase().replace(/\s+/g, '-')}`
        },
        {
            text: `Custom ${industry} AI Tools for SMBs`,
            to: `/custom-ai?industry=${industry.toLowerCase().replace(/\s+/g, '-')}&type=custom`
        }
    ];

    const industries = [
        { icon: <RealEstateIcon />, title: "Real Estate", description: "Boost lead quality, predict property values, and streamline outreach. An AI marketplace empowers real estate SMBs with tools to close deals faster and maximize ROI." },
        { icon: <StoreIcon />, title: "Retail", description: "Optimize inventory, forecast trends, and personalize marketing. Leverage AI to make smarter decisions and thrive in a fast-changing market." },
        { icon: <MarketingIcon />, title: "Marketing", description: "Achieve targeted campaigns with advanced audience insights. AI marketplaces help SMBs automate strategies, driving engagement and ROI effortlessly." },
        { icon: <HospitalityIcon />, title: "Hospitality", description: "Revolutionize guest experiences with AI-driven bookings, pricing, and communication. Reduce costs, boost loyalty, and stand out with tailored solutions." },
        { icon: <ToolboxIcon />, title: "Home Services", description: "Automate scheduling, dispatching, and client updates. AI-powered tools increase efficiency, reduce downtime, and build customer trust." },
        { icon: <EComIcon />, title: "E-commerce", description: "Drive sales with personalized recommendations and optimized customer journeys. Scale operations effortlessly with AI tools designed for SMB growth." },
    ];

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://cornucopia-ai.com/products" />
            </Helmet>
            <Box sx={{ textAlign: "center", p: { xs: 2, md: 4 }, maxWidth: "1400px", margin: "0 auto" }}>
                {/* Partner Section */}
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mt: { xs: 2, md: 3 },
                        mb: { xs: 3, md: 4 },
                    }}
                >
                    <Typography
                        variant="h2"
                        component="h2"
                        sx={{
                            fontSize: { xs: "1.5rem", sm: "2rem" },
                            fontFamily: "Poppins",
                            fontWeight: "bold",
                            color: "#5c187e",
                            mb: { xs: 3, md: 4 },
                            mt: { xs: 2, md: -6 },
                        }}
                    >
                        Backed By
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: { xs: 4, md: 16 },
                            width: '100%',
                            px: { xs: 2, md: 4 },
                            mb: { xs: 4, md: 5 },
                        }}
                    >
                        {[
                            { name: "AWS", src: AwsLogo },
                            { name: "Launch", src: LaunchLogo },
                            { name: "NVIDIA", src: NvidiaLogo }
                        ].map((partner, index) => (
                            <Box
                                key={index}
                                sx={{
                                    width: { xs: '200px', sm: '250px', md: '300px' },
                                    height: { xs: '80px', sm: '100px', md: '120px' },
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <img
                                    src={partner.src}
                                    alt={partner.name}
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        objectFit: 'contain',
                                        maxWidth: '100%'
                                    }}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>

                {/* Existing Empower section */}
                <Typography variant="h2" component="h2" sx={{ fontSize: { xs: "1.5rem", sm: "2rem" }, fontFamily: "Poppins", fontWeight: "bold", color: "#5c187e", mb: 3 }}>
                    Empower your business with the right AI solutions.
                </Typography>
                <hr style={{ border: "1px solid purple", width: "60%", margin: "20px auto" }} />

                <Grid container spacing={3} sx={{ mt: 2, mb: 6 }}>
                    {["Made for SMBs.", "Integrated Effortlessly.", "Boost Productivity."].map((title, index) => (
                        <Grid item xs={12} sm={4} key={index}>
                            <Box sx={{ color: "purple", textAlign: "center" }}>
                                <Typography variant="h4" component="h4" sx={{ fontSize: "2rem", mb: 1, fontWeight: "bold", fontFamily: "Poppins", color: "#5c187e" }}>
                                    <CheckBoxIcon /> {title}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>

                <hr style={{ border: "1px solid purple" }} />

                <Grid container spacing={3} sx={{ mt: 4, mb: 6 }}>
                    {industries.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Box sx={{ backgroundColor: 'rgba(92, 24, 126, 0.03)', borderRadius: '16px', p: 3, height: '100%', display: 'flex', flexDirection: 'column', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)' } }}>
                                <Typography variant="h5" component="h5" sx={{ fontSize: { xs: '1.5rem', md: '1.75rem' }, mb: 2, fontWeight: "600", fontFamily: "Poppins", color: "#5c187e", display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                                    {item.icon} {item.title}
                                </Typography>
                                <Typography variant="body1" component="p" sx={{ fontFamily: "Poppins", color: "#666", mb: 3, flexGrow: 1, fontSize: '0.95rem', lineHeight: 1.6 }}>{item.description}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                                    {getIndustryButtons(item.title).map((button, btnIndex) => (
                                        <Button
                                            key={btnIndex}
                                            component={Link} // Use Link for React Router
                                            to={button.to}  // Define route for SEO
                                            sx={{
                                                borderRadius: '12px',
                                                textTransform: 'none',
                                                backgroundColor: btnIndex === 0 ? '#B666D2' : '#8A4DA8',
                                                color: 'white',
                                                py: 1,
                                                '&:hover': { opacity: 0.9 }
                                            }}
                                        >
                                            {button.text}
                                        </Button>
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                <hr style={{ border: "1px solid purple" }} />

                <Typography variant="h2" component="h2" sx={{ fontSize: { xs: "1.5rem", sm: "2rem" }, fontFamily: "Poppins", fontWeight: "bold", color: "#5c187e", mt: 3, mb: 4 }}>Need help onboarding your AI tool or want a free consultation to discover how AI can save your SMB time & money?
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<CalendarToday />}
                    onClick={handleCalendlyOpen}
                    sx={{
                        backgroundColor: "#A020F0",
                        component: "a",
                        color: "white",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        borderRadius: "30px",
                        fontFamily: "Poppins",
                        textTransform: "none",
                        "&:hover": {
                            backgroundColor: "#8A2BE2",
                        },
                    }}
                >
                    Schedule a Meeting
                </Button>
                <Modal
                    open={openCalendly}
                    onClose={handleCalendlyClose}
                    aria-labelledby="calendly-modal"
                    aria-describedby="calendly-scheduling-modal"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        sx={{
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            width: '80%',
                            height: '80%',
                            maxWidth: '1000px',
                            position: 'relative',
                        }}
                    >
                        <InlineWidget
                            url={calendlyUrl}
                            styles={{ height: '100%', width: '100%' }}
                        />
                    </Box>
                </Modal>

            </Box>
        </>
    );
};

export default BusinessEmp;
