import NavBar from "../../components/Navbar";
import ImageSlider from "./ImageSlider";
import { useState, useEffect } from "react";
import { FlexDiv } from "../../assets/styles/styles";
import { Typography, Grid } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import GroupIcon from '../../assets/images/group.svg';
import Trolley from "../../assets/images/trolley.svg";
import BasePriceCard from "../../components/ProductDetailsBuyer/BasePriceCard";
import Discover from "../../assets/images/discover.png";
import FooterDivider from "../../components/FooterDivider";
import Footer from "../../components/Footer";
import DoneIcon from '@mui/icons-material/Done';
import ReviewCard from "../../components/ProductDetailsBuyer/ReviewCard";
import { useNavigate } from 'react-router-dom';
import VideoModal from "../../components/ProductDetailsBuyer/VideoModal";
import { BACK_TO_PRODUCT_LIST, DESCRIPTION, FEATURES, LIVE_PREVIEW, PERKS_AND_BENEFITS, PRICE, PRODUCT_DETAILS, REVIEWS_AND_RATING, PRODUCT_LOREM_DESCRIPTION } from "../../constants/text";
import { Elements } from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import RateAndReview from "../../components/PurchasedProductAuthUser/RateAndReview"
import CircularProgress from '@mui/material/CircularProgress';
import NoReview from "../../components/SellerDashboard/NoReviews";
import { getCustomProductDetails, getProductDetails, getReviews, incrementProductViews, toggleProductLike } from "../../Services/Products";
import CustomPagination from "../../components/CustomPagination";
import { Helmet } from "react-helmet";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import DownloadModal from "./DownloadModal";
import { useNotification } from "../../Context/NotificationContext";
import { stripePromise, HEADER_DATA, BackDiv, LivePreview, FeaturesDiv, Images, BackToProduct, AddProduct, Heading, HeadingNum, FeaturesDivText, tickStyle, summary, TickText } from "./constants";
import { Container } from "@mui/material";
import styled from '@emotion/styled';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { IconButton } from '@mui/material';
import { Tooltip } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const DescriptionSection = styled('div')({
    maxWidth: '800px',
    marginTop: '32px',
    marginBottom: '32px',
    '& .description-title': {
        color: '#7C3AED',
        fontSize: '1.5rem',
        fontWeight: '600',
        marginBottom: '24px'
    },
    '& .main-intro': {
        fontSize: '1.1rem',
        color: '#666',
        lineHeight: '1.8',
        marginBottom: '32px'
    },
    '& .features-list': {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
    },
    '& .feature-item': {
        display: 'flex',
        gap: '16px',
        alignItems: 'flex-start',
        backgroundColor: '#f8f8f8',
        padding: '20px',
        borderRadius: '8px',
        '& .feature-content': {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px'
        },
        '& .feature-title': {
            fontSize: '1.1rem',
            fontWeight: '500',
            color: '#333'
        },
        '& .feature-description': {
            fontSize: '0.95rem',
            color: '#666',
            lineHeight: '1.6'
        }
    }
});

// Helper function to consolidate features into 3-4 main points
const consolidateFeatures = (description) => {
    if (!description) return { intro: '', features: [] };

    const sentences = description.split('.')
        .filter(s => s.trim())
        .map(s => s.trim());

    const intro = sentences[0];

    // Group remaining content into 3-4 main features
    const mainFeatures = [
        {
            title: 'Integration & Compatibility',
            description: sentences.find(s => s.includes('integrating') || s.includes('compatible')) || ''
        },
        {
            title: 'Content Generation',
            description: sentences.find(s => s.includes('content') || s.includes('generate')) || ''
        },
        {
            title: 'Advanced Features',
            description: sentences.find(s => s.includes('AI-Powered') || s.includes('features')) || ''
        }
    ].filter(f => f.description); // Only keep features that have content

    return { intro, features: mainFeatures };
};

const BottomSection = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '48px',
    marginTop: '48px',
    marginBottom: '48px',
    width: '100%',
    maxWidth: '1200px',
});

const FeaturesPerksGrid = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '64px',
    '@media (max-width: 1200px)': {
        gridTemplateColumns: 'repeat(2, 1fr)',
    },
    '@media (max-width: 768px)': {
        gridTemplateColumns: '1fr',
    }
});

const SectionTitle = styled('h2')({
    color: '#7C3AED',
    fontSize: '1.5rem',
    fontWeight: '600',
    marginBottom: '24px'
});

const ItemsList = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
});

const ListItem = styled('div')({
    display: 'flex',
    alignItems: 'flex-start',
    gap: '12px',
    '& .item-text': {
        color: '#666',
        fontSize: '1rem',
        lineHeight: '1.5'
    }
});

const ImageGrid = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '1rem',
    marginTop: '1rem',
    marginBottom: '2rem',
});

const ImageContainer = styled('div')({
    aspectRatio: '1/1',
    borderRadius: '8px',
    overflow: 'hidden',
    border: '1px solid #e0e0e0',
    position: 'relative',
    cursor: 'pointer',
    transition: 'transform 0.2s',
    '&:hover': {
        transform: 'scale(1.02)',
    }
});

const PlaceholderImage = styled('div')({
    width: '100%',
    height: '100%',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#666',
    fontSize: '0.875rem',
});

const BuyerProductDetails = ({ custom }) => {
    const [productDetails, setProductDetails] = useState(null);
    const [productFile, setProductFile] = useState(null);
    const isDesktop = useMediaQuery({ minWidth: 1170 });
    const isDesktopThree = useMediaQuery({ minWidth: 1050 });
    const [userHasReview, setUserHasReview] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 450 });
    const isDesktopTwo = useMediaQuery({ minWidth: 1280 });
    const isLaptop = useMediaQuery({ minWidth: 900 });
    const isTab = useMediaQuery({ minWidth: 640 });
    const [isModalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const [loading, setLoading] = useState(true);
    const [productBought, setProductBought] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [updatedReview, setUpdatedReview] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [productType, setProductType] = useState();
    const { newProductId } = useNotification();
    const [likes, setLikes] = useState(0);
    const [isLiked, setIsLiked] = useState(false);

    const handleBackClick = () => {
        if (!custom) {
            navigate('/products');
        }
        else {
            navigate('/custom-ai')
        }
    };

    const fetchProductDetails = async () => {
        if (!custom) {
            try {
                const productDetails = await getProductDetails(id);
                console.log(productDetails);
                if (productDetails.response) {
                    if (productDetails.response.productFiles) {
                        setProductFile(productDetails.response.productFiles)
                    }
                    if (productDetails.response.type) {
                        setProductType(productDetails.response.type)
                    }
                    console.log(productDetails.response)
                    setProductDetails(productDetails.response);
                    setLikes(productDetails.response.likes || 0);
                    setIsLiked(productDetails.response.isLiked || false);
                    setLoading(false);

                    // Increment view count when product details are viewed
                    if (userData) {  // Only track views for logged-in users
                        await incrementProductViews(id);
                        // Update local view count
                        setProductDetails(prev => ({
                            ...prev,
                            views: (prev.views || 0) + 1
                        }));
                    }
                }
                else {
                    setSnackbarMsg(productDetails.message);
                    setSnackbarSeverity("error");
                    setOpen(true);
                    setLoading(true);
                }
            } catch (error) {
                console.error('Error fetching product details:', error);
            }
        }
        else {
            try {
                const productDetails = await getCustomProductDetails(id);
                console.log(productDetails);
                if (productDetails.response) {
                    if (productDetails.response.productFiles) {
                        setProductFile(productDetails.response.productFiles)
                    }
                    if (productDetails.response.type) {
                        setProductType(productDetails.response.type)
                    }
                    setProductDetails(productDetails.response);
                    console.log(productDetails.response)
                    setLoading(false);

                    // Increment view count when product details are viewed
                    if (userData) {  // Only track views for logged-in users
                        await incrementProductViews(id);
                    }
                }
                else {
                    setSnackbarMsg(productDetails.message);
                    setSnackbarSeverity("error");
                    setOpen(true);
                    setLoading(true);
                }
            } catch (error) {
                console.error('Error fetching product details:', error);
            }
        }
    };

    const fetchReviews = async () => {
        try {
            let limit = NumOfReviews();
            const reviewsResponse = await getReviews(id, currentPage, undefined, undefined, setCurrentPage, limit);
            setReviews(reviewsResponse.reviews);
            setTotalPages(reviewsResponse.totalPages);
        } catch (error) {
            console.error('Error fetching reviews:', error);
        }
    };

    const handleCloseDownloadModal = () => {
        setShowDownloadModal(false);
    };
    const NumOfReviews = () => {
        if (!isTab) {
            return '1';
        } else if (!isLaptop) {
            return '2';
        } else {
            return '3';
        }
    };

    useEffect(() => {
        if (!productBought)
            setLoading(true);
        fetchProductDetails();
        if (productBought && productDetails?.productFiles) {
            setShowDownloadModal(true);
        }
    }, [productBought]);

    useEffect(() => {
        fetchReviews();
        setUpdatedReview(false);
    }, [currentPage, userHasReview, updatedReview, isTab, isLaptop]);

    const openModal = () => {
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setSnackbarMsg('');
        navigate('/my-products')
    };

    function chunkArray(arr, chunkSize) {
        const chunkedArray = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            chunkedArray.push(arr.slice(i, i + chunkSize));
        }
        return chunkedArray;
    }
    if (productDetails) {
    }
    console.log('productDetails::: ', productDetails);

    const handleLikeClick = async (e) => {
        e.preventDefault();
        if (!userData) {
            navigate('/login');
            return;
        }

        try {
            const response = await toggleProductLike(productDetails._id);
            if (response.success) {
                // Update local state
                setIsLiked(!isLiked);
                setLikes(prev => isLiked ? prev - 1 : prev + 1);
                // Update productDetails state
                setProductDetails(prev => ({
                    ...prev,
                    likes: isLiked ? prev.likes - 1 : prev.likes + 1,
                    isLiked: !isLiked
                }));
            }
        } catch (error) {
            console.error("Error toggling like:", error);
            setSnackbarMsg("Error updating like status");
            setSnackbarSeverity("error");
            setOpen(true);
        }
    };

    const handleShare = (platform) => {
        const url = window.location.href;
        const productName = productDetails?.name;
        const productDescription = productDetails?.description?.slice(0, 100) + '...'; // Get first 100 chars of description

        // Customize messages for each platform
        const messages = {
            twitter: `Check out ${productName} on Cornucopia AI! 🚀\n\n${productDescription}\n\n`,
            linkedin: `I found this amazing AI tool on Cornucopia AI!\n\n${productName}\n\n${productDescription}\n\nCheck it out here:`
        };

        // Platform-specific share URLs
        const shareUrls = {
            twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(messages.twitter)}&url=${encodeURIComponent(url)}`,
            linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`
        };

        window.open(shareUrls[platform], '_blank', 'width=600,height=400');
    };

    const handleImageClick = (imageUrl) => {
        // Implement image enlargement/preview functionality
        // You could use a modal or lightbox component
    };

    return (
        <>
            <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>

                <Helmet>
                    <title>Cornucopia AI | Buyer-Product Details</title>
                </Helmet>
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleClose}
                        severity={snackbarSeverity}
                    >
                        {snackbarMsg}
                    </MuiAlert>
                </Snackbar>
                {userData &&
                    <DownloadModal open={showDownloadModal} onClose={handleCloseDownloadModal} downloadLink={productFile} web={productType === 'web' ? true : false} heading={productType === 'web' ? 'Purchase Successful' : 'Download Link'} subHeading={productType === 'web' ? 'Please check your email for purchased website onboarding. If you dont receive email in 1 minute click Resend Email' : 'Click the button below to download the file:'} buttonText={productType === 'web' ? 'Cancel' : 'Download'} productId={newProductId} />
                }
                <NavBar header_data={HEADER_DATA} header_button={{ label: 'Join the venture', navigation_url: '/buyerseller' }} mobile_nav_height={'260px'} />
                {loading ?
                    <FlexDiv style={{ marginBottom: '-200px', justifyContent: 'center', alignItems: 'center', marginTop: '100px', height: '90vh' }}>
                        <CircularProgress color="secondary" style={{ width: '27px', height: '25px' }} />
                    </FlexDiv>
                    :
                    <div style={{ marginLeft: isDesktop ? '4.37rem' : '1rem', marginTop: '3.25rem', marginRight: isDesktop ? '3rem' : '1rem', marginBottom: '-200px' }}>
                        <FlexDiv style={{ justifyContent: 'space-between' }}>
                            <FlexDiv style={{ gap: '0.6rem', paddingTop: '0.5rem' }}>
                                <button style={{ display: 'contents' }} onClick={handleBackClick}>
                                    <BackDiv>
                                        <KeyboardBackspaceIcon sx={{ color: '#777777' }} />
                                    </BackDiv>
                                </button>
                                {!isMobile && <div>
                                    <Typography sx={BackToProduct}>{BACK_TO_PRODUCT_LIST}</Typography>
                                    <Typography sx={AddProduct}>{PRODUCT_DETAILS}</Typography>
                                </div>
                                }
                            </FlexDiv>
                            {productDetails?.demoVideo.includes('https://cornucopia-uploads') &&
                                <>
                                    <button style={{ display: 'contents' }} onClick={openModal}>
                                        <LivePreview style={{ marginTop: '0.5rem', paddingTop: '0.75rem' }}>
                                            <img src={GroupIcon} alt="" style={{ width: '0.90313rem', height: '1.0625rem', margin: '0rem 0.7rem 0rem 2.8rem' }} />
                                            {LIVE_PREVIEW}
                                        </LivePreview>
                                    </button>
                                    <VideoModal open={isModalOpen} onClose={closeModal} videoURL={productDetails.demoVideo} />
                                </>
                            }
                        </FlexDiv>
                        <FlexDiv style={{ marginTop: '1.5rem', justifyContent: 'space-between' }}>
                            <div>
                                <FlexDiv style={{ gap: isDesktopThree ? '1.3rem' : '', flexDirection: isDesktopThree ? 'row' : 'column' }}>
                                    {productDetails ? (
                                        <>
                                            <Typography sx={Heading}>{productDetails.name}</Typography>
                                            {productDetails.buyers && productDetails.buyers.length > 0 && (
                                                <Typography sx={HeadingNum} style={{ marginTop: '0.3rem' }}>
                                                    {`(${productDetails.buyers.length})`}
                                                </Typography>
                                            )}
                                        </>
                                    ) : <></>}
                                </FlexDiv>

                                <FlexDiv style={{ marginTop: '1rem', alignItems: 'center', gap: '2rem' }}>
                                    {/* Social Share Buttons */}
                                    <FlexDiv sx={{
                                        gap: '0.5rem',
                                        alignItems: 'center',
                                        backgroundColor: '#F9F5FF',
                                        padding: '8px 16px',
                                        borderRadius: '12px',
                                        boxShadow: '0 2px 4px rgba(124, 58, 237, 0.06)',
                                        transition: 'all 0.2s ease',
                                        '&:hover': {
                                            backgroundColor: '#F3E8FF',
                                            transform: 'translateY(-1px)',
                                            boxShadow: '0 4px 8px rgba(124, 58, 237, 0.1)'
                                        }
                                    }}>
                                        <IconButton
                                            onClick={() => handleShare('twitter')}
                                            sx={{
                                                padding: '4px',
                                                color: '#7C3AED'
                                            }}
                                        >
                                            <TwitterIcon sx={{ fontSize: '1.25rem' }} />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleShare('linkedin')}
                                            sx={{
                                                padding: '4px',
                                                color: '#7C3AED'
                                            }}
                                        >
                                            <LinkedInIcon sx={{ fontSize: '1.25rem' }} />
                                        </IconButton>
                                    </FlexDiv>

                                    {/* Stats Container with increased gap */}
                                    <FlexDiv sx={{
                                        gap: '2.5rem',
                                        alignItems: 'center'
                                    }}>
                                        {/* Likes */}
                                        <Tooltip
                                            title={userData ? "Like this product" : "Login to like this product"}
                                            placement="top"
                                            arrow
                                        >
                                            <FlexDiv sx={{
                                                gap: '0.5rem',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                backgroundColor: '#F9F5FF',
                                                padding: '8px 20px',
                                                borderRadius: '12px',
                                                boxShadow: '0 2px 4px rgba(124, 58, 237, 0.06)',
                                                transition: 'all 0.2s ease',
                                                '&:hover': {
                                                    backgroundColor: '#F3E8FF',
                                                    transform: 'translateY(-1px)',
                                                    boxShadow: '0 4px 8px rgba(124, 58, 237, 0.1)'
                                                }
                                            }}
                                                onClick={handleLikeClick}>
                                                <FavoriteIcon
                                                    sx={{
                                                        fontSize: '1.35rem',
                                                        color: isLiked ? '#7C3AED' : '#9CA3AF',
                                                        transition: 'all 0.2s ease'
                                                    }}
                                                />
                                                <Typography sx={{
                                                    fontSize: '0.9rem',
                                                    color: '#666',
                                                    fontWeight: '500',
                                                    minWidth: '2rem',
                                                    textAlign: 'center'
                                                }}>
                                                    {likes}
                                                </Typography>
                                            </FlexDiv>
                                        </Tooltip>

                                        {/* Views */}
                                        <Tooltip
                                            title="Total number of views"
                                            placement="top"
                                            arrow
                                        >
                                            <FlexDiv sx={{
                                                gap: '0.5rem',
                                                alignItems: 'center',
                                                backgroundColor: '#F9F5FF',
                                                padding: '8px 20px',
                                                borderRadius: '12px',
                                                boxShadow: '0 2px 4px rgba(124, 58, 237, 0.06)',
                                                transition: 'all 0.2s ease',
                                                '&:hover': {
                                                    backgroundColor: '#F3E8FF',
                                                    transform: 'translateY(-1px)',
                                                    boxShadow: '0 4px 8px rgba(124, 58, 237, 0.1)'
                                                }
                                            }}>
                                                <VisibilityIcon sx={{
                                                    fontSize: '1.35rem',
                                                    color: '#7C3AED'
                                                }} />
                                                <Typography sx={{
                                                    fontSize: '0.9rem',
                                                    color: '#666',
                                                    fontWeight: '500',
                                                    minWidth: '2rem',
                                                    textAlign: 'center'
                                                }}>
                                                    {productDetails?.views || 0}
                                                </Typography>
                                            </FlexDiv>
                                        </Tooltip>
                                    </FlexDiv>
                                </FlexDiv>

                                <FlexDiv style={{ marginTop: '1rem' }}>
                                    {productDetails && chunkArray(productDetails.category, 3).map((filteredArray) => (
                                        <FlexDiv key={`key-${filteredArray[0]}`} style={{ gap: '0.25rem', marginTop: '0.8rem' }}>
                                            {filteredArray.map((data) => (
                                                <FeaturesDiv key={data} style={{ padding: '0.2rem 0rem 0.2rem 0.5rem', gap: '0.2rem' }}>
                                                    <img src={Trolley} alt="" style={{ width: '0.6875rem', height: '0.625rem', marginTop: '0.2rem' }} />
                                                    <Typography sx={FeaturesDivText} style={{ marginRight: '0.3rem' }}>{data}</Typography>
                                                </FeaturesDiv>
                                            ))}
                                        </FlexDiv>
                                    ))}
                                </FlexDiv>
                                <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                                </div>
                                {productDetails && isDesktopThree && (
                                    <img
                                        src={productDetails.images[0]}
                                        alt=""
                                        style={{
                                            borderRadius: '1.125rem',
                                            width: isDesktop ? "39.3125rem" : '35rem',
                                            height: "28.75rem",
                                            marginTop: '1.5rem',
                                            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)'
                                        }}
                                    />
                                )}
                                {!isDesktopThree && productDetails && (
                                    <div style={{ width: isLaptop ? '50vw' : '90vw', marginTop: '1rem' }}>
                                        <ImageSlider images={productDetails.images} />
                                    </div>
                                )}

                                {productDetails && isDesktopThree &&
                                    <Grid container gap={isDesktopTwo ? '0rem' : isMobile ? '1rem' : '3rem'} style={{ marginTop: '1.6rem' }}>
                                        {!isDesktopThree && productDetails && (
                                            <Grid item lg={4} md={4} sm={isTab ? 3 : 5} xs={isMobile ? 10 : 5}>
                                                <Images src={productDetails.images[0] || Discover} alt="" />
                                            </Grid>
                                        )}
                                        {productDetails.images[1] &&
                                            < Grid item lg={4} md={isDesktopThree ? 3 : 4} sm={isLaptop ? 4 : isTab ? 3 : 5} xs={isDesktopThree ? 3 : isMobile ? 10 : 5}>
                                                <Images src={productDetails.images[1]} alt="" />
                                            </Grid>
                                        }
                                        {productDetails.images[2] &&
                                            <Grid item lg={4} md={isDesktopThree ? 3 : 4} sm={isLaptop ? 4 : isTab ? 3 : 5} xs={isDesktopThree ? 3 : isMobile ? 10 : 5}>
                                                <Images src={productDetails.images[2]} alt="" />
                                            </Grid>
                                        }
                                        {productDetails.images[3] &&
                                            <Grid item lg={4} md={isDesktopThree ? 3 : 4} sm={isLaptop ? 4 : isTab ? 3 : 5} xs={isDesktopThree ? 3 : isMobile ? 10 : 5}>
                                                <Images src={productDetails.images[3]} alt="" />
                                            </Grid>
                                        }
                                    </Grid>
                                }
                            </div>
                            {isLaptop &&
                                <div>
                                    {productDetails && userData ? (
                                        <>
                                            {productDetails.buyers && productDetails.buyers.includes(userData.response.id) ? (
                                                // User is in buyers array, display RateAndReview component
                                                <RateAndReview sellerId={productDetails.user.id} productID={productDetails.id} productCost={productDetails.price} userHasReview={userHasReview} setUserHasReview={setUserHasReview} firstName={productDetails.user.firstName} lastName={productDetails.user.lastName} profilePicture={productDetails.user.displayPicture ? productDetails.user.displayPicture : null} companyName={productDetails.user.company ? productDetails.user.company.companyName : null} bio={productDetails.user.bio ? productDetails.user.bio : null} setUpdatedReview={setUpdatedReview} />
                                            ) : (
                                                // User is not in buyers array, display BasePriceCard component
                                                <Elements stripe={stripePromise}>
                                                    {!custom ? (
                                                        <>
                                                            <BasePriceCard custom={custom} productName={productDetails.name} price={productDetails.price} subscriptionPlans={productDetails.subscriptionPlans} sellerId={productDetails.user.id} productId={productDetails.id} setProductBought={setProductBought} firstName={productDetails.user.firstName} lastName={productDetails.user.lastName} profilePicture={productDetails.user.displayPicture ? productDetails.user.displayPicture : null} companyName={productDetails.user.company ? productDetails.user.company.companyName : null} bio={productDetails.user.bio ? productDetails.user.bio : null} isAffiliate={productDetails?.isAffiliate} url={productDetails?.url} />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <BasePriceCard custom={custom} productName={productDetails.name} price={productDetails.priceRangePackages} subscriptionPlans={productDetails.subscriptionPlans} sellerId={productDetails.user.id} productId={productDetails.id} setProductBought={setProductBought} firstName={productDetails.user.firstName} lastName={productDetails.user.lastName} profilePicture={productDetails.user.displayPicture ? productDetails.user.displayPicture : null} companyName={productDetails.name ? productDetails.name : null} bio={productDetails.user.bio ? productDetails.user.bio : null} isAffiliate={productDetails.isAffiliate} url={productDetails?.url} />
                                                        </>
                                                    )}
                                                </Elements>
                                            )}
                                        </>
                                    ) : (
                                        productDetails ? (
                                            <Elements stripe={stripePromise}>
                                                {console.log(productDetails.user.id)}
                                                {!custom ? (
                                                    <>
                                                        <BasePriceCard custom={custom} productName={productDetails.name} price={productDetails.price} subscriptionPlans={productDetails.subscriptionPlans} sellerId={productDetails.user.id} productId={productDetails.id} setProductBought={setProductBought} firstName={productDetails.user.firstName} lastName={productDetails.user.lastName} profilePicture={productDetails.user.displayPicture ? productDetails.user.displayPicture : null} companyName={productDetails.user.company ? productDetails.user.company.companyName : null} bio={productDetails.user.bio ? productDetails.user.bio : null} isAffiliate={productDetails?.isAffiliate} url={productDetails?.url} />
                                                    </>
                                                ) : (
                                                    <>
                                                        <BasePriceCard custom={custom} productName={productDetails.name} price={productDetails.priceRangePackages} subscriptionPlans={productDetails.subscriptionPlans} sellerId={productDetails.user.id} productId={productDetails.id} setProductBought={setProductBought} firstName={productDetails.user.firstName} lastName={productDetails.user.lastName} profilePicture={productDetails.user.displayPicture ? productDetails.user.displayPicture : null} companyName={productDetails.user.company ? productDetails.user.company.companyName : null} bio={productDetails.user.bio ? productDetails.user.bio : null} isAffiliate={productDetails?.isAffiliate} url={productDetails?.url} />
                                                    </>
                                                )}
                                            </Elements>
                                        ) : null
                                    )}
                                </div>
                            }
                        </FlexDiv >
                        <BottomSection>
                            <FeaturesPerksGrid>
                                <div>
                                    <SectionTitle>{FEATURES}</SectionTitle>
                                    <ItemsList>
                                        {productDetails?.features?.map((feature, index) => (
                                            <ListItem key={index}>
                                                <DoneIcon sx={{ color: '#7C3AED' }} />
                                                <Typography className="item-text">{feature}</Typography>
                                            </ListItem>
                                        ))}
                                    </ItemsList>
                                </div>

                                {/* <div>
                                    <SectionTitle>{PERKS_AND_BENEFITS}</SectionTitle>
                                    <ItemsList>
                                        {productDetails?.perks?.map((perk, index) => (
                                            <ListItem key={index}>
                                                <DoneIcon sx={{ color: '#7C3AED' }} />
                                                <Typography className="item-text">{perk}</Typography>
                                            </ListItem>
                                        ))}
                                    </ItemsList>
                                </div> */}

                                <div>
                                    <SectionTitle>Data Collected</SectionTitle>
                                    <ItemsList>
                                        {productDetails?.dataCollected?.map((dataType, index) => (
                                            <ListItem key={index}>
                                                <DoneIcon sx={{ color: '#7C3AED' }} />
                                                <Typography className="item-text">{dataType}</Typography>
                                            </ListItem>
                                        ))}
                                        {(!productDetails?.dataCollected || productDetails.dataCollected.length === 0) && (
                                            <Typography
                                                sx={{
                                                    color: '#666',
                                                    fontStyle: 'italic',
                                                    fontSize: '0.9rem'
                                                }}
                                            >
                                                No data collection information available
                                            </Typography>
                                        )}
                                    </ItemsList>
                                </div>
                            </FeaturesPerksGrid>
                            <div style={{
                                marginTop: '1rem',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '0.5rem'
                            }}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="description-content"
                                        id="description-panel-header"
                                        sx={{
                                            borderBottom: '1px solid #e0e0e0',
                                            '&:hover': { backgroundColor: '#f5f5f5' }
                                        }}
                                    >
                                        <Typography sx={Heading} style={{ fontSize: '1.25rem' }}>{DESCRIPTION}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                                        <Typography sx={summary}>{productDetails?.description}</Typography>
                                    </AccordionDetails>
                                </Accordion>

                                {/* {!custom && productDetails?.perks && productDetails.perks.length > 0 && (
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="perks-content"
                                            id="perks-panel-header"
                                            sx={{
                                                borderBottom: '1px solid #e0e0e0',
                                                '&:hover': { backgroundColor: '#f5f5f5' }
                                            }}
                                        >
                                            <Typography sx={Heading} style={{ fontSize: '1.25rem' }}>{PERKS_AND_BENEFITS}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                                            {productDetails.perks.map((perk, index) => (
                                                <FlexDiv key={`perk-${index}`} style={{ marginTop: '0.5rem', gap: '0.5rem' }}>
                                                    <DoneIcon sx={tickStyle} style={{ marginTop: '0.3rem' }} />
                                                    <Typography sx={summary}>{perk}</Typography>
                                                </FlexDiv>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                )}
 */}
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="data-collected-content"
                                        id="data-collected-panel-header"
                                        sx={{
                                            borderBottom: '1px solid #e0e0e0',
                                            '&:hover': { backgroundColor: '#f5f5f5' }
                                        }}
                                    >
                                        <Typography sx={Heading} style={{ fontSize: '1.25rem' }}>Data Collected</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                                        <Typography sx={summary}>{productDetails?.dataCollected || "No data collection information available"}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div>
                                <SectionTitle>Reviews and Rating</SectionTitle>
                                {
                                    reviews && reviews.length > 1 ? (
                                        <FlexDiv style={{ justifyContent: 'center', padding: '2rem 0rem 0rem 0rem', gap: isDesktopThree ? '2.5rem' : '1rem' }}>
                                            {reviews.slice(0, -1).map((review) => (
                                                <div key={review._id} style={{ marginTop: '1.5rem' }}>
                                                    {review.user && review.user.fullName && (
                                                        <ReviewCard
                                                            rating={review.rating}
                                                            comment={review.comment}
                                                            fullName={review.user.fullName}
                                                            date={review.createdAt}
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                        </FlexDiv>
                                    ) : (
                                        <div style={{ marginTop: '1rem' }}>
                                            <NoReview />
                                        </div>
                                    )
                                }
                                {
                                    reviews.length > 1 &&
                                    <FlexDiv style={{ justifyContent: 'center', marginTop: '2rem' }}>
                                        <CustomPagination page={currentPage}
                                            onChange={(event, newPage) => setCurrentPage(newPage)} count={totalPages} />
                                    </FlexDiv>
                                }
                            </div>
                        </BottomSection>
                    </div >
                }
            </Container>
            <FooterDivider />
            <Footer />
        </>
    )
}
export default BuyerProductDetails;
