export const HEADER_DATA = [
  {
    id: "1",
    name: "Home",
    margin_right: "2rem",
    navigation_url: "/",
  },
  {
    id: "2",
    name: "Why Cornucopia",
    margin_right: "2rem",
    navigation_url: "/why-cornucopia",
  },
  {
    id: "3",
    name: "Marketplace",
    margin_right: "2rem",
    navigation_url: "/products",
  },
  {
    id: "4",
    name: "Custom AI for SMBs",
    margin_right: "2rem",
    navigation_url: "/custom-ai",
  },
  {
    id: "5",
    name: "Pricing",
    margin_right: "2rem",
    navigation_url: "/pricing",
  },
  {
    id: "6",
    name: "Login",
    margin_right: "0rem",
    navigation_url: "/login",
  },
];
