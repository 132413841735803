import React from 'react';
import { FlexDiv, TrendProductTitle, TrendProductsText, AnalyticsText } from '../../assets/styles/styles';
import Trolley from '../../assets/images/trolley.svg';
import Coins from '../../assets/images/coins.svg';
import People from '../../assets/images/people.svg';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { motion } from 'framer-motion';

const getImageWidth = (isMobileTwo, isMobile) => {
    if (isMobileTwo && !isMobile) {
        return '21.5rem';
    } else if (isMobile) {
        return '18rem';
    }
    else {
        return '14.25rem'
    }
};

const TrendingProductsCard = ({ product: { name, price, description, image, buyers, id, isAffiliate }, tags = ['Business', 'Retail'], custom }) => {
    const navigate = useNavigate();
    const isMobileTwo = useMediaQuery({ maxWidth: 599 });
    const isMobile = useMediaQuery({ maxWidth: 350 });
    const displayText = description || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.';
    const truncatedText = displayText.length > 73 ? `${displayText.slice(0, 71)}...` : displayText;
    const imageWidth = getImageWidth(isMobileTwo, isMobile);

    const handleProductClick = () => {
        if (!custom) {
            navigate(`/product-details/${id}`);
        }
        else {
            navigate(`/custom-product-details/${id}`);

        }
    };

    return (
        <button onClick={handleProductClick} style={{ display: 'contents' }}>
            <motion.div whileHover={{ scale: 1.1 }} transition={{ duration: 0.4 }} style={{ marginBottom: isMobileTwo ? '-5rem' : '0' }}>
                <img
                    alt=""
                    src={image || '../../assets/images/trending_product.png'}
                    style={{
                        borderRadius: '1rem',
                        width: imageWidth,
                        height: '15.9375rem',
                        boxSizing: 'border-box',
                        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
                    }}
                />
                <FlexDiv style={{ justifyContent: 'space-between', marginTop: '1rem' }}>
                    <TrendProductTitle>{name}</TrendProductTitle>
                    {!custom && (
                        isAffiliate ? (
                            <TrendProductTitle>Affiliate</TrendProductTitle>
                        ) : (
                            <TrendProductTitle>{'$' + price}</TrendProductTitle>
                        )
                    )}
                </FlexDiv>
                <TrendProductsText style={{ marginTop: '0.5rem', textAlign: 'start', overflow: 'hidden' }}>
                    {truncatedText}
                </TrendProductsText>
                <FlexDiv style={{ marginTop: '0.5rem' }}>
                    <img alt="" src={Trolley} style={{ marginRight: '0.5rem' }} />
                    <img alt="" src={Coins} style={{ marginRight: '1.5rem' }} />
                    <AnalyticsText style={{ marginRight: '1rem', fontSize: '0.75rem', marginTop: '0.5rem' }}>{tags[0] === 'Buisness' ? 'Business' : tags[0]}</AnalyticsText>
                    {tags.length > 1 && <AnalyticsText style={{ fontSize: '0.75rem', marginTop: '0.5rem' }}>{tags[0].length + tags[1].length > 20 ? '...' : tags[1] === 'Buisness' ? 'Business' : tags[1]}</AnalyticsText>}
                </FlexDiv>
                <FlexDiv style={{ marginTop: '0.5rem' }}>
                    <img alt="" src={People} style={{ marginRight: '1rem' }} />
                    <AnalyticsText style={{ fontSize: '0.75rem', marginBottom: '0' }}>{buyers || '0'} buyers</AnalyticsText>
                </FlexDiv>
            </motion.div>
        </button>
    );
};

export default TrendingProductsCard;
