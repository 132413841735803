import { React, useState } from 'react';
import { InlineWidget } from 'react-calendly';
import {
    CalendarMonth,
    VerifiedUserOutlined,
    AutoAwesome,
    VisibilityOutlined,
    ChevronRight,
    ShieldOutlined,
    MonetizationOnOutlined,
    BoltOutlined,
    AttachMoney,
    PersonOutlined,
    RocketLaunchOutlined,
    CheckCircle,
    FormatQuote
} from '@mui/icons-material';

import {
    Box,
    Button,
    Container,
    Grid,
    Paper,
    Typography,
    Card,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import NavBar from "../../components/Navbar";
import { HEADER_DATA } from "./constants";
import Footer from '../../components/Footer';
import FooterDivider from "../../components/FooterDivider";
import verifiedBadge from '../../assets/images/verified-badge.svg';

const TESTIMONIALS = [
    {
        quote: "As a developer who's built web apps for everyone from bakeries to landscaping companies, I've always struggled with one thing: translating vague client ideas into features that actually solve their problems. Then I stumbled on Cornucopia AI – and it's changed the game.",
        author: "Derek Little",
        position: "A Little Programming Company LLC"
    },
    {
        quote: "Great service for AI businesses looking to target SMBs!",
        author: "Evan Carraccolio",
        position: "Vairo AI"
    },
    {
        quote: "Cornucopia has provided us with valuable exposure to our target market and receptive clients, while also creating flexibility for us to create products and offer our services through the marketplace.",
        author: "Taha Hasan",
        position: "Co-Founder - Slipstream"
    }
];

const CornucopiaVerified = () => {

    const [openCalendlySMB, setOpenCalendlySMB] = useState(false);
    const [openCalendlyDeveloper, setOpenCalendlyDeveloper] = useState(false);

    const handleOpenSMB = () => setOpenCalendlySMB(true);
    const handleCloseSMB = () => setOpenCalendlySMB(false);

    const handleOpenDeveloper = () => setOpenCalendlyDeveloper(true);
    const handleCloseDeveloper = () => setOpenCalendlyDeveloper(false);

    return (
        <>
            <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
                <NavBar
                    header_data={HEADER_DATA}
                    header_button={{
                        label: 'Join the venture',
                        navigation_url: '/buyerseller'
                    }}
                    mobile_nav_height={'16.5rem'}
                />

                {/* Hero Section */}
                <Box component="header" sx={{
                    bgcolor: 'primary.main',
                    py: 10,
                    color: 'common.white',
                    textAlign: 'center',
                    background: 'linear-gradient(90deg, #5c187e, #9333ea)'
                }}>
                    <Typography component="h1" variant="h2" fontWeight="bold">
                        Why Choose Cornucopia AI
                    </Typography>
                    <Typography component="h2" variant="h5" sx={{ opacity: 0.9 }}>
                        The AI Marketplace Built for SMBs & Developers
                    </Typography>
                </Box>

                <Container maxWidth="lg" sx={{ pb: 0 }}>
                    {/* Stats Section */}
                    <Paper component="section" elevation={3} sx={{ p: 3, borderRadius: 2, mt: -4 }}>
                        <Grid container spacing={3} textAlign="center">
                            <Grid item xs={12} md={4}>
                                <Typography component="h3" variant="h4" sx={{ color: '#5c187e', fontWeight: 'bold' }}>$4.4T</Typography>
                                <Typography component="p">Projected AI market value by 2025</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography component="h3" variant="h4" sx={{ color: '#5c187e', fontWeight: 'bold' }}>76%</Typography>
                                <Typography component="p">of SMBs lack AI expertise & resources</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography component="h3" variant="h4" sx={{ color: '#5c187e', fontWeight: 'bold' }}>89%</Typography>
                                <Typography component="p">of AI startups struggle with SMB market entry</Typography>
                            </Grid>
                        </Grid>
                    </Paper>

                    {/* Value Proposition */}
                    <Box component="section" sx={{ textAlign: 'center', mb: 8, mt: 8 }}>
                        <Typography
                            component="h2"
                            variant="h3"
                            sx={{
                                color: '#5c187e',
                                mb: 2,
                                fontWeight: 'bold'
                            }}
                        >
                            Bridging the AI Adoption Gap
                        </Typography>
                        <Typography
                            component="p"
                            variant="h6"
                            sx={{
                                color: 'text.secondary',
                                maxWidth: '800px',
                                mx: 'auto'
                            }}
                        >
                            AI adoption for SMBs is exploding, yet many struggle to find trusted, high-quality AI tools tailored to their needs. At the same time, AI developers face challenges in gaining visibility, trust, and sustainable revenue. Cornucopia AI bridges this gap.
                        </Typography>
                    </Box>

                    {/* SMB Benefits */}

                    <Box component="section" sx={{ mb: 10 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2, mb: 4 }}>
                            <PersonOutlined sx={{ fontSize: 40, color: '#5c187e' }} />
                            <Typography component="h2" variant="h4" sx={{ color: '#5c187e', fontWeight: 'bold' }}>
                                Why SMBs Choose Cornucopia AI
                            </Typography>
                        </Box>


                        <Grid container spacing={4}>
                            {/* Trust & Verification Card */}
                            <Grid item xs={12} md={4}>
                                <Card
                                    sx={{
                                        p: 4,
                                        bgcolor: 'rgba(243, 232, 255, 0.5)',
                                        border: '1px solid #E9D5FF',
                                        borderRadius: 4,
                                        transition: 'all 0.3s',
                                        '&:hover': {
                                            bgcolor: '#F3E8FF',
                                            boxShadow: 3
                                        }
                                    }}
                                >
                                    <ShieldOutlined sx={{ fontSize: 48, color: '#5c187e', mb: 2 }} />
                                    <Typography variant="h5" sx={{ color: '#5c187e', mb: 2, fontWeight: 600 }}>
                                        Trust & Verification
                                    </Typography>
                                    <List>
                                        {[
                                            'Rigorous AI tool verification',
                                            'Security & compliance guaranteed',
                                            'Real-world performance testing'
                                        ].map((item, index) => (
                                            <ListItem key={index} sx={{ px: 0 }}>
                                                <ListItemIcon sx={{ minWidth: 32 }}>
                                                    <ChevronRight sx={{ color: '#5c187e' }} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={item}
                                                    primaryTypographyProps={{
                                                        fontFamily: 'Poppins',
                                                        color: 'text.secondary'
                                                    }}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Card>
                            </Grid>

                            {/* Seamless Integration Card */}
                            <Grid item xs={12} md={4}>
                                <Card
                                    sx={{
                                        p: 4,
                                        bgcolor: 'rgba(243, 232, 255, 0.5)',
                                        border: '1px solid #E9D5FF',
                                        borderRadius: 4,
                                        transition: 'all 0.3s',
                                        '&:hover': {
                                            bgcolor: '#F3E8FF',
                                            boxShadow: 3
                                        }
                                    }}
                                >
                                    <BoltOutlined sx={{ fontSize: 48, color: '#5c187e', mb: 2 }} />
                                    <Typography variant="h5" sx={{ color: '#5c187e', mb: 2, fontWeight: 600 }}>
                                        Seamless Integration
                                    </Typography>
                                    <List>
                                        {['One-click tool adoption', 'Unified AI management dashboard', 'No-code implementation'].map((item, index) => (
                                            <ListItem key={index} sx={{ px: 0 }}>
                                                <ListItemIcon sx={{ minWidth: 32 }}>
                                                    <ChevronRight sx={{ color: '#5c187e' }} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={item}
                                                    primaryTypographyProps={{
                                                        fontFamily: 'Poppins',
                                                        color: 'text.secondary'
                                                    }}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Card>
                            </Grid>

                            {/* Cost-Effective Card */}
                            <Grid item xs={12} md={4}>
                                <Card
                                    sx={{
                                        p: 4,
                                        bgcolor: 'rgba(243, 232, 255, 0.5)',
                                        border: '1px solid #E9D5FF',
                                        borderRadius: 4,
                                        transition: 'all 0.3s',
                                        '&:hover': {
                                            bgcolor: '#F3E8FF',
                                            boxShadow: 3
                                        }
                                    }}
                                >
                                    <MonetizationOnOutlined sx={{ fontSize: 48, color: '#5c187e', mb: 2 }} />
                                    <Typography variant="h5" sx={{ color: '#5c187e', mb: 2, fontWeight: 600 }}>
                                        Cost-Effective
                                    </Typography>
                                    <List>
                                        {['Exclusive marketplace discounts', 'Bundle pricing options', 'Usage-based pricing available'].map((item, index) => (
                                            <ListItem key={index} sx={{ px: 0 }}>
                                                <ListItemIcon sx={{ minWidth: 32 }}>
                                                    <ChevronRight sx={{ color: '#5c187e' }} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={item}
                                                    primaryTypographyProps={{
                                                        fontFamily: 'Poppins',
                                                        color: 'text.secondary'
                                                    }}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ mb: 10 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2, mb: 4 }}>
                            <RocketLaunchOutlined sx={{ fontSize: 40, color: '#5c187e' }} />
                            <Typography variant="h4" sx={{ color: '#5c187e', fontWeight: 'bold' }}>
                                Why AI Developers Choose Cornucopia AI
                            </Typography>
                        </Box>

                        <Grid container spacing={4}>
                            {/* Market Access Card */}
                            <Grid item xs={12} md={4}>
                                <Card
                                    sx={{
                                        p: 4,
                                        bgcolor: 'rgba(243, 232, 255, 0.5)',
                                        border: '1px solid #E9D5FF',
                                        borderRadius: 4,
                                        transition: 'all 0.3s',
                                        '&:hover': {
                                            bgcolor: '#F3E8FF',
                                            boxShadow: 3
                                        }
                                    }}
                                >
                                    <VisibilityOutlined sx={{ fontSize: 48, color: '#5c187e', mb: 2 }} />
                                    <Typography variant="h5" sx={{ color: '#5c187e', mb: 2, fontWeight: 600 }}>
                                        Market Access
                                    </Typography>
                                    <List>
                                        {["Exposure to SMBs actively seeking AI tools", "Targeted marketing support", "Featured product placements"].map((item, index) => (
                                            <ListItem key={index} sx={{ px: 0 }}>
                                                <ListItemIcon sx={{ minWidth: 32 }}>
                                                    <ChevronRight sx={{ color: '#5c187e' }} />
                                                </ListItemIcon>
                                                <ListItemText primary={item} primaryTypographyProps={{ fontFamily: 'Poppins', color: 'text.secondary' }} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Card>
                            </Grid>

                            {/* Revenue Growth Card */}
                            <Grid item xs={12} md={4}>
                                <Card
                                    sx={{
                                        p: 4,
                                        bgcolor: 'rgba(243, 232, 255, 0.5)',
                                        border: '1px solid #E9D5FF',
                                        borderRadius: 4,
                                        transition: 'all 0.3s',
                                        '&:hover': {
                                            bgcolor: '#F3E8FF',
                                            boxShadow: 3
                                        }
                                    }}
                                >
                                    <MonetizationOnOutlined sx={{ fontSize: 48, color: '#5c187e', mb: 2 }} />
                                    <Typography variant="h5" sx={{ color: '#5c187e', mb: 2, fontWeight: 600 }}>
                                        Revenue Growth
                                    </Typography>
                                    <List>
                                        {["Recurring revenue opportunities", "Flexible pricing models", "Access to SMB purchasing power"].map((item, index) => (
                                            <ListItem key={index} sx={{ px: 0 }}>
                                                <ListItemIcon sx={{ minWidth: 32 }}>
                                                    <ChevronRight sx={{ color: '#5c187e' }} />
                                                </ListItemIcon>
                                                <ListItemText primary={item} primaryTypographyProps={{ fontFamily: 'Poppins', color: 'text.secondary' }} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Card>
                            </Grid>

                            {/* Validation & Trust Card */}
                            <Grid item xs={12} md={4}>
                                <Card
                                    sx={{
                                        p: 4,
                                        bgcolor: 'rgba(243, 232, 255, 0.5)',
                                        border: '1px solid #E9D5FF',
                                        borderRadius: 4,
                                        transition: 'all 0.3s',
                                        '&:hover': {
                                            bgcolor: '#F3E8FF',
                                            boxShadow: 3
                                        }
                                    }}
                                >
                                    <PersonOutlined sx={{ fontSize: 48, color: '#5c187e', mb: 2 }} />
                                    <Typography variant="h5" sx={{ color: '#5c187e', mb: 2, fontWeight: 600 }}>
                                        Validation & Trust
                                    </Typography>
                                    <List>
                                        {["Verified AI badge", "Credibility with SMB buyers", "Performance validation"].map((item, index) => (
                                            <ListItem key={index} sx={{ px: 0 }}>
                                                <ListItemIcon sx={{ minWidth: 32 }}>
                                                    <ChevronRight sx={{ color: '#5c187e' }} />
                                                </ListItemIcon>
                                                <ListItemText primary={item} primaryTypographyProps={{ fontFamily: 'Poppins', color: 'text.secondary' }} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Verification Process */}
                    <Box component="section" sx={{ mb: 10 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2, mb: 4 }}>
                            <VerifiedUserOutlined sx={{ fontSize: 40, color: '#5c187e' }} />
                            <Typography component="h2" variant="h4" sx={{ color: '#5c187e', fontWeight: 'bold' }}>
                                Our Verification Process
                            </Typography>
                        </Box>
                        <Card
                            sx={{
                                p: 4,
                                background: 'linear-gradient(135deg, #F3E8FF, #FFFFFF)',
                                border: '1px solid #E9D5FF',
                                borderRadius: 4
                            }}
                        >
                            <Grid container spacing={4}>
                                {[
                                    {
                                        title: '1. Performance Review',
                                        items: ['Real-world testing', 'SMB use case validation', 'Performance Metrics']
                                    },
                                    {
                                        title: '2. Security Check',
                                        items: ['GDPR, SOC 2 compliance', 'Data protection standards', 'Check for security risks']
                                    },
                                    {
                                        title: '3. Integration Testing',
                                        items: ['API reliability checks', 'Platform compatibility']
                                    }
                                ].map((section, index) => (
                                    <Grid item xs={12} md={4} key={index}>
                                        <Typography variant="h5" sx={{ color: '#5c187e', mb: 2, fontWeight: 600 }}>
                                            {section.title}
                                        </Typography>
                                        <List>
                                            {section.items.map((item, itemIndex) => (
                                                <ListItem key={itemIndex} sx={{ px: 0 }}>
                                                    <ListItemIcon sx={{ minWidth: 32 }}>
                                                        <CheckCircle sx={{ color: '#5c187e' }} />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={item}
                                                        primaryTypographyProps={{
                                                            fontFamily: 'Poppins',
                                                            color: 'text.secondary'
                                                        }}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                ))}
                            </Grid>
                        </Card>
                    </Box>

                    {/* Verification Badge */}
                    <Box component="section" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: 6,
                        mb: 8,
                        p: 4,
                        background: 'linear-gradient(135deg, #F3E8FF, #FFFFFF)',
                        borderRadius: '16px',
                        border: '1px solid #E9D5FF'
                    }}>
                        <Grid container spacing={3} alignItems="center" justifyContent="center">
                            <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                                <Box
                                    component="img"
                                    src={verifiedBadge}
                                    alt="Cornucopia AI Verified"
                                    sx={{
                                        width: '120px',
                                        height: '120px',
                                        mb: { xs: 2, md: 0 }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography component="h2" variant="h4" sx={{ 
                                    color: '#5c187e',
                                    fontWeight: 'bold',
                                    mb: 2
                                }}>
                                    Cornucopia AI Verified Badge
                                </Typography>
                                <Typography component="p" variant="body1" sx={{
                                    color: '#666',
                                    fontSize: '1.1rem',
                                    lineHeight: '1.6'
                                }}>
                                    Tools that successfully pass our comprehensive verification process earn the Cornucopia AI Verified badge. 
                                    This badge represents our seal of approval, indicating that the tool meets our high standards for 
                                    performance, security, and reliability. Look for this badge when choosing AI tools to ensure you're 
                                    getting verified, trusted solutions for your business.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* New Testimonials Section */}
                    <Box component="section" sx={{ 
                        my: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 4
                    }}>
                        <Typography
                            component="h2"
                            variant="h3"
                            sx={{
                                color: '#5c187e',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                mb: 4,
                                fontFamily: 'Poppins'
                            }}
                        >
                            Trusted by Industry Leaders
                        </Typography>
                        
                        <Grid container spacing={4} maxWidth="lg">
                            {TESTIMONIALS.map((testimonial, index) => (
                                <Grid item xs={12} md={4} key={index}>
                                    <Box
                                        sx={{
                                            bgcolor: 'rgba(243, 232, 255, 0.5)',
                                            p: 4,
                                            borderRadius: '16px',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 2,
                                            border: '1px solid #E9D5FF',
                                            transition: 'all 0.3s',
                                            '&:hover': {
                                                bgcolor: '#F3E8FF',
                                                transform: 'translateY(-4px)',
                                                boxShadow: 2
                                            }
                                        }}
                                    >
                                        <FormatQuote sx={{ color: '#5c187e', fontSize: 40 }} />
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontFamily: 'Poppins',
                                                fontSize: '1.1rem',
                                                color: '#666',
                                                fontStyle: 'italic',
                                                flexGrow: 1
                                            }}
                                        >
                                            "{testimonial.quote}"
                                        </Typography>
                                        <Box>
                                            <Typography
                                                variant="subtitle1"
                                                sx={{
                                                    fontFamily: 'Poppins',
                                                    fontWeight: 'bold',
                                                    color: '#5c187e'
                                                }}
                                            >
                                                {testimonial.author}
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                sx={{
                                                    fontFamily: 'Poppins',
                                                    color: '#666'
                                                }}
                                            >
                                                {testimonial.position}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    {/* CTA Section */}
                    <Box
                        component="section"
                        sx={{
                            p: 6,
                            borderRadius: 4,
                            background: 'linear-gradient(135deg, #F3E8FF, #FFFFFF)',
                            border: '1px solid #E9D5FF'
                        }}
                    >
                        <Typography component="h2" variant="h3" sx={{ color: '#5c187e', textAlign: 'center', mb: 6, fontFamily: 'Poppins' }}>
                            Ready to Transform Your Business?
                        </Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Card
                                    sx={{
                                        p: 4,
                                        height: '100%',
                                        transition: 'all 0.3s',
                                        '&:hover': {
                                            boxShadow: 6
                                        }
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                                        <PersonOutlined sx={{ fontSize: 40, color: '#5c187e' }} />
                                        <Typography variant="h5" sx={{ color: '#5c187e', fontFamily: 'Poppins' }}>For SMBs</Typography>
                                    </Box>
                                    <Typography sx={{ color: 'text.secondary', mb: 3, fontFamily: 'Poppins' }}>
                                        Schedule a free consultation to discover how our verified AI tools can transform your operations.
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        sx={{
                                            bgcolor: '#9333EA',
                                            '&:hover': {
                                                bgcolor: '#7E22CE'
                                            },
                                            py: 1.5,
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            fontFamily: 'Poppins'
                                        }}
                                        onClick={handleOpenSMB}
                                    >
                                        <CalendarMonth sx={{ fontFamily: 'Poppins' }} />
                                        Schedule Free Consultation
                                        <ChevronRight />
                                    </Button>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card
                                    sx={{
                                        p: 4,
                                        height: '100%',
                                        transition: 'all 0.3s',
                                        '&:hover': {
                                            boxShadow: 6
                                        }
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                                        <AutoAwesome sx={{ fontSize: 40, color: '#5c187e' }} />
                                        <Typography variant="h5" sx={{ color: '#5c187e', fontFamily: 'Poppins' }}>For AI Developers</Typography>
                                    </Box>
                                    <Typography sx={{ color: 'text.secondary', mb: 3, fontFamily: 'Poppins' }}>
                                        Join our marketplace and get instant access to SMBs actively seeking AI solutions.
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        sx={{
                                            bgcolor: '#9333EA',
                                            '&:hover': {
                                                bgcolor: '#7E22CE'
                                            },
                                            py: 1.5,
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            fontFamily: 'Poppins'

                                        }}
                                        onClick={handleOpenDeveloper}
                                    >
                                        <CalendarMonth sx={{ fontFamily: 'Poppins' }} />
                                        Schedule Onboarding Call
                                        <ChevronRight />
                                    </Button>
                                </Card>
                            </Grid>
                        </Grid>

                        {/* Calendly Modals */}
                        {openCalendlySMB && (
                            <Box
                                sx={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100vw',
                                    height: '100vh',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 1300
                                }}
                                onClick={handleCloseSMB}
                            >
                                <Box
                                    sx={{
                                        bgcolor: 'background.paper',
                                        boxShadow: 24,
                                        width: '80%',
                                        height: '80%',
                                        maxWidth: '1000px',
                                        position: 'relative',
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <InlineWidget url="https://calendly.com/tatmanman12/30min?" />
                                </Box>
                            </Box>
                        )}

                        {openCalendlyDeveloper && (
                            <Box
                                sx={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100vw',
                                    height: '100vh',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 1300
                                }}
                                onClick={handleCloseDeveloper}
                            >
                                <Box
                                    sx={{
                                        bgcolor: 'background.paper',
                                        boxShadow: 24,
                                        width: '80%',
                                        height: '80%',
                                        maxWidth: '1000px',
                                        position: 'relative',
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <InlineWidget url="https://calendly.com/tatmanman12/30min?" />
                                </Box>
                            </Box>)}
                    </Box>
                    {/* New Footer Banner */}
                    {/* <Box
                    sx={{
                        py: 8,
                        px: 2,
                        background: 'linear-gradient(90deg, #9333EA, #A855F7)',
                        borderRadius: 2,
                        textAlign: 'center',
                        color: 'white',
                        mb: 6
                    }}
                >
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        Join the AI Revolution Today
                    </Typography>
                    <Typography sx={{ mb: 4, maxWidth: '600px', mx: 'auto', opacity: 0.9 }}>
                        Whether you're an SMB looking to leverage AI or a developer ready to scale your AI tool, Cornucopia AI is your trusted partner.
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            bgcolor: 'white',
                            color: '#9333EA',
                            '&:hover': {
                                bgcolor: '#F3E8FF'
                            },
                            px: 4,
                            py: 1.5,
                            fontWeight: 600
                        }}
                        onClick={() => window.location.href = '/buyerseller'}
                    >
                        Get Started Now
                    </Button>
                </Box> */}
                </Container>
            </Box>
            <FooterDivider sx={{ mt: 0 }} />
            <Footer />

        </>
    );
};

export default CornucopiaVerified;