import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import OnlineIcon from '../../assets/images/online_icon.svg';
import { useMediaQuery } from "react-responsive";
import { useNotification } from '../../Context/NotificationContext';
import { Avatar } from '@mui/material';


const formatTimeInPST = (timestamp) => {
    const date = new Date(timestamp);
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localTime = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        timeZone: userTimezone,
    });
    return localTime;
};


// Updated styles with enhanced visual design
const cardStyle = {
    maxWidth: '100%',
    borderRadius: '0.5rem',
    background: '#FFF',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.2s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
        transform: 'translateY(-1px)',
    }
};


const profilePictureContainerStyle = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
};


const onlineIconStyle = {
    position: 'absolute',
    bottom: '0',
    right: '0',
    width: '0.75rem',
    height: '0.75rem',
    background: '#22C55E',
    border: '2px solid white',
    borderRadius: '50%'
};


const nameStyle = {
    color: '#3F0065',
    fontFamily: 'Poppins',
    fontSize: '0.938rem',
    fontWeight: 600,
    lineHeight: '1.25',
    marginBottom: '0.25rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
};


const messageStyle = {
    color: '#6B7280',
    fontFamily: 'Poppins',
    fontSize: '0.813rem',
    fontWeight: 400,
    lineHeight: '1.25',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100%'
};


const timeStyle = {
    color: '#6B7280',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
    marginLeft: 'auto',
    paddingLeft: '0.75rem'
};


const contentContainerStyle = {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '1rem',
    padding: '0.75rem'
};


const textContainerStyle = {
    flex: 1,
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column'
};


const avatarStyle = {
    width: '2.875rem',
    height: '2.875rem',
    borderRadius: '50%',
    fontSize: '1rem',
    fontWeight: 500
};


const InboxChat = ({
    firstName = 'Jennifer',
    lastName = 'Fritz',
    content,
    notification,
    chatID,
    displayPicture,
    color,
    time
}) => {
    const isTab = useMediaQuery({ minWidth: 750 });
    const isSmallMobile = useMediaQuery({ maxWidth: 390 });
    const { chatId } = useNotification();


    const cardWidth = !isSmallMobile && !isTab ? '22rem' : '17rem';

    const cardStyleWithNotification = {
        ...cardStyle,
        width: cardWidth,
        border: chatID === chatId ? '2px solid rgba(79, 0, 103, 0.8)' : '1px solid rgba(0, 0, 0, 0.08)'
    };


    const combinedName = `${firstName} ${lastName}`;
    const displayName = combinedName.length > 20 ? `${combinedName.substring(0, 19)}...` : combinedName;
    const truncatedContent = notification ?
        <span style={{ fontWeight: 600, color: '#3F0065' }}>New Message</span> :
        (content.length > 45 ? `${content.substring(0, 45)}...` : content);


    return (
        <Card style={cardStyleWithNotification}>
            <CardContent style={{ padding: 0 }}>
                <div style={contentContainerStyle}>
                    <div style={profilePictureContainerStyle}>
                        {displayPicture ? (
                            <img
                                src={displayPicture}
                                alt={displayName}
                                style={avatarStyle}
                            />
                        ) : (
                            <Avatar
                                sx={{
                                    ...avatarStyle,
                                    bgcolor: color,
                                    border: '2px solid rgba(79, 0, 103, 0.1)'
                                }}
                            >
                                {`${firstName[0]}${lastName[0]}`}
                            </Avatar>
                        )}
                        <div style={onlineIconStyle} />
                    </div>


                    <div style={textContainerStyle}>
                        <div style={nameStyle}>{displayName}</div>
                        <div style={messageStyle}>{truncatedContent}</div>
                    </div>
                    <div style={timeStyle}>
                        {time ? formatTimeInPST(time) : '3:08pm'}
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};


export default InboxChat;
