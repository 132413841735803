import React from 'react';
import { TrendingProductsText, AiProductsText, FlexDiv } from '../../assets/styles/styles';
import { useMediaQuery } from 'react-responsive';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import NoProduct from '../SellerDashboard/NoProducts';
import DoneIcon from '@mui/icons-material/Done';

const MAX_DESCRIPTION_LENGTH = 300;

const TrendingProducts = ({ products = [], trendingProductsText = 'Trending Products', custom }) => {
    const isMobile = useMediaQuery({ maxWidth: 550 });
    const isTablet = useMediaQuery({ maxWidth: 900 });
    const navigate = useNavigate();

    const handleProductClick = (id) => {
        // Temporary fix, we have to ensure we are mapping each product from products to the TrendingProductsCard in the future
        if (!custom) {
            navigate(`/product-details/${id}`);
        }
        else {
            navigate(`/custom-product-details/${id}`);
        }
    };

    return (
        <div style={{ margin: isTablet ? '0rem 1rem 1rem 1rem' : '0rem 4rem 1rem 6.5rem' }}>
            <FlexDiv style={{ justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', textAlign: 'left', width: '100%' }}>
                <TrendingProductsText style={{ fontSize: isMobile ? '1.4rem' : '', alignSelf: 'flex-start' }}>
                    {trendingProductsText}
                </TrendingProductsText>
                <AiProductsText style={{ alignSelf: 'flex-start' }}>AI products helping to boost your business and profits.</AiProductsText>
            </FlexDiv>

            {products.length === 0 ? (
                <div style={{ marginTop: '2rem' }}>
                    <NoProduct />
                </div>
            ) : (
                <Grid container spacing={4} justifyContent="center">
                    {products.map((product, index) => (
                        <Grid item key={index} lg={6} md={12} sm={12} xs={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin: '1rem 0',
                            }}>
                            <div
                                onClick={() => handleProductClick(product.id)}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '1.5rem',
                                    padding: '1.5rem',
                                    width: '100%',
                                    maxWidth: '900px',
                                    minHeight: '250px',
                                    cursor: 'pointer',
                                    transition: 'all 0.3s ease',
                                    background: 'white',
                                    borderRadius: '15px',
                                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
                                    textAlign: 'left',
                                    overflow: 'hidden'
                                }}
                            >
                                {/* Left Section: Image */}
                                <div
                                    style={{
                                        width: '40%',
                                        height: '200px',
                                        borderRadius: '15px',
                                        overflow: 'hidden',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <img
                                        src={product.images?.[0] || ''}
                                        alt={product.name}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            borderRadius: '15px',
                                        }}
                                    />
                                </div>

                                {/* Right Section: Product Details */}
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '0.8rem' }}>
                                    <h3 style={{ margin: '0', fontSize: '1.4rem', fontWeight: '600', color: '#4A148C' }}>
                                        {product.name}
                                        <span style={{ fontSize: '1.3rem', fontWeight: 'bold', color: '#333', marginLeft: '10px' }}>
                                            {!custom
                                                ? (product.isAffiliate ? "Affiliate" : `$${product.price}`)
                                                : `${product.priceRangePackages}`}
                                        </span>
                                    </h3>

                                    {/* Categories */}
                                    <div style={{ display: 'flex', gap: '6px', flexWrap: 'wrap', fontSize: '0.9rem' }}>
                                        {product.category?.map((tag, idx) => (
                                            <span
                                                key={idx}
                                                style={{
                                                    background: '#EDE7F6',
                                                    padding: '4px 8px',
                                                    borderRadius: '6px',
                                                    fontSize: '0.9rem',
                                                    fontWeight: '500',
                                                    color: '#4A148C',
                                                }}
                                            >
                                                {tag}
                                            </span>
                                        ))}
                                    </div>

                                    {/* Description */}
                                    <p style={{ fontSize: '1rem', color: '#666', lineHeight: '1.5', maxWidth: '100%' }}>
                                        {product.description.length > MAX_DESCRIPTION_LENGTH
                                            ? product.description.substring(0, MAX_DESCRIPTION_LENGTH) + '...'
                                            : product.description}
                                    </p>

                                    {/* Features Section */}
                                    {product.features && product.features.length > 0 && (
                                        <div>
                                            <p style={{ fontWeight: 'bold', color: '#4A148C', marginBottom: '0.5rem' }}>Features</p>
                                            <ul style={{ listStyle: 'none', padding: 0, margin: 0, fontSize: '1rem', color: '#666' }}>
                                                {product.features.slice(0, 3).map((feature, idx) => (
                                                    <li key={idx} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                        <DoneIcon style={{ fontSize: '1.2rem', color: '#4A148C' }} />
                                                        {feature}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            )}
        </div>
    );
};

export default TrendingProducts;
