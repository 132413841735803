import { FlexDiv } from "../../../assets/styles/styles";
import { TextField, InputAdornment, Typography } from "@mui/material";
import Chart from 'react-apexcharts'
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import NoTransactions from '../NoTransactions';
import AnimateNumber from "./AnimateNumber";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useMediaQuery } from "react-responsive";
import { useState, useEffect } from 'react';
import UserProfileSeller from '../UserProfileSeller';
import { JOHNSON, MAXIMIZE_PRODUCT_SALES, WELCOME_BACK, TRANSACTION_STATS, SALES_ANALYTICS, RECENT_TRANSACTION } from '../../../constants/text';
import { format, parseISO } from 'date-fns';
import { DashboardHeading, DashboardSubHeading, TransactionText, AnalyticsDiv, TableOuterDiv, TransactionStatusDiv, StyledTableCell, AnalyticsData as InitialAnalyticsData, data, TableHeaders, options, pageSize, ItemImg, series, circleColors } from './constants';
import { fetchTransactionDataApi } from "../../../Services/Transaction";
import { Helmet } from "react-helmet"
import Avatar from "@mui/material/Avatar";
import { fetchFinancialAnalyticsApi } from "../../../Services/Analytics";
import CircularProgress from '@mui/material/CircularProgress';

const Dashboard = () => {
    const isDesktop = useMediaQuery({ minWidth: 1000 });
    const isLaptop = useMediaQuery({ minWidth: 620 });
    const isLaptoptwo = useMediaQuery({ minWidth: 720 });
    const isMobile = useMediaQuery({ minWidth: 520 });
    const isSmallMobile = useMediaQuery({ maxWidth: 440 });
    const [loading, setLoading] = useState(true);
    const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const userId = userData.response.id
    const [transactionData, setTransactionData] = useState([]);
    const [AnalyticsData, setAnalyticsData] = useState(InitialAnalyticsData);
    const [monthlyTransactions, setMonthlyTransactions] = useState([]);

    const fetchTransactionData = async () => {
        try {
            const data = await fetchTransactionDataApi(userId, pageSize);
            setTransactionData(data);
        } catch (error) {
            console.log("Error occured while fetching transactions", error)
        } finally {
            setLoading(false);
        }
    };

    const fetchFinancialAnalytics = async () => {
        try {
            const financialData = await fetchFinancialAnalyticsApi(userId);
            setAnalyticsData((prevData) => [
                { ...prevData[0], total: financialData.response.totalBuyers },
                { ...prevData[1], total: financialData.response.totalProfit },
                { ...prevData[2], total: financialData.response.totalProducts },
                // { ...prevData[3], total: financialData.response.totalExpenses },
            ]);
            const chartData = financialData.response.monthlyData.map(item => ({
                name: item.month,
                value: parseFloat(item.profit) // Convert string to number for chart rendering
            }));
            setMonthlyTransactions(chartData);
        } catch (error) {
            console.error('Error fetching financial analytics:', error);
        }
    };

    const convertDateFormat = (date_string) => {
        const parsedDate = parseISO(date_string);
        const formattedDate = format(parsedDate, "MM/dd/yyyy HH:mm");
        return formattedDate;
    }

    useEffect(() => {
        fetchTransactionData();
        fetchFinancialAnalytics();
    }, [userId]);

    return (
        <>
            <Helmet>
                <title>Cornucopia AI | Dashboard</title>
            </Helmet>
            <FlexDiv style={{ justifyContent: isDesktop ? 'space-between' : 'flex-end', paddingTop: '1.5rem' }}>
                {isDesktop &&
                    <DashboardHeading style={{ color: '#30004E', fontSize: isSmallMobile ? '1.5rem' : '' }}>{WELCOME_BACK}<span style={{ color: '#6D0085' }}>{userData ? userData.response.firstName : JOHNSON}</span></DashboardHeading>
                }
                <UserProfileSeller />
            </FlexDiv>
            {!isDesktop &&
                <FlexDiv style={{ marginTop: '2.3rem', justifyContent: isDesktop ? 'space-between' : 'center', alignItems: isDesktop ? '' : 'center', flexDirection: isDesktop ? 'row' : 'column' }}>
                    <div style={{ marginLeft: isDesktop ? '1.5rem' : '' }}>
                        <DashboardHeading style={{ color: '#30004E', textAlign: 'center', fontSize: isSmallMobile ? '1.5rem' : '' }}>{WELCOME_BACK}<span style={{ color: '#6D0085' }}>{userData ? userData.response.firstName : JOHNSON}</span></DashboardHeading>
                        {/* {isLaptop && <DashboardSubHeading>{MAXIMIZE_PRODUCT_SALES}</DashboardSubHeading>} */}
                    </div>
                </FlexDiv>
            }
            <Grid container style={{ marginTop: '2.19rem' }}>
                {AnalyticsData.map((data) => (
                    <Grid item key={data.name} lg={3} md={6} sm={6} xs={isMobile ? 6 : 12} display="flex" flexDirection="column" alignItems='center' marginTop='1rem'>
                        <AnalyticsDiv>
                            <FlexDiv>
                                <img src={data.line} alt="" style={{ margin: '1.5rem 0rem 0rem 1.5rem' }} />
                                <div style={{ margin: '1.5rem 0rem 0rem 1rem' }}>
                                    <h3 style={{ color: '#808080', fontFamily: 'Poppins', fontSize: '0.9375rem', fontWeight: '400' }}>{data.name}</h3>
                                    <h3 style={{ color: '#000', fontFamily: 'Poppins', fontSize: '1.375rem', fontWeight: '500' }}>
                                        <AnimateNumber value={parseFloat(data.total) < 0 ? 0 : parseFloat(data.total).toFixed(2)} />
                                    </h3>
                                </div>
                                <img src={data.image} alt="" style={{ margin: data.allMargins }} />
                            </FlexDiv>
                        </AnalyticsDiv>
                    </Grid>
                ))}
            </Grid>
            <FlexDiv style={{ marginTop: '1.87rem', paddingRight: '1rem', paddingLeft: '1rem', justifyContent: isDesktop ? 'space-between' : 'center', alignItems: isDesktop ? '' : 'center', gap: '1rem', flexDirection: isDesktop ? 'row' : 'column', marginBottom: '2rem' }}>
                {/* <div style={{ width: isSmallMobile ? '100%' : '24rem', height: isSmallMobile ? '100%' : '17rem', borderRadius: '0.5625rem', background: '#F9F9F9', paddingBottom: isSmallMobile ? '1rem' : '' }}>
                    <TransactionText style={{ margin: '1.25rem 0rem 0rem 1.5rem' }}>{TRANSACTION_STATS}</TransactionText>
                    <Chart options={options} series={series} type="donut" width={isSmallMobile ? '340' : '380'} />
                </div> */}
                <div style={{ width: '100%', height: '17rem', borderRadius: '0.5625rem', background: '#F9F9F9', marginBottom: isDesktop ? '' : '2rem' }}>
                    <TransactionText style={{ margin: '1.25rem 0rem 0.5rem 1.5rem' }}>{SALES_ANALYTICS}</TransactionText>
                    <ResponsiveContainer width="95%" height={220}>
                        <AreaChart data={monthlyTransactions}>
                            <CartesianGrid strokeDasharray="transparent" />
                            <XAxis dataKey="name" tick={{ fontSize: '0.8125rem' }} />
                            <YAxis tick={{ fontSize: '0.75rem' }} />
                            <Tooltip />
                            <defs>
                                <linearGradient id="gradientFill" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="rgba(63, 0, 101, 0.59)" />
                                    <stop offset="100%" stopColor="rgba(217, 217, 217, 0.00)" />
                                </linearGradient>
                            </defs>
                            <Area type="monotone" dataKey="value" fill={`url(#gradientFill)`} stroke="#3F0065" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </FlexDiv>
            {loading ?
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '20rem' }}>
                    <CircularProgress color='secondary' />
                </div>
                : transactionData.length > 0 ? (
                    <TableOuterDiv style={{ marginRight: '1rem', padding: '1rem', marginLeft: isLaptoptwo ? '' : '1rem', marginBottom: '1.5rem' }}>
                        <TransactionText style={{ padding: '1.25rem 0rem 0rem 0.5rem' }}>{RECENT_TRANSACTION}</TransactionText>
                        <TableContainer>
                            <Table aria-label="customized table" sx={{ width: '100%' }}>
                                <TableHead>
                                    <TableRow>
                                        {TableHeaders.map((data, index) => (
                                            <TableCell key={index} sx={{ color: '#605F5F', fontFamily: 'Poppins', fontSize: '0.9375rem', fontWeight: 'bold', minWidth: isMobile ? 'auto' : '150px' }}>{data}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {transactionData.map((transaction, index) => (
                                        <TableRow key={transaction.id} sx={{ background: index % 2 === 0 ? '#fff' : 'rgba(245, 221, 251, 0.21)' }}>
                                            <StyledTableCell component="th" scope="row">
                                                {index + 1}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">#{transaction.buyerId.id.slice(0, 6)}</StyledTableCell>
                                            <StyledTableCell align="left" sx={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
                                                <FlexDiv style={{ gap: '0.5rem' }}>
                                                    <Avatar sx={{ bgcolor: circleColors[index % circleColors.length], width: '1.8rem', height: '1.8rem', fontSize: '0.8rem' }}> {`${transaction.buyerId.firstName.charAt(0)}${transaction.buyerId.lastName.charAt(0)}`}</Avatar>
                                                    <Typography sx={{ fontFamily: 'Poppins', marginTop: '0.2rem' }}>{`${transaction.buyerId.firstName} ${transaction.buyerId.lastName}`}</Typography>
                                                </FlexDiv>
                                            </StyledTableCell>
                                            <StyledTableCell align="left" style={{ fontSize: '14px' }}>
                                                <FlexDiv style={{ gap: '0.5rem' }}>
                                                    <img src={transaction.productId ? transaction.productId.images[0] : ItemImg} alt="" style={{ width: '1.56rem', height: '1.56rem' }} />
                                                    <Typography sx={{ fontFamily: 'Poppins', marginTop: '0.05rem' }}>{transaction?.productId?.name ? transaction?.productId?.name : 'N/A'}</Typography>
                                                </FlexDiv>
                                            </StyledTableCell>
                                            <StyledTableCell align="left" style={{ fontSize: '14px' }}>{convertDateFormat(transaction.orderDate)}</StyledTableCell>
                                            <StyledTableCell align="left"><TransactionStatusDiv style={{ background: "#D6EEDD" }}> {transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}</TransactionStatusDiv></StyledTableCell>
                                            <StyledTableCell align="left" style={{ fontSize: '14px' }}>$ {transaction.amount / 100}</StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableOuterDiv>
                ) : (
                    <div style={{ margin: isLaptoptwo ? '0rem 1rem 1rem 0rem' : '0rem 1rem 1rem 1rem' }}>
                        <NoTransactions state_height='20rem' />
                    </div>
                )}
        </>
    )
}
export default Dashboard;
