import { FlexDiv } from "../../assets/styles/styles";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Grid, Typography, TextField, InputAdornment, IconButton, Container, Paper, Avatar, Badge } from "@mui/material";
import { Textarea } from "@mui/joy";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import HeaderCover from '../../assets/images/header_cover.png';
import ProfilePicture from "../../assets/images/header_profile.png";
import UsernameIcon from '../../assets/images/username_icon.svg'
import UserProfileSeller from "../../components/SellerDashboard/UserProfileSeller";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { updateProfile, passwordReset } from "../../Services/User";
import { ManageProfileText, SaveBtn, ProductText, placeholderStyles, HeaderCoverStyle, TokenExpired, DisplayPicDiv, EditDisplayDiv, DisplayPicImg, UsernameDiv, UsernameIconDiv, UsernameText } from "../../components/SellerDashboard/Settings/constants";
import { MANAGE_PROFILE, EDIT_DISPLAY, FIRST_NAME, LAST_NAME, PHONE, EMAIL } from "../../constants/text";
import { handleRefreshToken } from "../../Services/Authentication";
import { handleUpdateUser } from "../../Services/User";
import { Helmet } from "react-helmet";
import FooterDivider from "../../components/FooterDivider";
import Footer from "../../components/Footer";
import NavBar from "../../components/Navbar";
import { HEADER_DATA } from "../BuyerProducts/constants";
import { styled } from '@mui/system';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';

const SettingsContainer = styled('div')({
    background: '#FAFAFA',
    minHeight: '100vh',
});

const ProfileHeader = styled('div')({
    position: 'relative',
    height: '300px',
    background: 'linear-gradient(135deg, #9333EA 0%, #7C3AED 100%)',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: `radial-gradient(circle at 20% 150%, #A855F7 0%, transparent 50%),
                    radial-gradient(circle at 80% -50%, #8B5CF6 0%, transparent 50%)`,
        opacity: 0.6,
        pointerEvents: 'none',
    },
    '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `
            linear-gradient(45deg, rgba(255,255,255,0.1) 25%, transparent 25%),
            linear-gradient(-45deg, rgba(255,255,255,0.1) 25%, transparent 25%),
            linear-gradient(45deg, transparent 75%, rgba(255,255,255,0.1) 75%),
            linear-gradient(-45deg, transparent 75%, rgba(255,255,255,0.1) 75%)
        `,
        backgroundSize: '20px 20px',
        opacity: 0.1,
    }
});

const ProfileContent = styled('div')({
    maxWidth: '1200px',
    margin: '-120px auto 0',
    padding: '0 24px',
    position: 'relative',
    zIndex: 1,
});

const ProfileCard = styled(Paper)({
    padding: '40px',
    borderRadius: '24px',
    boxShadow: '0 4px 24px rgba(124, 58, 237, 0.06)',
    background: '#fff',
    marginBottom: '24px',
    border: '1px solid rgba(124, 58, 237, 0.1)',
});

const AvatarWrapper = styled('div')({
    position: 'relative',
    marginBottom: '32px',
    display: 'flex',
    alignItems: 'flex-end',
    gap: '24px',
});

const StyledAvatar = styled(Avatar)({
    width: 140,
    height: 140,
    border: '4px solid #fff',
    boxShadow: '0 8px 24px rgba(124, 58, 237, 0.15)',
    transition: 'transform 0.2s ease',
    '&:hover': {
        transform: 'scale(1.05)',
    }
});

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: '16px',
        backgroundColor: '#F9FAFB',
        transition: 'all 0.3s ease',
        '&:hover': {
            backgroundColor: '#F3F4F6',
            transform: 'translateY(-1px)',
            boxShadow: '0 4px 12px rgba(124, 58, 237, 0.05)',
        },
        '&.Mui-focused': {
            backgroundColor: '#fff',
            transform: 'translateY(-2px)',
            boxShadow: '0 8px 16px rgba(124, 58, 237, 0.1)',
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#7C3AED',
                borderWidth: '2px',
            },
        },
    },
    '& .MuiInputLabel-root': {
        color: '#6B7280',
        '&.Mui-focused': {
            color: '#7C3AED',
        },
    },
    '& .MuiInputAdornment-root': {
        '& .MuiSvgIcon-root': {
            color: '#7C3AED',
            transition: 'transform 0.2s ease',
        },
        '&:hover .MuiSvgIcon-root': {
            transform: 'scale(1.1)',
        },
    },
}));

const SaveButton = styled(Button)({
    marginTop: '32px',
    background: 'linear-gradient(135deg, #9333EA 0%, #7C3AED 100%)',
    borderRadius: '16px',
    padding: '12px 32px',
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 600,
    color: '#FFFFFF',
    boxShadow: '0 4px 14px rgba(124, 58, 237, 0.2)',
    transition: 'all 0.3s ease',
    '&:hover': {
        background: 'linear-gradient(135deg, #8B5CF6 0%, #7C3AED 100%)',
        boxShadow: '0 6px 20px rgba(124, 58, 237, 0.3)',
        transform: 'translateY(-2px)',
    },
    '&:disabled': {
        background: '#E5E7EB',
        color: '#9CA3AF',
        boxShadow: 'none',
    },
});

const BuyerSettings = () => {
    const isDesktop = useMediaQuery({ maxWidth: 1125 });
    const isSmallDesktop = useMediaQuery({ minWidth: 900 });
    const isLargeLaptop = useMediaQuery({ minWidth: 720 });
    const isLaptop = useMediaQuery({ minWidth: 620 });
    const isMobile = useMediaQuery({ maxWidth: 650 });
    const [coverPicture, setCoverPicture] = useState();
    const [dataSubmission, setDataSubmission] = useState(false);
    const [selectedField, setSelectedField] = useState(null);
    const [isExpired, setIsExpired] = useState(true);
    const [open, setOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [showPassword, setShowPassword] = useState(false)
    const [showNewPass, setShowNewPass] = useState(false)
    const [saveButtonClicked, setSaveButtonClicked] = useState(false);
    const navigate = useNavigate();
    const formData = new FormData();
    const storedUser = localStorage.getItem('Cornucopia-user');
    const user = JSON.parse(storedUser);
    const userId = user.response.id;
    const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const isGoogle = userData.response.isGoogle
    const initialValues = {
        firstName: userData.response.firstName,
        lastName: userData.response.lastName,
        phone: userData.response?.contact ? userData.response.contact : '',
        email: userData.response.email,
        currPass: '',
        newPass: '',
        displayPicture: userData?.response?.displayPicture ? userData?.response?.displayPicture : ProfilePicture,
        coverPicture: userData?.response?.coverPicture ? userData?.response?.coverPicture : HeaderCover
    };
    const [values, setValues] = useState(initialValues);
    const [selectedFile, setSelectedFile] = useState(null);
    const [profileImageError, setProfileImageError] = useState('');
    const [coverImageError, setCoverImageError] = useState('');
    const fileInputRef = useRef(null);
    const coverInputRef = useRef(null);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowNewPass = () => {
        setShowNewPass(!showNewPass)
    }
    const handleFieldChange = (fieldName, value) => {
        if (fieldName === 'phone') {
            const sanitizedValue = value.replace(/[^0-9+\-]/g, '');
            setValues({ ...values, [fieldName]: sanitizedValue });
        } else {
            setValues({ ...values, [fieldName]: value });
        }
    };

    const refreshtoken = async () => {
        try {
            const isTokenRefreshed = await handleRefreshToken();
            if (isTokenRefreshed) {
                setIsExpired(false);
            }
        } catch (error) {
            console.log('Error refreshing token:', error);
        }
    };

    const appendUserData = () => {
        if (values.firstName !== initialValues.firstName) {
            formData.append("firstName", values.firstName);
        }

        if (values.lastName !== initialValues.lastName) {
            formData.append("lastName", values.lastName);
        }

        if (values.phone !== initialValues.phone) {
            formData.append("contact", values.phone);
        }

        if (values.email !== initialValues.email) {
            formData.append("email", values.email);
        }

        if (values.bio !== initialValues.bio) {
            formData.append("bio", values.bio);
        }

        if (selectedFile) {
            formData.append('displayPicture', selectedFile);
        }
        if (coverPicture) {
            formData.append('coverPicture', coverPicture)
        }
    }

    const updateUser = async () => {
        try {
            await handleUpdateUser(userId)
        }
        catch (error) {
            console.log(error)
        }
    }
    const isPasswordValid = (password) => {
        // Validate password format using the specified pattern
        const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
        return passwordPattern.test(password);
    };

    const editProduct = async () => {
        const refreshExpiryToken = localStorage.getItem('refresh-token-expiry');
        const convertedTime = new Date(refreshExpiryToken);
        const currentTime = new Date();
        if (currentTime >= convertedTime) {
            localStorage.removeItem('access-token');
            localStorage.removeItem('refresh-token');
            localStorage.removeItem('access-token-expiry');
            localStorage.removeItem('refresh-token-expiry');
            localStorage.removeItem('Cornucopia-user');
            navigate('/login');
            return;
        }
        setDataSubmission(true);
        appendUserData();
        const accessExpiryToken = localStorage.getItem('access-token-expiry');
        const isTokenExpired = TokenExpired(accessExpiryToken);
        if (isTokenExpired) {
            await refreshtoken();
        }
        let passMatch = false;
        let passCorrect = true;
        setSaveButtonClicked(true);
        if (values.currPass !== '' || values.newPass !== '') {
            if (values.currPass !== '' && values.newPass !== '') {
                const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
                if (!passwordPattern.test(values.currPass) || !passwordPattern.test(values.newPass)) {
                    setDataSubmission(false);
                    return;
                }
                try {
                    const response = await passwordReset(userData.response.email, values.currPass, values.newPass);
                } catch (error) {
                    console.error("Error updating profile", error);
                    setSnackbarMsg(error?.response?.data?.message);
                    setSnackbarSeverity('error');
                    setDataSubmission(false);
                    setOpen(true);
                    passCorrect = false;
                    return;
                }
            } else if (values.currPass !== '') {
                setSnackbarMsg('Enter the new password');
                setSnackbarSeverity('error');
                setOpen(true);
                setDataSubmission(false);
                passCorrect = false;
                return;
            } else {
                setSnackbarMsg('Enter the current password');
                setSnackbarSeverity('error');
                setOpen(true);
                setDataSubmission(false);
                passCorrect = false;
                return;
            }
        }
        if (passCorrect) {
            const response = await updateProfile(userId, formData)
            if (response) {
                try {
                    setDataSubmission(false);
                    setSnackbarMsg("Profile Updated Successfully");
                    setSnackbarSeverity("success");
                    setOpen(true);
                    updateUser();
                }
                catch (error) {
                    console.error("Error updating profile", error);
                    setDataSubmission(false);
                    console.error('Login error:', error);
                    if (error?.response?.message) {
                        setSnackbarMsg(error.response.message);
                        setSnackbarSeverity('error');
                    } else if (error?.message) {
                        setSnackbarMsg(error.message);
                        setSnackbarSeverity('error');
                    } else {
                        setSnackbarMsg('Error');
                        setSnackbarSeverity('error');
                    }
                    setOpen(true);
                };
            }
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setSnackbarMsg('');
    };

    const handleProfileImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Validate file type and size
            const validTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            const maxSize = 5 * 1024 * 1024; // 5MB

            if (!validTypes.includes(file.type)) {
                setProfileImageError('Please upload a JPG, JPEG or PNG file');
                setSnackbarMsg('Invalid file type. Please upload a JPG, JPEG or PNG file');
                setSnackbarSeverity('error');
                setOpen(true);
                return;
            }

            if (file.size > maxSize) {
                setProfileImageError('File size should be less than 5MB');
                setSnackbarMsg('File size should be less than 5MB');
                setSnackbarSeverity('error');
                setOpen(true);
                return;
            }

            setSelectedFile(file);
            setProfileImageError('');
        }
    };

    const handleCoverImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Validate file type and size
            const validTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            const maxSize = 5 * 1024 * 1024; // 5MB

            if (!validTypes.includes(file.type)) {
                setCoverImageError('Please upload a JPG, JPEG or PNG file');
                setSnackbarMsg('Invalid file type. Please upload a JPG, JPEG or PNG file');
                setSnackbarSeverity('error');
                setOpen(true);
                return;
            }

            if (file.size > maxSize) {
                setCoverImageError('File size should be less than 5MB');
                setSnackbarMsg('File size should be less than 5MB');
                setSnackbarSeverity('error');
                setOpen(true);
                return;
            }

            setCoverPicture(file);
            setCoverImageError('');
        }
    };

    const handleButtonClick = () => {
        const fileInput = document.getElementById('fileInput');
        fileInput.click();
    };

    const handleCoverButtonClick = () => {
        const fileInput = document.getElementById('fileInputCover');
        fileInput.click();
    };

    return (
        <SettingsContainer>
            <Helmet>
                <title>Cornucopia AI | Settings</title>
            </Helmet>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMsg}
                </MuiAlert>
            </Snackbar>
            <NavBar header_data={HEADER_DATA} header_button={{ label: 'Join the venture', navigation_url: '/buyerseller' }} mobile_nav_height={'260px'} authenticated={true} />
            <ProfileHeader
                style={{
                    backgroundImage: coverPicture 
                        ? `url(${URL.createObjectURL(coverPicture)})` 
                        : values.coverPicture !== HeaderCover 
                            ? `url(${values.coverPicture})`
                            : 'none'
                }}
            >
                <IconButton
                    sx={{
                        position: 'absolute',
                        right: '24px',
                        top: '24px',
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        backdropFilter: 'blur(10px)',
                        zIndex: 2,
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.3)',
                            transform: 'scale(1.05)',
                        },
                        transition: 'all 0.2s ease',
                    }}
                    onClick={() => coverInputRef.current.click()}
                >
                    <CameraAltIcon sx={{ color: '#fff' }} />
                </IconButton>
                <input
                    type="file"
                    ref={coverInputRef}
                    accept="image/png, image/jpeg, image/jpg"
                    style={{ display: 'none' }}
                    onChange={handleCoverImageUpload}
                />
                {coverImageError && (
                    <Typography 
                        color="error" 
                        variant="caption" 
                        sx={{ 
                            position: 'absolute',
                            right: '24px',
                            top: '64px',
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            padding: '4px 8px',
                            borderRadius: '4px',
                            zIndex: 2
                        }}
                    >
                        {coverImageError}
                    </Typography>
                )}
            </ProfileHeader>
            <ProfileContent>
                <ProfileCard>
                    <AvatarWrapper>
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                                <IconButton
                                    sx={{
                                        backgroundColor: '#7C3AED',
                                        '&:hover': { 
                                            backgroundColor: '#6D28D9',
                                            transform: 'scale(1.1)'
                                        },
                                        transition: 'all 0.2s ease',
                                    }}
                                    size="small"
                                    onClick={() => fileInputRef.current.click()}
                                >
                                    <CameraAltIcon sx={{ color: '#fff', fontSize: '1.2rem' }} />
                                </IconButton>
                            }
                        >
                            <StyledAvatar
                                src={selectedFile ? URL.createObjectURL(selectedFile) : values.displayPicture}
                                alt="Profile Picture"
                            />
                        </Badge>
                        <input
                            type="file"
                            ref={fileInputRef}
                            accept="image/png, image/jpeg, image/jpg"
                            style={{ display: 'none' }}
                            onChange={handleProfileImageUpload}
                        />
                        {profileImageError && (
                            <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                                {profileImageError}
                            </Typography>
                        )}
                    </AvatarWrapper>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <StyledTextField
                                fullWidth
                                label="First Name"
                                value={values.firstName}
                                onChange={(e) => handleFieldChange('firstName', e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon sx={{ color: '#9CA3AF' }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <StyledTextField
                                fullWidth
                                label="Last Name"
                                value={values.lastName}
                                onChange={(e) => handleFieldChange('lastName', e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon sx={{ color: '#9CA3AF' }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <StyledTextField
                                fullWidth
                                label="Phone"
                                value={values.phone}
                                onChange={(e) => handleFieldChange('phone', e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon sx={{ color: '#9CA3AF' }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <StyledTextField
                                fullWidth
                                label="Email"
                                value={values.email}
                                onChange={(e) => handleFieldChange('email', e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon sx={{ color: '#9CA3AF' }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledTextField
                                fullWidth
                                label="Current Password"
                                type={showPassword ? 'text' : 'password'}
                                value={values.currPass}
                                onChange={(e) => handleFieldChange('currPass', e.target.value)}
                                onFocus={() => setSelectedField('currPass')}
                                onBlur={() => setSelectedField(null)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon sx={{ color: '#9CA3AF' }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledTextField
                                fullWidth
                                label="New Password"
                                type={showNewPass ? 'text' : 'password'}
                                value={values.newPass}
                                onChange={(e) => handleFieldChange('newPass', e.target.value)}
                                onFocus={() => setSelectedField('newPass')}
                                onBlur={() => setSelectedField(null)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon sx={{ color: '#9CA3AF' }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SaveButton
                                fullWidth
                                onClick={editProduct}
                                disabled={dataSubmission}
                            >
                                {dataSubmission ? (
                                    <CircularProgress size={24} sx={{ color: '#FFFFFF' }} />
                                ) : (
                                    <Typography sx={{ color: '#FFFFFF', fontSize: '1rem' }}>
                                        Save Changes
                                    </Typography>
                                )}
                            </SaveButton>
                        </Grid>
                    </Grid>
                </ProfileCard>
            </ProfileContent>
            <FooterDivider />
            <Footer />
        </SettingsContainer>
    )
}
export default BuyerSettings;