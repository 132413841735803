import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Container,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  Switch,
  styled,
  Stack,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  useMediaQuery,
  useTheme,
  Chip,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NavBar from "../../components/Navbar";
import { HEADER_DATA } from "../LandingPage/constants";
import FooterDivider from "../../components/FooterDivider";
import Footer from "../../components/Footer";
import { IP_ADD } from "../../constants/api";
import { useNavigate } from "react-router-dom";

// Custom styled Switch for toggle
const PurpleSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#A020F0",
    "&:hover": {
      backgroundColor: "rgba(160, 32, 240, 0.08)",
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#A020F0",
  },
}));

// Custom styled Card for pricing tiers
const PricingCard = styled(Card)(({ theme, featured, isActive }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  borderRadius: "16px",
  boxShadow: featured
    ? "0 8px 24px rgba(160, 32, 240, 0.15)"
    : isActive
      ? "0 8px 24px rgba(76, 175, 80, 0.15)"
      : "0 2px 12px rgba(0, 0, 0, 0.08)",
  transition: "all 0.3s ease",
  border: featured ? "2px solid #A020F0" : isActive ? "2px solid #4CAF50" : "1px solid #e0e0e0",
  position: "relative",
  overflow: "visible",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 12px 32px rgba(0, 0, 0, 0.12)",
  },
}));

// Featured badge component
const FeaturedBadge = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "-12px",
  right: "24px",
  backgroundColor: "#A020F0",
  color: "white",
  padding: "4px 12px",
  borderRadius: "20px",
  fontSize: "0.75rem",
  fontWeight: "bold",
  boxShadow: "0 4px 8px rgba(160, 32, 240, 0.25)",
}));

// Active badge component
const ActiveBadge = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "-12px",
  right: "24px",
  backgroundColor: "#4CAF50",
  color: "white",
  padding: "4px 12px",
  borderRadius: "20px",
  fontSize: "0.75rem",
  fontWeight: "bold",
  boxShadow: "0 4px 8px rgba(76, 175, 80, 0.25)",
}));

const PricingPage = () => {
  const [isYearly, setIsYearly] = useState(false);
  const [userType, setUserType] = useState("buyers"); // 'buyers' or 'sellers'
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [subscriptions, setSubscriptions] = useState([]);
  const [mySubs, setMySubs] = useState([]);
  const userCorn = JSON.parse(localStorage.getItem("Cornucopia-user"));
  const navigate = useNavigate();

  const getDiscountPrice = (price) => {
    return (price / 2).toFixed(2);
  };

  let user = JSON.parse(localStorage.getItem("Cornucopia-user"))?.response?.id;
  let userEmail = JSON.parse(localStorage.getItem("Cornucopia-user"))?.response?.email;
  const buyerPlans = subscriptions?.filter((plan) => plan.metadata?.role === "buyer");
  const sellerPlans = subscriptions?.filter((plan) => plan.metadata?.role === "seller");
  const activePlans = userType === "sellers" ? sellerPlans : buyerPlans;

  // Function to check if a plan is active based on user's subscriptions
  const isActivePlan = (plan) => {
    if (!mySubs || mySubs.length === 0) return false;

    // Find active subscription matching the current role and the correct subscription type (buyer/seller)
    const activeSubscription = mySubs.find((sub) => sub.status === "active" && sub.role === plan.metadata?.role);

    if (!activeSubscription) return false;

    // Match by name or subscriptionId
    return plan.id === activeSubscription.productId || plan.name === activeSubscription.name;
  };

  const fetchAvailableSubscriptions = async () => {
    try {
      const response = await axios.get(`${IP_ADD}/api/payment/get-available-subscriptions`);
      if (response.data.responseCode === 2000) {
        setSubscriptions(response.data.response);
      } /* else {
        setError(response.data.message);
      } */
    } catch (err) {
      console.error("Error fetching subscriptions:", err);
      //   setError("Failed to fetch subscriptions.");
    } finally {
      //   setLoading(false);
    }
  };

  useEffect(() => {
    fetchAvailableSubscriptions();
  }, []);
  useEffect(() => {
    if (userCorn?.response?.role) {
      setUserType(userCorn.response.role === "seller" ? "sellers" : "buyers");
    }
  }, [userCorn]);

  const fetchMySubscription = async (user) => {
    try {
      const response = await axios.get(`${IP_ADD}/api/payment/get-subscriptions`, {
        params: { userId: user },
      });
      if (response.data.responseCode === 2000) {
        setMySubs(response.data.response);
      } /* else {
        setError(response.data.message);
      } */
    } catch (err) {
      console.error("Error fetching subscriptions:", err);
      //   setError("Failed to fetch subscriptions.");
    } finally {
      //   setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchMySubscription(user);
    }
  }, [user]);

  // Effect to set initial user type based on active subscription
  useEffect(() => {
    if (mySubs && mySubs.length > 0) {
      const activeSub = mySubs.find((sub) => sub.status === "active");
      if (activeSub) {
        setUserType(activeSub.role === "seller" ? "sellers" : "buyers");
      }
    }
  }, [mySubs]);

  async function createSubscription(priceId, userEmail) {
    try {
      const response = await axios.post(`${IP_ADD}/api/payment/create-checkout-session`, {
        priceId,
        userEmail,
      });

      if (response.data.sessionId) {
        window.location.href = response.data.url;
      } else {
        console.error("Failed to create Stripe session", response.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const handleSubscribeClick = () => {
    // Check if user is logged in
    if (!userCorn) {
      // If not logged in, redirect to login page
      navigate('/login');
      return;
    }

    // If logged in, proceed with existing subscription logic
    if (userEmail) {
      createSubscription(plan.prices[0].priceId, userEmail);
    }
  };

  return (
    <Box>
      <NavBar
        header_data={HEADER_DATA}
        header_button={{ label: "Join the venture", navigation_url: "/buyerseller" }}
        mobile_nav_height={"16.5rem"}
      />
      <Box sx={{ bgcolor: "#ffffff", py: 10, px: 2 }} style={{ marginBottom: "-200px" }}>
        <Container maxWidth="lg">
          {/* Header Section */}
          <Box sx={{ textAlign: "center", mb: 8 }}>
            <Typography
              variant="h2"
              component="h1"
              sx={{
                fontWeight: "bold",
                mb: 3,
                fontSize: { xs: "2rem", md: "3rem" },
                background: "linear-gradient(90deg, #A020F0 0%, #8A2BE2 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Choose Your Plan
            </Typography>
            <Typography variant="h5" color="text.secondary" sx={{ maxWidth: "700px", mx: "auto", mb: 5 }}>
              Find the perfect Cornucopia AI subscription for your business needs
            </Typography>

            {/* Toggle sections */}
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 3 }}>
              {/* Buyer/Seller Toggle */}
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{
                  p: 1,
                  borderRadius: 3,
                  bgcolor: "#f5f5f5",
                  boxShadow: "inset 0 1px 3px rgba(0,0,0,0.1)",
                }}
              >
                <Button
                  variant={userType === "buyers" ? "contained" : "text"}
                  onClick={() => setUserType("buyers")}
                  disabled={userCorn?.response?.role === "seller"}
                  sx={{
                    bgcolor: userType === "buyers" ? "#A020F0" : "transparent",
                    color: userType === "buyers" ? "white" : "text.primary",
                    boxShadow: userType === "buyers" ? "0 4px 12px rgba(160, 32, 240, 0.2)" : "none",
                    px: 3,
                    py: 1,
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: userType === "buyers" ? "#8A2BE2" : "rgba(0,0,0,0.05)",
                    },
                  }}
                >
                  For Buyers
                </Button>
                <Button
                  variant={userType === "sellers" ? "contained" : "text"}
                  onClick={() => setUserType("sellers")}
                  disabled={userCorn?.response?.role === "buyer"}
                  sx={{
                    bgcolor: userType === "sellers" ? "#A020F0" : "transparent",
                    color: userType === "sellers" ? "white" : "text.primary",
                    boxShadow: userType === "sellers" ? "0 4px 12px rgba(160, 32, 240, 0.2)" : "none",
                    px: 3,
                    py: 1,
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: userType === "sellers" ? "#8A2BE2" : "rgba(0,0,0,0.05)",
                    },
                  }}
                >
                  For AI Tool Developers
                </Button>
              </Stack>
            </Box>
          </Box>

          {/* Pricing Cards Section */}
          <Grid container spacing={4} justifyContent="center">
            {activePlans
              .slice() // Create a shallow copy to avoid mutating the original array
              .sort((a, b) => a.prices[0]?.amount - b.prices[0]?.amount) // Sort by price (small to large)
              .map((plan, index) => {
                const active = isActivePlan(plan);
                return (
                  <Grid item key={plan.id} xs={12} sm={12} md={4}>
                    <PricingCard
                      featured={plan.metadata?.["most-popular"] === "yes" && !active}
                      isActive={active}
                      elevation={active ? 10 : plan.metadata?.["most-popular"] === "yes" ? 8 : 2}
                    >
                      {plan.metadata?.["most-popular"] === "yes" && !active && <FeaturedBadge>POPULAR</FeaturedBadge>}
                      {active && <ActiveBadge>CURRENT PLAN</ActiveBadge>}
                      <CardContent sx={{ p: 4, flexGrow: 1 }}>
                        {/* Plan Icon & Title */}
                        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                          {plan?.images.length > 0 ? (
                            <img src={plan.images[0]} alt="" style={{ width: "37px" }} />
                          ) : null}

                          <Typography
                            variant="h5"
                            component="div"
                            sx={{
                              ml: 1,
                              fontWeight: "bold",
                              color: active ? "#4CAF50" : "inherit",
                            }}
                          >
                            {plan.name}
                          </Typography>
                        </Box>

                        {/* Pricing */}
                        <Box sx={{ my: 3, display: "flex", alignItems: "baseline" }}>
                          <Typography
                            variant="h3"
                            component="span"
                            fontWeight="bold"
                            color={
                              active
                                ? "#4CAF50"
                                : plan.metadata?.["most-popular"] === "yes"
                                  ? "primary.main"
                                  : "text.primary"
                            }
                          >
                            ${isYearly ? getDiscountPrice(plan.prices[0]?.amount) : plan.prices[0]?.amount.toFixed(2)}
                          </Typography>
                          <Typography variant="subtitle1" color="text.secondary" sx={{ ml: 1 }}>
                            {plan.prices[0]?.amount > 0
                              ? `/${plan.prices[0]?.interval}${isYearly ? " (billed annually)" : ""}`
                              : ""}
                          </Typography>
                        </Box>

                        {/* First 3 Months Promo (for paid plans) */}
                        {plan.prices[0]?.amount > 0 && (
                          <Box
                            sx={{
                              mb: 3,
                              p: 1,
                              bgcolor: "#FFF9C4",
                              borderRadius: 1,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <TipsAndUpdatesIcon sx={{ color: "#FFB300", mr: 1, fontSize: "1.2rem" }} />
                            <Typography variant="body2" fontWeight="medium" color="text.secondary">
                              First 3 Months: ${(plan.prices[0]?.amount / 2).toFixed(2)}/{plan.prices[0]?.interval}
                            </Typography>
                          </Box>
                        )}

                        {/* Description */}
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                          {plan.description}
                        </Typography>

                        <Divider sx={{ my: 3 }} />

                        {/* Features */}
                        <List disablePadding>
                          {plan.features.map((feature, idx) => (
                            <ListItem key={idx} disablePadding disableGutters sx={{ py: 1 }}>
                              <ListItemIcon sx={{ minWidth: 32 }}>
                                <CheckCircleOutlineIcon color={active ? "success" : "primary"} fontSize="small" />
                              </ListItemIcon>
                              <ListItemText primary={feature.name} primaryTypographyProps={{ variant: "body2" }} />
                            </ListItem>
                          ))}
                        </List>
                      </CardContent>
                      <CardActions sx={{ p: 4, pt: 0 }}>
                        <Button
                          variant={
                            active ? "contained" : plan.metadata?.["most-popular"] === "yes" ? "contained" : "outlined"
                          }
                          fullWidth
                          size="large"
                          disabled={active}
                          startIcon={active ? <CheckCircleIcon /> : null}
                          onClick={handleSubscribeClick}
                          sx={{
                            bgcolor: active
                              ? "#4CAF50"
                              : plan.metadata?.["most-popular"] === "yes"
                                ? "#A020F0"
                                : "transparent",
                            color: active ? "white" : plan.metadata?.["most-popular"] === "yes" ? "white" : "#A020F0",
                            border: active
                              ? "none"
                              : plan.metadata?.["most-popular"] === "yes"
                                ? "none"
                                : "1px solid #A020F0",
                            py: 1.5,
                            borderRadius: "30px",
                            fontWeight: "bold",
                            textTransform: "none",
                            fontSize: "1rem",
                            "&:hover": {
                              bgcolor: active
                                ? "#4CAF50"
                                : plan.metadata?.["most-popular"] === "yes"
                                  ? "#8A2BE2"
                                  : "rgba(160, 32, 240, 0.08)",
                              boxShadow:
                                active || plan.metadata?.["most-popular"] === "yes"
                                  ? "0 8px 16px rgba(160, 32, 240, 0.2)"
                                  : "none",
                            },
                            "&.Mui-disabled": {
                              bgcolor: "#4CAF50",
                              color: "white",
                              opacity: 0.9,
                            },
                          }}
                        >
                          {active ? "CURRENT PLAN" : "SUBSCRIBE NOW"}
                        </Button>
                      </CardActions>
                    </PricingCard>
                  </Grid>
                );
              })}
          </Grid>

          {/* Enterprise/Custom Section */}
          <Box
            sx={{
              mt: 8,
              p: 4,
              borderRadius: 4,
              bgcolor: "#f8f9fa",
              textAlign: "center",
              border: "1px solid #e0e0e0",
            }}
          >
            <Typography variant="h5" fontWeight="bold" mb={2}>
              Need a custom solution?
            </Typography>
            <Typography variant="body1" color="text.secondary" mb={3} sx={{ maxWidth: "700px", mx: "auto" }}>
              {userType === "sellers"
                ? "Have more than 5 AI tools or need a custom enterprise solution? We'll tailor a package specifically for your needs."
                : "Looking for a comprehensive enterprise AI adoption strategy for your entire organization? Let's build a custom plan."}
            </Typography>
            <Button
              variant="outlined"
              size="large"
              sx={{
                color: "#A020F0",
                borderColor: "#A020F0",
                px: 4,
                py: 1.5,
                borderRadius: "30px",
                fontWeight: "bold",
                textTransform: "none",
                fontSize: "1rem",
                "&:hover": {
                  borderColor: "#8A2BE2",
                  bgcolor: "rgba(160, 32, 240, 0.05)",
                },
              }}
            >
              Contact Sales
            </Button>
          </Box>
        </Container>
      </Box>
      <FooterDivider />
      <Footer />
    </Box>
  );
};

export default PricingPage;
