import { SecondaryDiv, FooterSecondaryText, FlexDiv, CornucopiaIcon, FooterLongText, FooterTextOne, FooterTextTwo, FooterTextBtn } from "../assets/styles/styles";
import XIcon from '../assets/images/XLogo.svg';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import cornucopia_landing_icon from "../assets/images/logo.jpeg";
import { CONTACT_US, QUICK_LINKS, COPYRIGHTS } from "../constants/text";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { Container, Box } from "@mui/material";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

const QUICK_LINKS_DATA = [
    {
        id: '1',
        Name: 'Home',
        margin: '1rem',
        navigation_url: '/'
    },
    {
        id: '2',
        Name: 'Why Cornucopia',
        margin: '1rem',
        navigation_url: '/why-cornucopia'
    },
    {
        id: '3',
        Name: 'Marketplace',
        margin: '1rem',
        navigation_url: '/products'
    },
    {
        id: '4',
        Name: 'Custom AI for SMBs',
        margin: '1rem',
        navigation_url: '/custom-ai'
    },
    {
        id: '5',
        Name: 'Schedule a Call',
        margin: '1rem',
        navigation_url: 'https://calendly.com/tatmanman12/30min',
        isExternal: true
    },
    {
        id: '6',
        Name: 'Tutorial: Post Custom AI Tools',
        margin: '1rem',
        navigation_url: 'https://www.loom.com/share/89daff637b604adfb86f0683ab985476?sid=42454e81-c6cf-4f4f-947a-0e7951dc70be',
        isExternal: true,
        icon: 'video'
    },
    {
        id: '7',
        Name: 'Tutorial: Post Web-Based Tools',
        margin: '1rem',
        navigation_url: 'https://www.loom.com/share/3982ac8296b04ba6a4f7d29d601c05a9?sid=7d47bfda-7f33-47d4-9446-d93efcec1ca9',
        isExternal: true,
        icon: 'video'
    },
    {
        id: '8',
        Name: 'Pricing',
        margin: '1rem',
        navigation_url: '/pricing'
    }
];

const CONTACT_US_DATA = [
    {
        id: '1',
        Name: 'Kenilworth Ave, Charlotte, North Carolina, 28203',
        margin: '1rem',
        width: '15rem'
    },
    {
        id: '2',
        Name: 'services@cornucopia-ai.com',
        margin: '1rem',
        width: '',
        email: 'mailto:services@cornucopia-ai.com'
    },
];

const COMPANY_INFO = {
    description: 'Level the playing field – we bring cutting-edge AI to the heart of small and medium businesses. Our marketplace makes AI accessible and easy to implement.',
    social: [
        {
            icon: LinkedInIcon,
            url: 'https://www.linkedin.com/company/cornucopia-ai/'
        },
        {
            icon: InstagramIcon,
            url: 'https://www.instagram.com/cornucopia_ai/'
        }
    ]
};

const Footer = () => {
    const isDesktop = useMediaQuery({ minWidth: 1200 });
    const isLaptop = useMediaQuery({ minWidth: 500, maxWidth: 750 });
    const isMobile = useMediaQuery({ maxWidth: 500 });
    const navigate = useNavigate();

    const handleNavigation = (navigation_url, isExternal) => {
        if (isExternal) {
            window.open(navigation_url, '_blank');
        } else {
            navigate(navigation_url);
        }
    };

    const handleSocialClick = (url) => {
        window.open(url, '_blank');
    };

    return (
        <>
            <Container maxWidth={false} sx={{ maxWidth: '1800px', bgcolor: '#FFFFFF' }} disableGutters>
                <footer style={{
                    zIndex: "50",
                    position: "relative",
                    backgroundColor: "#FFFFFF",
                    padding: isMobile ? "2rem 1rem" : "4rem 2rem",
                    borderTop: "1px solid #EAEAEA"
                }}>
                    <FlexDiv style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        flexDirection: isMobile || isLaptop ? 'column' : 'row',
                        gap: isMobile || isLaptop ? '2rem' : '0'
                    }}>
                        {/* Company Info Section */}
                        <div style={{ maxWidth: isDesktop ? "30%" : "100%" }}>
                            <CornucopiaIcon
                                src={cornucopia_landing_icon}
                                alt="Cornucopia AI"
                                style={{
                                    cursor: "pointer",
                                    height: "50px",
                                    marginBottom: "1.5rem"
                                }}
                            />
                            <FooterLongText style={{
                                marginBottom: '2rem',
                                color: '#666'
                            }}>
                                {COMPANY_INFO.description}
                            </FooterLongText>
                            <FlexDiv style={{ gap: '1rem' }}>
                                {COMPANY_INFO.social.map((social, index) => (
                                    <button
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            background: '#FFFFFF',
                                            border: '1px solid #EAEAEA',
                                            borderRadius: '50%',
                                            padding: '0.5rem',
                                            cursor: 'pointer',
                                            transition: 'background 0.2s'
                                        }}
                                        onClick={() => handleSocialClick(social.url)}
                                    >
                                        {social.isImage ? (
                                            <img src={social.icon} alt="" style={{ width: '20px', height: '20px' }} />
                                        ) : (
                                            <social.icon style={{ color: '#666' }} />
                                        )}
                                    </button>
                                ))}
                            </FlexDiv>
                        </div>

                        {/* Quick Links & Contact Section */}
                        <FlexDiv style={{
                            gap: isMobile ? "2rem" : "4rem",
                            flexDirection: isMobile ? 'column' : 'row',
                            alignItems: isMobile ? 'flex-start' : 'flex-start'
                        }}>
                            <div>
                                <FooterTextOne style={{
                                    marginBottom: '1.5rem',
                                    fontWeight: 'bold',
                                    color: '#333'
                                }}>{QUICK_LINKS}</FooterTextOne>
                                {QUICK_LINKS_DATA.map((data) => (
                                    <FooterTextBtn
                                        key={data.id}
                                        onClick={() => handleNavigation(data.navigation_url, data.isExternal)}
                                    >
                                        <FooterTextTwo style={{
                                            marginBottom: data.margin,
                                            color: '#666',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                            transition: 'color 0.2s'
                                        }}>
                                            {data.Name === 'Schedule a Call' && <CalendarMonthIcon style={{ fontSize: '1rem' }} />}
                                            {data.icon === 'video' && <PlayCircleOutlineIcon style={{ fontSize: '1rem' }} />}
                                            {data.Name}
                                        </FooterTextTwo>
                                    </FooterTextBtn>
                                ))}
                            </div>
                            <div>
                                <FooterTextOne style={{
                                    marginBottom: '1.5rem',
                                    fontWeight: 'bold',
                                    color: '#333'
                                }}>{CONTACT_US}</FooterTextOne>
                                {CONTACT_US_DATA.map((data) => (
                                    <FooterTextOne
                                        key={data.id}
                                        style={{
                                            marginBottom: data.margin,
                                            width: data.width,
                                            color: '#666'
                                        }}
                                    >
                                        {data.email ? (
                                            <a
                                                style={{
                                                    color: '#666',
                                                    textDecoration: 'none',
                                                    transition: 'color 0.2s'
                                                }}
                                                href={data.email}
                                                onMouseOver={(e) => e.target.style.color = '#7C3AED'}
                                                onMouseOut={(e) => e.target.style.color = '#666'}
                                            >
                                                {data.Name}
                                            </a>
                                        ) : (
                                            data.Name
                                        )}
                                    </FooterTextOne>
                                ))}
                            </div>
                        </FlexDiv>
                    </FlexDiv>
                </footer>
            </Container>

            {/* Updated Copyright Section - Moved outside Container */}
            <Box
                sx={{
                    width: '100%',
                    bgcolor: '#5c187e',
                    mt: 0,
                    py: 2,
                    bottom: 0,
                }}
            >
                <FooterSecondaryText
                    style={{
                        color: '#FFFFFF',
                        fontSize: '0.875rem',
                        textAlign: 'center',
                        opacity: 0.9
                    }}
                >
                    {COPYRIGHTS}
                </FooterSecondaryText>
            </Box>
        </>
    );
};

export default Footer;
