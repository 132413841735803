import React from 'react';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const ModalContainer = styled('div')({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '600px',
    backgroundColor: 'white',
    borderRadius: '16px',
    padding: '2rem',
    maxHeight: '90vh',
    overflowY: 'auto'
});

const PlanContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    marginTop: '1.5rem'
});

const PlanCard = styled('div')({
    background: '#F7F3FC',
    padding: '1.5rem',
    borderRadius: '10px',
    border: '1px solid #6D0085',
    width: '100%',
});

const SubscriptionPlansModal = ({ open, onClose, subscriptionPlans }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="subscription-plans-modal"
        >
            <ModalContainer>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h5" style={{ color: '#6D0085', fontWeight: 600 }}>
                        Subscription Plans
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <PlanContainer>
                    {subscriptionPlans?.map((plan, index) => (
                        <PlanCard key={index}>
                            <Typography variant="h6" style={{ color: '#6D0085', fontWeight: 600 }}>
                                {plan.tier}
                            </Typography>
                            <Typography variant="h5" style={{ color: '#6D0085', margin: '0.5rem 0' }}>
                                ${plan.price}/{plan.billingCycle}
                            </Typography>
                            <div style={{ marginTop: '1rem' }}>
                                {formatSubscriptionFeatures(plan.description).map((feature, idx) => (
                                    <div key={idx} style={{ display: 'flex', gap: '0.5rem', marginTop: '0.5rem' }}>
                                        <DoneIcon style={{ color: '#6D0085' }} />
                                        <Typography>{feature}</Typography>
                                    </div>
                                ))}
                            </div>
                        </PlanCard>
                    ))}
                </PlanContainer>
            </ModalContainer>
        </Modal>
    );
};

// Helper function to format subscription features (move this from BasePriceCard)
const formatSubscriptionFeatures = (description) => {
    if (!description) return [];
    return description
        .split('-')
        .map(feature => feature.trim())
        .filter(feature => feature)
        .map(feature => {
            return feature
                .replace('a month', '/mo')
                .replace('images a month', 'images/mo')
                .replace('words a month', 'words/mo')
                .trim();
        })
        .filter(feature => feature.length > 0)
        .slice(0, 4);
};

export default SubscriptionPlansModal;
