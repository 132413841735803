import React, { useState } from 'react';
import Box from '@mui/material/Box';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import Switch from '@mui/material/Switch';
import { useMediaQuery } from '@mui/material';
import { CLICK_TO_UPLOAD, DRAG_AND_DROP, UPLOADS } from '../../constants/text';
import axios from 'axios';
import { IP_ADD } from '../../constants/api';
import MiddleEllipsisText from '../Utils/MiddleEllipsisText';

const containerStyle = {
    display: 'flex',
    alignItems: 'center',
};

const boxStyle = {
    width: '9.4375rem',
    height: '7.6875rem',
    borderRadius: '0.4375rem',
    border: '1px dashed #9D40B1',
    background: '#FAFAFA',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'relative',
    marginRight: '0.7rem',
};

const iconStyle = {
    width: '1.3125rem',
    height: '1.3125rem',
    color: '#690080',
};

const textStyles = {
    color: '#9D40B1',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginTop: '0.5rem',
};

const uploadedImageStyle = {
    width: '9.0625rem',
    height: '7.6875rem',
    borderRadius: '0.4375rem',
    marginLeft: '0.7rem', // Margin between images
};

const uploadsTextStyle = {
    color: '#888',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginTop: '1rem',
    marginBottom: '0.81rem',
};

const fileInfoContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.81rem', // Margin below each file info
};

const fileInfoBoxStyle = {
    width: '3.1875rem',
    height: '2.5rem',
    borderRadius: '0.375rem',
    background: '#D9D9D9',
    marginRight: '0.81rem', // Margin between box and file name
};

const fileNameStyle = {
    color: '#5B5B5B',
    fontFamily: 'Poppins',
    fontSize: '0.8125rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
};

const progressBarStyle = {
    height: '0.2rem',
    backgroundColor: '#CECECE',
    borderRadius: '0.25rem',
    width: '20.375rem',
    position: 'relative',
};

const PhotoUploadBox = ({ uploadedImages, setUploadedImages, editing = false, productID = null }) => {
    const [progress, setProgress] = useState({});
    const [uploading, setUploading] = useState(false);
    const isMobile = useMediaQuery('(max-width:500px)');
    const [uploadedImagesCount, setUploadedImagesCount] = useState(uploadedImages.length);

    const handleFileChange = (event) => {
        if (uploadedImagesCount >= 4) {
            return;
        }
        const file = event.target.files[0];

        if (file) {
            setUploading(true);
            simulateUploadProgress(file, (percentUploaded) => {
                setProgress((prevProgress) => ({
                    ...prevProgress,
                    [file.name]: percentUploaded,
                }));

                setUploading(false);

                setUploadedImages([...uploadedImages, file]);
                setUploadedImagesCount(uploadedImagesCount + 1);
                document.getElementById('file-input').value = '';
            });
        }
    };
    const simulateUploadProgress = (file, progressCallback) => {
        const totalSize = file.size;
        let uploadedSize = 0;
        const interval = setInterval(() => {
            uploadedSize += Math.random() * 100000; // Simulating progress steps
            const percentUploaded = (uploadedSize / totalSize) * 100;
            progressCallback(percentUploaded);

            if (percentUploaded >= 100) {
                clearInterval(interval);
            }
        }, 10);
    };


    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        setUploadedImages([...uploadedImages, file]);
    };

    const removeImage = async (index) => {
        try {
            const response = await axios.delete(`${IP_ADD}/api/products/${productID}/${index}`);
            if (response.status === 200) {
                const newUploadedImages = uploadedImages.filter((_, i) => i !== index);
                setUploadedImages(newUploadedImages);
                setUploadedImagesCount(uploadedImagesCount - 1);
            }
        } catch (error) {
            console.error('Error updating product:', error);
        }
    }

    const handleDeleteImage = (index) => {
        if (!editing) {
            const newUploadedImages = uploadedImages.filter((_, i) => i !== index);
            URL.revokeObjectURL(uploadedImages[index]);
            setUploadedImages(newUploadedImages);
            setUploadedImagesCount(uploadedImagesCount - 1);
        } else if (uploadedImages[index]?.name) {
            const newUploadedImages = uploadedImages.filter((_, i) => i !== index);
            setUploadedImages(newUploadedImages);
            setUploadedImagesCount(uploadedImagesCount - 1);
        } else {
            removeImage(index);
        }
    };

    return (
        <>
            <Card elevation={3} style={{ padding: isMobile ? '0' : '1rem' }}>
                <CardContent>
                    <div style={{ ...containerStyle, display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                        <Box
                            style={{ ...boxStyle, marginBottom: isMobile ? '1rem' : 'none' }}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            onClick={() => document.getElementById('file-input').click()}
                        >
                            <input
                                id="file-input"
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                            <AddPhotoAlternateIcon style={iconStyle} />
                            <div style={textStyles}>{CLICK_TO_UPLOAD}</div>
                            <div style={{ ...textStyles, color: '#888888', marginTop: '0rem' }}>{DRAG_AND_DROP}</div>
                            <div style={{ ...textStyles, color: '#888', fontSize: '0.625rem', marginTop: '0.5rem' }}>
                                Supported: JPG, JPEG, PNG
                            </div>
                        </Box>
                        {uploadedImages.map((file, index) => (
                            uploadedImages[index] && (
                                index < 2 && ( // Display only the first two images
                                    typeof file === 'string' ? (
                                        <img
                                            key={index}
                                            src={file}
                                            alt={`Uploaded ${index}`}
                                            style={{ ...uploadedImageStyle, marginLeft: isMobile ? '0' : index === 0 ? '0' : '0.7rem', marginBottom: isMobile ? '1rem' : 'none', marginRight: isMobile ? '0.7rem' : '0' }}
                                        />
                                    ) : (
                                        <img
                                            key={index}
                                            src={URL.createObjectURL(file)}
                                            alt={`Uploaded ${index}`}
                                            style={{ ...uploadedImageStyle, marginLeft: isMobile ? '0' : index === 0 ? '0' : '0.7rem', marginBottom: isMobile ? '1rem' : 'none', marginRight: isMobile ? '0.7rem' : '0' }}
                                        />
                                    )
                                )
                            )
                        ))}
                    </div>
                    {uploadedImages.length > 0 && <div style={uploadsTextStyle}>{UPLOADS}</div>}
                    {uploadedImages.map((file, index) => (
                        uploadedImages[index] && (
                            <div key={index} style={fileInfoContainerStyle}>
                                {uploadedImages[index] && (
                                    <>
                                        <div style={fileInfoBoxStyle}>
                                            {typeof file === 'string' ? (
                                                <img
                                                    key={index}
                                                    src={file}
                                                    alt={`Uploaded ${index}`}
                                                    style={fileInfoBoxStyle}
                                                />
                                            ) : (
                                                <img
                                                    key={index}
                                                    src={URL.createObjectURL(file)}
                                                    alt={`Uploaded ${index}`}
                                                    style={fileInfoBoxStyle}
                                                />
                                            )}
                                        </div>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                            {file.name ?
                                                <>
                                                    <div style={{ ...fileNameStyle, marginBottom: '0.2rem' }}>{file.name}</div>
                                                    <div style={{ ...fileNameStyle, fontSize: '0.75rem', color: '#888888' }}>
                                                        {progress[file.name] < 100
                                                            ? ''
                                                            : `${(file.size / (1024 * 1024)).toFixed(2)} MB`}
                                                    </div>
                                                </>
                                                :
                                                <div style={{ ...fileNameStyle, marginBottom: '0.2rem' }}><MiddleEllipsisText text={file.replace(/^https:\/\/cornucopia-uploads\.s3\.us-east-1\.amazonaws\.com\//, '')} maxLength={!isMobile ? 40 : 20} /></div>
                                            }
                                            {progress[file.name] < 100 && (
                                                <div style={progressBarStyle}>
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            top: '0.5rem',
                                                            right: 0,
                                                            color: '#6A0082',
                                                            fontFamily: 'Poppins',
                                                            fontSize: '0.75rem',
                                                            fontStyle: 'normal',
                                                            fontWeight: '500',
                                                            lineHeight: 'normal',
                                                        }}
                                                    >
                                                        {progress[file.name].toFixed(0)}%
                                                    </div>
                                                    <div
                                                        style={{
                                                            height: '100%',
                                                            width: `${progress[file.name]}%`,
                                                            backgroundColor: '#A200C5',
                                                            borderRadius: '0.25rem',
                                                            position: 'relative',
                                                        }}
                                                    ></div>
                                                </div>
                                            )}
                                        </div>
                                        <div style={{ marginLeft: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                                            {uploading || progress[file.name] < 100 ? (
                                                <ClearIcon
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        URL.revokeObjectURL(uploadedImages[index]);
                                                        const newUploadedImages = [...uploadedImages];
                                                        newUploadedImages.splice(index, 1);
                                                        setUploadedImages(newUploadedImages);
                                                    }}
                                                />
                                            ) : (
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                    <DeleteIcon
                                                        style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                                                        onClick={() => handleDeleteImage(index)}
                                                    />
                                                    <Switch color="secondary" />
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        )
                    ))}
                </CardContent>
            </Card>
        </>
    );
};

export default PhotoUploadBox;
