import React, { useState } from "react"
import { FlexDiv } from "../../../assets/styles/styles";
import { CodeSection, DetailText, Heading, apiResponse, codeContent } from "./constants";
import CopyIcon from '../../../assets/images/copy-icon.png'
import UserProfileSeller from "../UserProfileSeller";
import { useMediaQuery } from "react-responsive";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Integration = () => {

    const isSmallScreen = useMediaQuery({ maxWidth: 720 })
    const [open, setOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [selectedLanguage, setSelectedLanguage] = useState('javascript');

    const copyResponseToClipboard = () => {
        navigator.clipboard.writeText(apiResponse)
            .then(() => {
                setSnackbarMsg('Copied to clipboard');
                setSnackbarSeverity('success');
                setOpen(true);
            })
            .catch((error) => {
                console.error('Error copying code to clipboard:', error);
            });
    }

    const copyCodeToClipboard = () => {
        // Copy codeContent[selectedLanguage] to clipboard
        navigator.clipboard.writeText(codeContent[selectedLanguage])
            .then(() => {
                setSnackbarMsg('Copied to clipboard');
                setSnackbarSeverity('success');
                setOpen(true);
            })
            .catch((error) => {
                console.error('Error copying code to clipboard:', error);
            });
    };

    const copyUrlToClipboard = () => {
        // Copy codeContent[selectedLanguage] to clipboard
        navigator.clipboard.writeText(`url = "https://backend.cornucopia-ai.com/api/auth/web/login"`)
            .then(() => {
                setSnackbarMsg('Copied to clipboard');
                setSnackbarSeverity('success');
                setOpen(true);
            })
            .catch((error) => {
                console.error('Error copying code to clipboard:', error);
            });
    };

    const handleSnackbarClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMsg}
                </MuiAlert>
            </Snackbar>
            <FlexDiv style={{ flexDirection: 'column' }}>
                <FlexDiv style={{ marginTop: '1.2rem', justifyContent: 'flex-end' }}>
                    <UserProfileSeller />
                </FlexDiv>
                <FlexDiv style={{ flexDirection: 'column', justifyContent: isSmallScreen ? 'center' : '', alignItems: isSmallScreen ? 'center' : '' }}>
                    <Heading style={{ marginTop: isSmallScreen ? '2rem' : '' }}>Integrate the Cornucopia AI api into your website</Heading>
                    <DetailText>The following code snippets contain the api code that can help you integrate Cornucopia AI with your website.</DetailText>

                    {/* <DetailText style={{ marginTop: '2rem', fontWeight:'800' }}>Base Url</DetailText> */}
                    <CodeSection style={{ marginTop: '0.5rem', marginBottom: '0rem', padding: '0px' }}>
                        <FlexDiv style={{ justifyContent: 'space-between', gap: '0.5rem', padding: '20px 20px 0px 20px', background: '#474e5a', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}>
                            {/* <select
                                value={selectedLanguage}
                                onChange={(event) => setSelectedLanguage(event.target.value)}
                                style={{
                                    outline: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    backgroundColor: '#D9D9D9',
                                    width: '105px',
                                    height: '35px',
                                    borderRadius: '8px',
                                    fontFamily: 'Roboto',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '21px',
                                    textAlign: 'center',
                                    borderRight: '16px solid transparent',
                                }}
                            >
                                <option value="flask">Flask</option>
                                <option value="django">Django</option>
                                <option value="javascript">React</option>
                            </select> */}
                            <DetailText style={{ fontWeight: '800', color: '#fff' }}>Base Url</DetailText>
                            <button
                                style={{
                                    width: '42px',
                                    height: '35px',
                                    borderRadius: '8px',
                                    backgroundColor: '#D9D9D9',
                                    cursor: 'pointer',
                                    outline: 'none',
                                    border: 'none',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '-0.5rem'
                                }}
                                onClick={copyUrlToClipboard}
                            >
                                <img src={CopyIcon} alt="Copy" />
                            </button>
                        </FlexDiv>
                        <pre style={{ padding: '20px 20px 5px 20px' }}>
                            <SyntaxHighlighter language='python' style={vscDarkPlus} customStyle={{ padding: '0rem', margin: '0rem' }}>
                                url = "https://backend.cornucopia-ai.com/api/auth/web/login"
                            </SyntaxHighlighter>
                        </pre>

                    </CodeSection>

                    <Heading style={{ marginTop: '2rem', fontSize: '20px' }}>Cornucopia API Integration Guide</Heading>
                    {/* <DetailText style={{ fontSize: '1rem', marginBottom: '0rem' }}>
                        This API enables authentication between your website and Cornucopia AI. Follow the steps below to integrate it properly.
                        <br /><br />
                        <span style={{ color: '#3F0065', fontSize: '18px' }}> Integration Steps:</span><br />
                        <ol>
                            <li style={{ marginTop: '0.5rem' }}>
                                <span style={{ color: '#3F0065', fontSize: '16px', marginTop: '0.5rem' }}>Obtain Product ID:</span><br />
                                <div style={{ marginTop: '0.5rem' }}>
                                    The <b>Product ID</b> is a unique identifier assigned when you list your product on Cornucopia AI.
                                    You can find it in the <b>Product ID column</b> of the <b>Product Table</b> on your <b>Cornucopia AI Dashboard</b>.
                                    This ID is mandatory for authentication requests.
                                </div>
                            </li>
                            <li style={{ marginTop: '0.5rem' }}>
                                <span style={{ color: '#3F0065', fontSize: '18px' }}>API Request Parameters:</span><br />
                                <div style={{ marginTop: '0.5rem' }}>
                                    To authenticate users, include the following parameters in your request:
                                </div>
                                <ul style={{ marginTop: '0.5rem' }}>
                                    <li style={{ marginTop: '0.5rem' }}><code style={{ fontSize: '18px' }}>product_id</code>: Your unique product identifier from the dashboard.</li>
                                    <li style={{ marginTop: '0.5rem' }}><code style={{ fontSize: '18px' }}>email</code>: The email address the user enters on your login page.</li>
                                    <li style={{ marginTop: '0.5rem' }}><code style={{ fontSize: '18px' }}>password</code>: The password the user provides during login.</li>
                                </ul>
                                <div style={{ marginTop: '0.5rem' }}>
                                    Ensure that the email and password fields are <b>not empty</b> before sending the request.
                                </div>
                            </li>
                            <li style={{ marginTop: '0.5rem' }}>
                                <span style={{ color: '#3F0065', fontSize: '18px' }}>Example Integration Scenario:</span><br />
                                <ul style={{ marginTop: '0.5rem' }}>
                                    <li style={{ marginTop: '0.5rem' }}>Add a <b>"Login with Cornucopia"</b> button to your login page.</li>
                                    <li style={{ marginTop: '0.5rem' }}>When a user <b>enters their credentials</b> and clicks the button, your system should <b>send an API request</b> with the collected data.</li>
                                    <li style={{ marginTop: '0.5rem' }}>If authentication is <b>successful</b>, store the <b>access token</b> securely and proceed with the user login process.</li>
                                    <li style={{ marginTop: '0.5rem' }}>If authentication <b>fails</b>, display a clear error message prompting the user to check their credentials.</li>
                                </ul>
                            </li>
                        </ol>
                    </DetailText> */}
                    <DetailText style={{ fontSize: '1rem', marginBottom: '0rem' }}>
                        This API enables authentication between your website and Cornucopia AI. Follow the steps below to integrate it properly.
                        <br /><br />
                        <span style={{ color: '#3F0065', fontSize: '18px' }}> Integration Steps:</span><br />
                        <ol>
                            <li style={{ marginTop: '0.5rem' }}>
                                <span style={{ color: '#3F0065', fontSize: '16px', marginTop: '0.5rem' }}>Obtain Product ID:</span><br />
                                <div style={{ marginTop: '0.5rem' }}>
                                    The <b>Product ID</b> is a unique identifier assigned when you list your product on Cornucopia AI.
                                    You can find it in the <b>Product ID column</b> of the <b>Product Table</b> on your <b>Cornucopia AI Dashboard</b>.
                                    This ID is mandatory for authentication requests.
                                </div>
                            </li>
                            <li style={{ marginTop: '0.5rem' }}>
                                <span style={{ color: '#3F0065', fontSize: '18px' }}>API Request Parameters:</span><br />
                                <div style={{ marginTop: '0.5rem' }}>
                                    To authenticate users, include the following parameters in your request:
                                </div>
                                <ul style={{ marginTop: '0.5rem' }}>
                                    <li style={{ marginTop: '0.5rem' }}><code style={{ fontSize: '18px' }}>product_id</code>: Your unique product identifier from the dashboard.</li>
                                    <li style={{ marginTop: '0.5rem' }}><code style={{ fontSize: '18px' }}>email</code>: The email address the user enters on your login page.</li>
                                    <li style={{ marginTop: '0.5rem' }}><code style={{ fontSize: '18px' }}>password</code>: The password the user provides during login.</li>
                                </ul>
                                <div style={{ marginTop: '0.5rem' }}>
                                    Ensure that the email and password fields are <b>not empty</b> before sending the request.
                                </div>
                            </li>
                            <li style={{ marginTop: '0.5rem' }}>
                                <span style={{ color: '#3F0065', fontSize: '18px' }}>Example Integration Scenario:</span><br />
                                <ul style={{ marginTop: '0.5rem' }}>
                                    <li style={{ marginTop: '0.5rem' }}>Add a <b>"Login with Cornucopia"</b> button to your login page.</li>
                                    <li style={{ marginTop: '0.5rem' }}>When a user <b>enters their credentials</b> and clicks the button, your system should <b>send an API request</b> with the collected data.</li>
                                    <li style={{ marginTop: '0.5rem' }}>If authentication is <b>successful</b>, store the <b>access token</b> securely and proceed with the user login process.</li>
                                    <li style={{ marginTop: '0.5rem' }}>If authentication <b>fails</b>, display a clear error message prompting the user to check their credentials.</li>
                                </ul>
                            </li>
                        </ol>
                        <br />
                        <span style={{ color: '#3F0065', fontSize: '18px' }}> Troubleshooting Guide:</span><br />
                        <ul>
                            <li style={{ marginTop: '0.5rem' }}><b>Ensure Axios or Fetch is Installed:</b>
                                <div style={{ marginTop: '0.5rem' }}>If using JavaScript/React, ensure you have <b>axios</b> installed.</div>
                                <div>Run: <code>npm install axios</code> or <code>yarn add axios</code>.</div>
                                <div>Alternatively, use the built-in <b>fetch API</b>.</div>
                            </li>
                            <li style={{ marginTop: '0.5rem' }}><b>Getting "Invalid Credentials" Error?</b>
                                <div style={{ marginTop: '0.5rem' }}>Double-check the <b>email</b> and <b>password</b> match the registered user.</div>
                                <div>Ensure the <b>Product ID</b> is correct.</div>
                            </li>
                            <li style={{ marginTop: '0.5rem' }}><b>API Request Not Working?</b>
                                <div style={{ marginTop: '0.5rem' }}>Confirm you are sending a <b>POST request</b> with all required parameters.</div>
                                <div>Ensure the API URL is correctly formatted: <code>https://backend.cornucopia-ai.com/api/auth/web/login</code>.</div>
                            </li>
                            <li style={{ marginTop: '0.5rem' }}><b>Receiving a 500 Server Error?</b>
                                <div style={{ marginTop: '0.5rem' }}>This is likely a <b>temporary server issue</b>. Wait a few minutes and retry.</div>
                                <div>Check Cornucopia AI’s status page or contact support if the issue persists.</div>
                            </li>
                            <li style={{ marginTop: '0.5rem' }}><b>How Can I Check If a User’s Email is Verified?</b>
                                <div style={{ marginTop: '0.5rem' }}>The API response contains <code>isEmailVerified: true/false</code>.</div>
                                <div>If 'false', prompt the user to verify their email before login.</div>
                            </li>
                            <li style={{ marginTop: '0.5rem' }}><b>Can I Use This API for Mobile Applications?</b>
                                <div style={{ marginTop: '0.5rem' }}>Yes! This API works for <b>React Native, Flutter, and other mobile frameworks</b>.</div>
                            </li>
                        </ul>
                    </DetailText>




                    {/* <DetailText style={{ marginTop: '2rem', fontWeight: '800' }}>Making Request</DetailText> */}
                    <Heading style={{ marginTop: '0.5rem', fontSize: '1.2rem' }}>Making Request</Heading>
                    <DetailText style={{ fontSize: '1rem' }}>Here's an example of how to make an API request to the Cornucopia platform. Remember to replace the placeholder values in the payload with your own information as mentioned above.</DetailText>
                    <CodeSection style={{ marginTop: '0.5rem', padding: '0px', marginBottom: '1.5rem' }}>
                        <FlexDiv style={{ justifyContent: 'space-between', padding: '20px 20px 0px 20px', background: '#474e5a', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}>
                            <DetailText style={{ fontWeight: '800', color: '#fff' }}>Making Request</DetailText>
                            <FlexDiv style={{ justifyContent: 'flex-end', gap: '0.5rem', marginTop: '-0.5rem' }}>
                                <select
                                    value={selectedLanguage}
                                    onChange={(event) => setSelectedLanguage(event.target.value)}
                                    style={{
                                        outline: 'none',
                                        border: 'none',
                                        cursor: 'pointer',
                                        backgroundColor: '#D9D9D9',
                                        width: '105px',
                                        height: '35px',
                                        borderRadius: '8px',
                                        fontFamily: 'Roboto',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '21px',
                                        textAlign: 'center',
                                        borderRight: '16px solid transparent',
                                    }}
                                >
                                    <option value="flask">Flask</option>
                                    <option value="django">Django</option>
                                    <option value="javascript">React</option>
                                    <option value="node">Node js</option>
                                </select>
                                <button
                                    style={{
                                        width: '42px',
                                        height: '35px',
                                        borderRadius: '8px',
                                        backgroundColor: '#D9D9D9',
                                        cursor: 'pointer',
                                        outline: 'none',
                                        border: 'none',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                    onClick={copyCodeToClipboard}
                                >
                                    <img src={CopyIcon} alt="Copy" />
                                </button>
                            </FlexDiv>
                        </FlexDiv>
                        <pre style={{ marginTop: '1rem', padding: '20px 20px 5px 20px' }}>
                            <SyntaxHighlighter language={selectedLanguage === 'flask' || selectedLanguage === 'django' ? 'python' : selectedLanguage === 'node' ? 'javascript' : selectedLanguage} style={vscDarkPlus} customStyle={{ padding: '0rem', margin: '0rem' }}>
                                {codeContent[selectedLanguage]}
                            </SyntaxHighlighter>
                        </pre>

                    </CodeSection>

                    <CodeSection style={{ marginTop: '1rem', padding: '0px' }}>
                        <FlexDiv style={{ justifyContent: 'space-between', padding: '20px 20px 0px 20px', background: '#474e5a', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}>
                            <DetailText style={{ fontWeight: '800', color: '#fff' }}>Api Response</DetailText>
                            <FlexDiv style={{ justifyContent: 'flex-end', gap: '0.5rem', marginTop: '-0.5rem' }}>
                                <button
                                    style={{
                                        width: '42px',
                                        height: '35px',
                                        borderRadius: '8px',
                                        backgroundColor: '#D9D9D9',
                                        cursor: 'pointer',
                                        outline: 'none',
                                        border: 'none',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                    onClick={copyResponseToClipboard}
                                >
                                    <img src={CopyIcon} alt="Copy" />
                                </button>
                            </FlexDiv>
                        </FlexDiv>
                        <pre style={{ marginTop: '1rem', padding: '20px 20px 5px 20px' }}>
                            <SyntaxHighlighter language={'javascript'} style={vscDarkPlus} customStyle={{ padding: '0rem', margin: '0rem' }}>
                                {apiResponse}
                            </SyntaxHighlighter>
                        </pre>

                    </CodeSection>
                </FlexDiv>
            </FlexDiv>
        </>
    );
};

export default Integration;
